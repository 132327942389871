import { inject, Ref, ref } from 'vue'
import { useInventoryStore } from '@/stores/inventory'
import { useApplicationStore } from '@/stores/application'
import { useRouter } from 'vue-router'
import phoneNumbers from '@/constants/phoneNumbers'
import { Toast } from '@/models/toaster/toast'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import pinpointClient from '@/clients/pinpointClient'
import { TVehicleState } from '@/components/cards/VehicleForm.vue'

type TUseAddVehicleReturn = {
    addVehicle: (vehicle: TVehicleState) => Promise<void>
    getVehicleFromInventory: (vin: string) => Promise<Vehicle | undefined>
    loading: Ref<boolean>
}

export function useAddVehicle(): TUseAddVehicleReturn {
    const router = useRouter()
    const applicationStore = useApplicationStore()
    const inventoryStore = useInventoryStore()
    const popToastLocal = inject('popToastLocal') as (toast: Toast) => {}
    const loading: Ref<boolean> = ref(false)

    async function addVehicle(vehicle: TVehicleState): Promise<void> {
        const vehiclePayload: Vehicle = {
            dealer_id: inventoryStore.id,
            zipcode: vehicle.isUsed ? inventoryStore.dealerZipCode : undefined,
            make: vehicle.make,
            model: vehicle.model,
            year: Number(vehicle.year),
            miles: Number(vehicle.mileage),
            vin: vehicle.vin,
            value: vehicle.isUsed ? undefined : Number(vehicle.invoice),
            expiresAt: vehicle.duration,
            used: vehicle.isUsed,
            favorited: false,
        }
        let toastMessage = ''
        loading.value = true

        const addedVehicle = await inventoryStore.addVehicle(vehiclePayload, applicationStore.account?.id)
        loading.value = false
        if (!addedVehicle || !addedVehicle.value) {
            const phoneNumber = applicationStore.dealer?.representative.team.team_id
                ? ' at ' + phoneNumbers[applicationStore.dealer?.representative.team.team_id]
                : ''
            toastMessage = `PinPoint was unable to get a Book Value for this vehicle from our sources, to structure this vehicle please call in to your Dealer Services Team${phoneNumber}. If you made a mistake, you can always add it back manually.`
        }
        if (toastMessage) {
            popToastLocal({
                message: toastMessage,
                timer: 5000,
                location: 'top-center',
            })
            return
        }
        router.push({
            name: 'SelectedVehicle',
            params: {
                dealer_id: applicationStore.account?.dealer_id,
                account_id: applicationStore.account?.id,
                vin: addedVehicle?.vin,
            },
        })
    }

    async function getVehicleFromInventory(vin: string): Promise<Vehicle | undefined> {
        try {
            loading.value = true

            const vehicle = await inventoryStore.getVehicle(vin, applicationStore.account?.id)
            if (vehicle) {
                inventoryStore.inventory.push(vehicle)
                return vehicle
            }
            const { data } = await pinpointClient.get(`/vehicle/${vin}`)
            return data || undefined
        } catch {
            return
        } finally {
            loading.value = false
        }
    }

    return {
        addVehicle,
        getVehicleFromInventory,
        loading,
    }
}
