<template>
    <div class="w-full text-slate-900 pr-2" cy-id="activity-list">
        <div class="font-medium w-full pb-2" id="activity-header">Activity</div>
        <div class="border-b border-slate-900"></div>
        <empty-state
            v-if="!entries.length"
            border-size="240"
            icon-subtitle="No account activity yet"
            footer-text="There is currently no history of activity on this account"
            :vuetify-icon-props="{ icon: '$clipboardListOutline', color: 'grey-darken-1', size: '120' }" />
        <div v-else class="flex flex-col overflow-auto custom-scrollbar pr-2 py-2 gap-2" cy-id="activities">
            <div
                v-for="(entry, i) in entries"
                :key="i"
                :class="`flex flex-row text-sm  border-slate-300 activity-entry rounded-lg `">
                <v-card width="100%" variant="flat" :class="`${getBackgroundColor(entry.from)}`">
                    <v-card-title>
                        <v-row no-gutters>
                            <v-col class="flex flex-row justify-center items-center gap-3">
                                <img :src="arivoLogo" v-if="showLogo(entry.from)" />
                                <div
                                    :class="`font-inter text-normal text-sm font-semibold w-full activity-reply ${getTextAlignment(
                                        entry.from,
                                    )}`">
                                    {{ entry.from }}
                                </div>

                                <v-avatar
                                    v-if="!showLogo(entry.from)"
                                    color="surface-variant"
                                    class="font-inter text-sm font-semibold leading-[14px]"
                                    size="24"
                                    :text="getInitials(entry.from)"></v-avatar>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="pb-2">
                        <v-row no-gutters>
                            <v-col>
                                <div
                                    class="flex flex-row font-inter text-[10px] font-medium tracking-[1.5px] leading-4 justify-between items-center mb-3">
                                    <hr class="w-full h-[1px] mx-auto my-1 bg-slate-400 border-0 rounded mr-2" />
                                    <span class="min-w-fit uppercase text-slate-600 activity-time">{{
                                        entry.time
                                    }}</span>
                                    <hr class="w-full h-[1px] mx-auto my-1 bg-slate-400 border-0 rounded ml-2" />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col
                                cy-id="activity-note"
                                class="font-inter text-normal font-normal text-sm leading-4 tracking-[.25px]"
                                >{{ entry.note }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { defineComponent } from 'vue'
import EmptyState from '@/components/partials/EmptyState.vue'
import arivoLogo from '@/assets/arivo-logo.svg'
import dayjs from 'dayjs'

defineComponent({
    name: 'Activity',
})

export type Activity = {
    note: string
    from: string
    time: string
}

interface Props {
    entries: Array<Activity>
}

defineProps<Props>()

function getBackgroundColor(from: string) {
    return from === 'Arivo Acceptance' ? 'bg-background' : 'bg-green-lighten-5'
}

function getTextAlignment(from: string) {
    return from === 'Arivo Acceptance' ? 'text-left' : 'text-right'
}

function showLogo(from: string) {
    return from === 'Arivo Acceptance'
}

function getInitials(name: string) {
    name = name.trim()
    return name
        .split(' ')
        .map((n) => n[0])
        .join('')
        .slice(0, 2)
        .toUpperCase()
}
</script>

<style scoped>
.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color 0.3s;

    &.task {
        padding-right: 1.5rem;
    }

    box-sizing: content-box;
}

.custom-scrollbar:hover {
    scrollbar-color: #d9d9d9 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 9999px;
    background-color: transparent;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
}
</style>
