export interface BankAccount {
    id?: number
    name: string
    routingNumber: string
    accountNumber: string
    routingNumberConfirm?: string
    accountNumberConfirm?: string
    paymentOptionId: string
    status: string
    microTransactionSent?: string
}

export interface BankAccountResponse {
    id: number
    dealer_id: number
    bank_name: string
    routing_number: string
    account_number: string
    type: 'ach' | 'wire' | 'fednow'
    active: number
    status: 'pending' | 'verified' | 'sent' | ''
    micro_transaction_sent: string
}

export const BANK_ACCOUNT_STATUS = {
    PENDING: 'pending',
    VERIFIED: 'verified',
    SENT: 'sent',
}
