<template>
    <v-app class="bg-transparent">
        <header-nav v-if="shouldShowHeader" />
        <v-main class="flex flex-col !font-inter h-screen mx-2">
            <router-view />
            <toaster />
            <global-modal />
            <dev-show-breakpoints v-if="shouldShowBreakpoints" />
        </v-main>
    </v-app>
</template>

<script lang="ts">
export default {
    name: 'App',
}
</script>

<script lang="ts" setup>
import DevShowBreakpoints from '@/helpers/DevShowBreakpoints.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import Toaster from './components/popables/Toaster/Toaster.vue'
import HeaderNav from './router/views/partials/HeaderNav.vue'
import GlobalModal from '@/components/modals/GlobalModal.vue'

const route = useRoute()
const shouldShowHeader = computed(() => {
    const hideHeaderRoutes = ['EmailAuthentication', 'Setup']
    return route.params.dealer_id != undefined && !hideHeaderRoutes.includes(route.name as string)
})

const shouldShowBreakpoints = computed(() => {
    return import.meta.env.DEV
})
</script>
