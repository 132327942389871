<template>
    <section cy-id="urgent-unresolved-issues" class="rounded-lg shadow border bg-white overflow-x-hidden">
        <h2 class="font-inter font-semibold text-base py-4 leading-8 px-6 border-b-2 border-black">
            Urgent & Unresolved Issues
        </h2>
        <v-tabs
            v-model="selectedTab"
            :items="tabList"
            density="compact"
            align-tabs="center"
            class="my-1 text-vuetify-text-normal">
            <template #tab="{ item }">
                <v-tab :text="item.title" :value="item.value" size="small" class="text-none !tracking-normal !text-xs">
                    <template #append>
                        <v-avatar
                            class="!text-center !text-xs"
                            density="compact"
                            :color="selectedTab === item.value ? 'text-normal' : 'text-disabled'"
                            size="small">
                            {{ item.count }}
                        </v-avatar>
                    </template>
                </v-tab>
            </template>
            <template #item="{ item }">
                <v-tabs-window-item :value="item.value">
                    <div class="overflow-y-auto max-h-[calc(100vh-100px)] border-1 border-t border-slate-500">
                        <div v-for="tab in tabList" :key="tab.value" v-show="tab.value === selectedTab" class="mx-4">
                            <issue-account-line
                                v-for="account in tab.accountList"
                                v-bind="account"
                                :queue-name="tab.value" />
                            <empty-state
                                v-if="!tab.accountList.length"
                                icon-subtitle="All set!"
                                border-size="240"
                                :vuetify-icon-props="{
                                    icon: '$clipboardCheckOutline',
                                    color: 'grey-darken-1',
                                    size: '120',
                                }"
                                :footer-text="computedEmptyStateText"></empty-state>
                        </div>
                    </div>
                </v-tabs-window-item>
            </template>
        </v-tabs>
    </section>
</template>
<script setup lang="ts">
import EmptyState from '@/components/partials/EmptyState.vue'
import IssueAccountLine from '@/components/partials/IssueAccountLine.vue'
import { getAccountsByDealerIdAndEventIds } from '@/composables/AccountsByEvent'
import { FAST_FUNDED, MISSING_TITLE, ORIGINAL_RIC_RECEIVED, RECOURSE, RECOURSE_RESOLVED } from '@/constants/events'
import { ComputedRef, Ref, computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

type Tab = {
    value: string
    title: string
    count?: number
    accountList: AccountItem[]
}

export type AccountItem = {
    name: string
    account: string
    days: number
}

const route = useRoute()
const cDealerId: ComputedRef<number> = computed(() => Number(route?.params?.dealer_id))
const selectedTab: Ref<string> = ref('outstanding')
const outstandingList: Ref<AccountItem[]> = ref([])
const recourseList: Ref<AccountItem[]> = ref([])
const fastFundList: Ref<AccountItem[]> = ref([])
const tabList: Ref<Tab[]> = computed(() => [
    {
        value: 'outstanding',
        title: 'Outstanding Title',
        count: outstandingList.value?.length || 0,
        accountList: outstandingList.value,
    },
    {
        value: 'recourse',
        title: 'Repurchase Notice',
        count: recourseList.value?.length || 0,
        accountList: recourseList.value,
    },
    {
        value: 'fastfund',
        title: 'Outstanding RIC',
        count: fastFundList.value?.length || 0,
        accountList: fastFundList.value,
    },
])

onMounted(async () => {
    getTabList()
})

const computedEmptyStateText: ComputedRef<string> = computed(() => {
    switch (selectedTab?.value) {
        case 'recourse':
            return 'There are currently no pending chargebacks for your dealership'
        case 'fastfund':
            return 'There are current no pending RIC for your dealership'
        default:
            return 'There are currently no outstanding titles pending for your dealership'
    }
})

async function getTabList(): Promise<void> {
    getOutstandingTab()
    getRecourseTab()
    getFastFundTab()
}

async function getOutstandingTab(): Promise<void> {
    getAccountsByDealerIdAndEventIds(cDealerId.value, [MISSING_TITLE], []).then((response) => {
        if (response) {
            outstandingList.value = response
        }
    })
}

async function getRecourseTab(): Promise<void> {
    getAccountsByDealerIdAndEventIds(cDealerId.value, [RECOURSE], [RECOURSE_RESOLVED]).then((response) => {
        if (response) {
            recourseList.value = response
        }
    })
}

async function getFastFundTab(): Promise<void> {
    getAccountsByDealerIdAndEventIds(cDealerId.value, [FAST_FUNDED], [ORIGINAL_RIC_RECEIVED]).then((response) => {
        if (response) {
            fastFundList.value = response
        }
    })
}
</script>
