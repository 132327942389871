import { DAYS_TO_EXPIRE, DAYS_WHEN_IN_FUNDING } from '@/constants/amounts'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import { AccountStatuses } from '@/constants/accounts'

dayjs.extend(utc)
dayjs.extend(duration)

export const money: Function = (value: string): string => {
    let number = Number(value)

    if (isNaN(number)) {
        number = 0
    }

    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    })
    const formattedNumber: string = nf.format(number)
    return `${formattedNumber}`
}
export const roundDownWithTwoDecimals: Function = (value: number): string => {
    return (Math.floor(value * 100) / 100).toFixed(2)
}
export const calculateDaysSince: Function = (date: string, isAddDayString = false): string => {
    const today = dayjs()
    const startDate = dayjs(date)
    const count = today.diff(startDate, 'day')
    return isAddDayString ? (count > 0 ? `${count} days` : 'today') : `${count}`
}
export const calculateDaysUntil: Function = (date: Date, today: Date = new Date()): number => {
    today = today ?? dayjs()
    const startDate = dayjs(date)
    const count = startDate.diff(today, 'day')
    return count
}
export const phoneNumber: Function = (value: string): string => {
    if (value == '' || value == null) {
        return ''
    }
    return value.split('').reduce((carry, each, index) => {
        if (value.length > 10) {
            if (index === 1) carry += ' ('
            if (index === 4) carry += ') '
            if (index === 7) carry += '-'
        } else if (value.length > 7) {
            if (index === 0) carry += '('
            if (index === 3) carry += ') '
            if (index === 6) carry += '-'
        } else {
            if (index === 3) carry += '-'
        }
        carry += each
        return carry
    }, '')
}

export function getTimeDifferenceFromDate(givenDate: Date, today: Date | null = new Date()) {
    let currentDate = dayjs()
    if (today) {
        currentDate = dayjs(today)
    }
    const diffInMinutes = currentDate.utc().diff(givenDate, 'minute')
    const diffInHours = currentDate.diff(givenDate, 'hours')
    const diffInDays = currentDate.diff(givenDate, 'days')
    const diffInMonths = currentDate.diff(givenDate, 'months')
    const diffInYears = currentDate.diff(givenDate, 'years')

    if (diffInYears > 0) {
        return diffInYears + ' y ago'
    } else if (diffInMonths > 0) {
        return diffInMonths + ' mo ago'
    } else if (diffInDays > 0) {
        return diffInDays + ' d ago'
    } else if (diffInHours > 0) {
        return diffInHours + ' h ago'
    } else if (diffInMinutes > 0) {
        return diffInMinutes + ' m ago'
    } else {
        return 'Just now'
    }
}

export const getTimeInStatusByDate = (date?: Date): { key: string; value: number } => {
    const currentDay = dayjs()
    const fundingDateTime = dayjs.utc(date).local()
    const minDiff = currentDay.diff(fundingDateTime, 'm')
    const hourDiff = currentDay.diff(fundingDateTime, 'h')
    const dayDiff = currentDay.diff(fundingDateTime, 'd')
    if (dayDiff) return { key: 'Days', value: dayDiff }
    if (minDiff <= 60) return { key: 'Minutes', value: minDiff ? minDiff : 1 }

    return { key: 'Hours', value: hourDiff }
}
export const getExpirationDate = (date: Date, daysTillExpire: number): Date => {
    return dayjs(date).add(daysTillExpire, 'days').toDate()
}

export const getFundingExpires = (decisionedDate: Date, dateAssigned: Date | undefined): Date => {
    const approvalExpiresAt = getExpirationDate(decisionedDate, DAYS_TO_EXPIRE)
    if (!dateAssigned) return approvalExpiresAt
    if (dayjs(approvalExpiresAt).diff(dateAssigned, 'days') < DAYS_WHEN_IN_FUNDING) {
        return approvalExpiresAt
    }
    return getExpirationDate(dateAssigned, DAYS_WHEN_IN_FUNDING)
}

export const getCountdownExpiresDate = (
    decisionedDate: Date,
    dateAssigned: Date | undefined,
): {
    date: Date
    countDown: number
} => {
    const approvalExpiresAt: Date = getExpirationDate(decisionedDate, DAYS_TO_EXPIRE)
    const assignedExpiresAfterApproval: boolean =
        dayjs(approvalExpiresAt).diff(dateAssigned, 'days') < DAYS_WHEN_IN_FUNDING

    if (!dateAssigned) {
        return { date: dayjs(decisionedDate).startOf('day').toDate(), countDown: DAYS_TO_EXPIRE }
    }

    if (assignedExpiresAfterApproval) {
        return { date: dayjs(decisionedDate).startOf('day').toDate(), countDown: DAYS_TO_EXPIRE }
    }

    return { date: dayjs(dateAssigned).startOf('day').toDate(), countDown: DAYS_WHEN_IN_FUNDING }
}

export function getDays(decisioned: Date, dateAssigned: Date | undefined) {
    const { date, countDown } = getCountdownExpiresDate(decisioned, dateAssigned)
    return getExpirationDate(date, countDown)
}

export const adjustName = (first?: string, last?: string): string => {
    if (!first) return ''

    const capitalizeFirstLetter = (word: string): string => {
        return word[0]?.toUpperCase() + word.substring(1).toLowerCase()
    }

    const capitalizeInitial = (word: string): string => {
        return word[0]?.toUpperCase()
    }

    const nameArr: string[] = first.split(' ')

    if (nameArr.length > 1) {
        const firstName = capitalizeFirstLetter(nameArr[0])
        const secondFirstName = `${capitalizeFirstLetter(nameArr[1])} `
        const lastInitial = last ? `${capitalizeInitial(last)}.` : ''
        return `${firstName} ${secondFirstName}${lastInitial}`
    }

    const capitalizedFirst = capitalizeFirstLetter(first)
    const lastInitial = last ? ` ${capitalizeInitial(last)}.` : ''
    return `${capitalizedFirst}${lastInitial}`
}

export function isInFunding(status?: string | number): boolean {
    return AccountStatuses.Funding == status
}

export function getTimeInStatusByMilliseconds(milliseconds: number): {
    key: 'Minutes' | 'Hours' | 'Days'
    value: number
} {
    const durationsObject: duration.Duration = dayjs.duration(milliseconds)
    const minutes: number = durationsObject.minutes()
    const hours: number = durationsObject.hours()
    const days: number = durationsObject.days()

    if (days) {
        return { key: 'Days', value: days }
    }
    if (hours) {
        return { key: 'Hours', value: hours }
    }

    return { key: 'Minutes', value: minutes || 1 }
}

interface TimeResult {
    value: number
    unit: string
}

interface TimeObject {
    minutes: TimeResult
    hours: TimeResult
    days: TimeResult
    months: TimeResult
    years: TimeResult
}

export function calculateTime(minutes: number): TimeResult {
    const hours: number = dayjs.duration(minutes, 'minutes').as('hour')
    const days: number = dayjs.duration(minutes, 'minutes').as('day')
    const months: number = dayjs.duration(minutes, 'minutes').as('month')
    const years: number = dayjs.duration(minutes, 'minutes').as('year')

    const result: TimeObject = {
        minutes: { value: minutes, unit: getUnitName(minutes, 'minute') },
        hours: { value: hours, unit: getUnitName(hours, 'hour') },
        days: { value: days, unit: getUnitName(days, 'day') },
        months: { value: months, unit: getUnitName(months, 'month') },
        years: { value: years, unit: getUnitName(years, 'year') },
    }

    const keys = ['years', 'months', 'days', 'hours', 'minutes'] as (keyof TimeObject)[]
    for (const key of keys) {
        if (result[key].value > 1) {
            return result[key]
        }
    }
    return result['minutes']
}

function getUnitName(value: number, singular: string): string {
    return value === 1 ? singular : `${singular}s`
}

export function makeFlatArray(arr: Record<number, number>): Array<number> {
    return Object.entries(arr)
        .flatMap(([value, count]) => Array(count).fill(Number(value)))
        .sort((a, b) => a - b)
}
