import { Applicant } from '@/models/Applicant/Applicant'
import { Application } from '@/models/Application/Application'
import { ApplicationDeal } from '@/models/Application/ApplicationDeal'
import { Deal, DealStructure, VehicleDeal } from '@/models/Deal/DealStructure'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { Dealer } from '@/models/Dealer/Dealer'
import { MAX_NEW_LTV, MAX_USED_LTV } from '@/constants/amounts'
import { usePolicyStore } from '@/stores/policy'

export function buildVehicleDealPayload(
    deal: ApplicationDeal,
    buyRate: number,
    dealerState: string,
    selectedVehicle: Vehicle | undefined,
): { currentDeal: Deal; currentVehicle: VehicleDeal } {
    const policyStore = usePolicyStore()
    const maxTermAllowedForMileage = policyStore.getMaxTermAllowedForMileage(selectedVehicle?.miles || 0)

    return {
        currentDeal: {
            amount_financed: deal.amountFinanced,
            amount_financed_max: deal.maxAmountFinanced,
            state: dealerState,
            term: deal.term > maxTermAllowedForMileage ? maxTermAllowedForMileage : deal.term,
            buy_rate: buyRate,
            contract_rate: deal.apr,
            max_contract_rate: deal.apr,
            payment: deal.payment,
            down_payment: deal.downPayment,
        },
        currentVehicle: {
            odometer: selectedVehicle?.miles,
            year: selectedVehicle?.year,
            value: selectedVehicle?.value,
        },
    }
}

export function buildDealStructurePayload(
    deal: Deal,
    vehicle: VehicleDeal,
    application?: Application,
    dealer?: Dealer,
    borrower?: Applicant,
): DealStructure | undefined {
    if (!borrower || !application || !dealer) {
        return
    }
    let coborrower = undefined
    if (coborrower) {
        coborrower = {
            bk_chapter: borrower.bk_chapter,
        }
    }
    const payload = {
        borrower: {
            bk_chapter: borrower.bk_chapter,
            bk_disposition: borrower.bk_disposition,
        },
        coborrower,
        deal: deal,
        pricing: {
            capped_price: application.terms.rate_capped_buy,
            uncapped_price: application.terms.rate_uncapped_buy,
        },
        vehicle: vehicle,
        date: application.created_at,
        dealer_id: dealer.id.toString(),
        account_id: application.account_id,
    }
    return payload
}

export function buildFinalDealStructurePayload(
    dealerId: number,
    currentDeal: DealStructure,
    application: Application | undefined,
    selectedVehicle: Vehicle | undefined,
) {
    if (!currentDeal || !application || !selectedVehicle || !selectedVehicle.value) return
    return {
        id: application.id,
        account_id: application.account_id,
        bk_fee_ignore: application.bk_fee_ignore,
        dealer_id: dealerId,
        fees: {
            acquisition: currentDeal.fees?.acq_fee,
            additional_acquisition: currentDeal.fees?.additional_acq_fee,
            ch_13_bk: application.fees.ch_13_bk,
            dealer_flat: currentDeal.fees?.dealer_participation,
            multiple_bk: application.fees.multiple_bk,
            processing: application.fees.processing,
            spread_fee: currentDeal.fees?.spread_fee || 0,
            technological_convenience_fee: currentDeal?.fees?.technological_convenience_fee || 0,
        },
        terms: {
            amount_financed: currentDeal.deal.amount_financed,
            amount_financed_max: currentDeal.deal.amount_financed_max,
            amount_financed_max_customer: application.terms.amount_financed_max_customer,
            cash_down: currentDeal.deal.down_payment,
            disbursement: currentDeal.deal.disbursement,
            ltv: currentDeal.deal.amount_financed / selectedVehicle?.value,
            ltv_max: selectedVehicle.used ? MAX_USED_LTV : MAX_NEW_LTV,
            payment: currentDeal.deal.payment,
            payment_max: application.terms.payment_max,
            rate_capped_buy: application.terms.rate_capped_buy,
            rate_contract: currentDeal.deal.contract_rate,
            rate_uncapped_buy: application.terms.rate_uncapped_buy,
            term: currentDeal.deal.term,
            rolled_amount_financed: application?.terms.rolled_amount_financed,
            max_apr: currentDeal.deal.max_contract_rate,
        },
        vehicle: {
            invoice: selectedVehicle.value,
            kbb: selectedVehicle.value,
            make: selectedVehicle.make,
            mileage: selectedVehicle.miles,
            model: selectedVehicle.model,
            vin: selectedVehicle.vin,
            year: selectedVehicle.year,
        },
    }
}
