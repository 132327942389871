<template>
    <div class="border-b">
        <v-menu v-model="menu" :close-on-content-click="false" location="end" :attach="testing">
            <template #activator="{ props: menu }">
                <v-hover #default="{ isHovering, props: hover }">
                    <v-btn
                        :cy-id="`${filterKey}-filter-btn`"
                        size="x-large"
                        block
                        :disabled="loading || !data"
                        variant="text"
                        v-bind="mergeProps(menu, hover)"
                        color="grey-darken-2"
                        class="text-capitalize !tracking-normal px-4 d-flex justify-space-between filter-open-btn">
                        <template #prepend>
                            <div class="text-start text-capitalize">
                                <p class="text-base">{{ filterLabel }}</p>
                                <v-chip
                                    v-for="(selected, index) in cChips"
                                    :key="`${selected.name}-${index}`"
                                    :cy-id="`${selected.name}-${index}`"
                                    label
                                    variant="elevated"
                                    class="!text-[12px] mr-2"
                                    density="compact"
                                    :text="selected.name"
                                    @click:close="removeFilter(selected.name)">
                                    <template #close>
                                        <v-icon
                                            v-if="!selected.name.includes('selected')"
                                            icon="$windowClose"
                                            size="16"
                                    /></template>
                                </v-chip>
                            </div>
                        </template>
                        <template #append>
                            <v-icon icon="$chevronRight" :class="{ '!invisible': !isHovering }" />
                        </template>
                    </v-btn>
                </v-hover>
            </template>
            <v-card max-height="500" min-width="300" class="overflow-hidden">
                <v-card-title class="!text-xl sticky top-0 border-b border-black bg-white z-10"
                    >{{ cardTitle }}
                </v-card-title>
                <v-card-text class="pa-3">
                    <FilterMultiSelect
                        v-if="props.data?.Type === 'multi-select'"
                        @enable="enableConfirm"
                        @selected-filters="setSelectedFilter($event)"
                        :name="filterLabel"
                        :filter-data="cMultiSelectData" />
                    <FilterSlider
                        v-else
                        @enable="enableConfirm"
                        @selected-filters="setSelectedFilter($event)"
                        :filter-data="cSliderData"
                        :show="showDollar" />
                </v-card-text>
                <v-card-actions class="px-3 sticky bottom-0">
                    <v-row align="center" justify="center">
                        <v-col>
                            <v-btn
                                cy-id="clear-filter-btn"
                                :disabled="disableClear"
                                block
                                color="primary"
                                variant="outlined"
                                class="!tracking-normal text-capitalize !rounded-lg"
                                @click="handleClear">
                                Clear
                            </v-btn>
                        </v-col>

                        <v-col>
                            <v-btn
                                cy-id="confirm-filter-btn"
                                :disabled="disableConfirm"
                                block
                                variant="flat"
                                color="primary"
                                text="Apply filter"
                                class="!tracking-normal text-capitalize !rounded-lg"
                                @click="handleConfirm">
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script lang="ts" setup>
import { ref, mergeProps, computed, ComputedRef, Ref } from 'vue'
import FilterSlider from '@/components/sidebar/FilterSlider.vue'
import { VBtn } from 'vuetify/components'
import {
    TSlider,
    IFilterKeys,
    TMultiSelect,
    TSliderValues,
    TMultiSelectValues,
    isSlider,
} from '@/models/Dealer/InventoryFilters'
import FilterMultiSelect from '@/components/sidebar/FilterMultiSelect.vue'
import { money } from '@/helpers/formatters'

type TChips = {
    name: string
}
type TEmit = {
    key: string
    type: string
}
export type TConfirmEmit = TEmit & {
    multiSelectedValues?: string
    min?: number
    max?: number
}
export type TRemoveEmit = TEmit & {
    selectedFilters: string
}
interface Props {
    filterLabel: string
    filterKey: IFilterKeys
    data?: TSlider | TMultiSelect
    loading?: boolean
    cardTitle: string
    testing?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    cardTitle: '',
    loading: false,
    filterKey: 'payment',
    filterLabel: '',
    testing: false,
})

const emit = defineEmits<{
    'confirm-filter': [value: TConfirmEmit]
    'remove-filter': [value: TRemoveEmit]
}>()
const menu: Ref<boolean> = ref(false)
const selectedFilters: Ref<{ min?: number; max?: number; multiSelectedValues?: string }> = ref({
    multiSelectedValues: '',
    min: 0,
    max: 0,
})
const disableConfirm: Ref<boolean> = ref(true)

const showDollar: ComputedRef<boolean> = computed(() => {
    if (isSlider(props.data)) {
        return props.filterKey === 'price' || props.filterKey === 'payment'
    }
    return false
})

const cSliderData: ComputedRef<TSliderValues> = computed(() => {
    if (isSlider(props.data)) {
        return props.data.Values
    }
    return {
        Values: [],
        selectedMin: 0,
        selectedMax: 0,
    }
})

const cMultiSelectData: ComputedRef<TMultiSelectValues[]> = computed(() => {
    if (!isSlider(props.data)) {
        return props.data?.Values ?? []
    }
    return []
})

const cChips: ComputedRef<TChips[]> = computed(() => {
    if (!props.data) return []
    let chips: TChips[] = []

    if (isSlider(props.data) && props.data.Values.selectedMax) {
        buildSliderChip(chips)
    } else if (!isSlider(props.data) && props.data?.Values) {
        buildMultiSelectChip(chips)
    }
    if (chips.length > 2) {
        chips = [{ name: `${chips.length} selected` }]
    }
    return chips
})

const disableClear: ComputedRef<boolean> = computed(() => {
    if (!isSlider(props.data)) {
        return props.data?.Values.every((item) => !item.selected) ?? true
    }
    return !props.data?.Values?.selectedMax ?? true
})

function enableConfirm(val: boolean): void {
    disableConfirm.value = val
}

function buildMultiSelectChip(chips: TChips[]): TChips[] {
    if (!isSlider(props.data)) {
        props.data?.Values.forEach((item: TMultiSelectValues) => {
            if (item.selected) {
                chips.push(item)
            }
        })
    }
    return chips
}
function buildSliderChip(chips: TChips[]): TChips[] {
    if (isSlider(props.data)) {
        if (props.filterKey !== 'payment' && props.filterKey !== 'price') {
            chips.push({
                name: `${props.data?.Values.selectedMin} - ${props.data.Values.selectedMax}`,
            })
        } else {
            chips.push({
                name: `${money(props.data.Values.selectedMin)} - ${money(props.data.Values.selectedMax)}`,
            })
        }
    }

    return chips
}

function setSelectedFilter(values: { min?: number; max?: number; multiSelectedValues?: string }): void {
    selectedFilters.value = values
}
function handleConfirm(): void {
    emit('confirm-filter', { key: props.filterKey, type: props.data?.Type ?? '', ...selectedFilters.value })
    menu.value = false
}
function handleClear(): void {
    emit('remove-filter', { key: props.filterKey, type: 'slider', selectedFilters: '' })
    menu.value = false
}
function removeFilter(chipToRemove: string) {
    emit('remove-filter', { key: props.filterKey, type: props.data?.Type ?? '', selectedFilters: chipToRemove })
    menu.value = false
}
</script>

<style lang="scss" scoped>
$text-field-padding: 0px 0 0px;
</style>
