<template>
    <v-menu
        v-model="termMenuOpen"
        :disabled="disabled"
        :attach="testing"
        :close-on-content-click="false"
        location="bottom">
        <template #activator="{ props }">
            <v-text-field
                data-testid="term_menu_display_input"
                v-bind="props"
                :readonly="disabled"
                class="text-center"
                variant="filled"
                bg-color="white"
                :value="`${currentTerm} months`"
                label="Term"
                persistent-placeholder
                hide-details="auto"
                append-inner-icon="$menuDown">
            </v-text-field>
        </template>
        <term-menu-card v-if="termMenuOpen" v-bind="props" @close="termMenuOpen = false" @confirmed="handleConfirm" />
    </v-menu>
</template>

<script setup lang="ts">
import { Ref, ref } from 'vue'
import { VTextField } from 'vuetify/components'
import TermMenuCard from '@/components/partials/termMenu/TermMenuCard.vue'

// define props
interface Props {
    currentTerm?: number
    mileage: number
    disabled?: boolean
    testing?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    testing: false,
    disabled: false,
})

const emit = defineEmits<{
    termApplied: [v: number]
}>()

const termMenuOpen: Ref<boolean> = ref(false)

function handleConfirm(value: number) {
    emit('termApplied', value)
    termMenuOpen.value = false
}
</script>

<style scoped></style>
