import { Ref, ref } from 'vue'
import { useInventoryStore } from '@/stores/inventory'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { useApplicationStore } from '@/stores/application'
import { TVehicleState } from '@/components/cards/VehicleForm.vue'

type TUseEditInvoiceReturn = {
    updateVehicleValue: (editVehicle: TVehicleState, currentVehicle?: Vehicle) => Promise<number | undefined>
    loading: Ref<boolean>
}

export function useEditVehicleInvoice(): TUseEditInvoiceReturn {
    const applicationStore = useApplicationStore()
    const inventoryStore = useInventoryStore()
    const loading: Ref<boolean> = ref(false)

    async function updateVehicleValue(vehicle: TVehicleState, currentVehicle?: Vehicle): Promise<number | undefined> {
        const valueDiff: number = Number(vehicle.invoice) - Number(currentVehicle?.value)
        if (!valueDiff) {
            return
        }
        loading.value = true
        const response = await inventoryStore.updateVehicle(
            {
                value: Number(vehicle.invoice),
                vin: vehicle.vin,
                year: Number(vehicle.year),
                zipcode: inventoryStore.dealerZipCode ?? '',
                miles: Number(vehicle.mileage),
            },
            vehicle.vin,
            applicationStore.account?.id,
        )
        loading.value = false
        return response?.value
    }

    return { updateVehicleValue, loading }
}
