<template>
    <!-- modal body -->
    <div class="flex flex-row gap-6 px-4 py-6 z-[53]" :cy-id="testing_id">
        <!-- LEFT SIDE -->
        <div class="bg-gray-50 py-4 px-6 w-full max-h-screen overflow-y-auto">
            <h2 class="pb-11 text-slate-900 text-[1.375rem]">Uploaded Files</h2>
            <div v-if="isLoadingAppDocs" class="p-3">
                <img class="mx-auto" src="@/assets/ajax-loader.gif" />
            </div>
            <div v-else class="bg-white">
                <p v-if="store.applicationDocuments.length === 0" class="bg-gray-50">No files uploaded yet</p>
                <p
                    v-for="(document, index) in store.applicationDocuments.sort((a, b) =>
                        a.filename.localeCompare(b.filename),
                    )"
                    v-else
                    :key="`document-upload-item-${index}`"
                    class="px-2 py-4"
                    data-test-file-name>
                    <file-upload
                        :progress-percent="0"
                        :account-id="accountId"
                        :application-id="applicationId"
                        :file-id="document.id"
                        upload-state="uploaded"
                        :compact="true"
                        @remove-file="onRemoveFileConfirmation(document)"
                        :file="document"
                        :code-word="password" />
                </p>
            </div>
        </div>
        <!-- /LEFT SIDE -->

        <!-- RIGHT SIDE -->
        <div class="bg-gray-50 py-4 px-6 w-full">
            <h2 class="pb-11 text-slate-900 text-[1.375rem]">Upload File</h2>
            <p class="pb-7">
                Use the form below to upload documents associated with this application. Keep in mind that you can
                upload multiple documents at a time. Max file size is 20MB
            </p>

            <div @dragover.prevent @drop.prevent class="flex flex-col">
                <div
                    class="relative flex flex-col justify-center items-center bg-white border border-gray-500 border-dashed ! p-2">
                    <!-- SELECTED FILES -->
                    <div
                        v-if="hasFile"
                        class="relative flex flex-col gap-2 justify-center items-center bg-white mb-6 w-full">
                        <file-upload
                            v-for="(item, index) in cQueue"
                            :key="`${index}-${item.file.name}`"
                            :file="createDocument(item.file as File)"
                            :file-id="0"
                            :account-id="accountId"
                            :application-id="applicationId"
                            :compact="true"
                            :upload-state="isUploading ? 'uploading' : 'preparing'"
                            :code-word="password"
                            @remove-file="onRemoveFileFromQueue(item.file as File)"
                            @upload-completed="onUploadCompleted"
                            @upload-fail="onUploadFail"
                            @validation-fail="onValidationFail" />
                    </div>
                    <!-- DROP AREA -->
                    <div
                        :class="`relative flex flex-col justify-center items-center  w-full ! ${cDropAreaStyles}`"
                        data-test-drag-drop-area
                        @dragover="onDragover($event as DragEvent)"
                        @drop="onDropFiles($event as DragEvent)">
                        <v-icon icon="$cloudUpload" :color="isNoFileError ? 'error' : 'text-secondary'" />
                        <p
                            :class="`font-medium ${
                                isNoFileError ? 'text-red-500' : isUploading ? 'text-gray-400' : 'text-slate-900'
                            }`">
                            Click to browse or drop files here
                        </p>
                        <input
                            :disabled="isUploading"
                            :accept="acceptedMimeTypes"
                            :class="`absolute top-0 right-0 cursor-pointer opacity-0 w-full h-full ${
                                isNoFileError ? 'text-red-500' : 'text-gray-900'
                            }`"
                            data-test-input-file
                            type="file"
                            multiple
                            @change="onChangeInputFiles($event as HTMLInputEvent)" />
                    </div>
                </div>
                <p class="mb-4">
                    If the document being uploaded is locked with a password, please type the password in the input
                    field below. If no password, please leave it blank
                </p>

                <v-text-field
                    v-model="password"
                    variant="filled"
                    persistent-placeholder
                    label="Document Password"
                    max-length="100" />

                <!-- ERROR SECTION -->
                <div
                    v-if="isNoFileError"
                    class="bg-red-100 border border-red-200 text-red-500 p-2 my-2"
                    data-test-no-file-error>
                    You must select a file to upload
                </div>
                <div
                    v-if="isWrongFileTypeError"
                    class="bg-red-100 border border-red-200 text-red-500 p-2 my-2"
                    data-test-wrong-file-type-error>
                    File Types Accepted: {{ acceptedExtensions }}
                </div>
                <div
                    v-if="isDuplicateFileError"
                    class="bg-red-100 border border-red-200 text-red-500 p-2 my-2"
                    data-test-duplicate-file-error>
                    A file with the same name already exists. Change file name and try again
                </div>
                <v-row class="py-6">
                    <v-col>
                        <v-btn
                            text="Cancel"
                            block
                            size="large"
                            color="primary"
                            variant="outlined"
                            class="text-none !tracking-normal !text-base"
                            @click="props.closeModal" />
                    </v-col>
                    <v-col>
                        <v-btn
                            :disabled="isUploading"
                            block
                            text="Upload Document"
                            size="large"
                            color="primary"
                            class="text-none !tracking-normal !text-base"
                            data-test-upload-btn
                            @click="upload" />
                    </v-col>
                </v-row>
            </div>
        </div>
        <!-- /RIGHT SIDE -->
        <v-snackbar color="#F9FAFB" v-model="showSnackbar"> {{ snackbarMessage }} </v-snackbar>
        <v-snackbar v-model="hasFileToDelete" color="#F9FAFB" :persistent="true">
            Are you sure you want to delete {{ fileToDelete?.filename }}?
            <template v-slot:actions>
                <v-btn color="slate-700 mr-2" variant="text" @click.native.stop="removeFile"> Yes </v-btn>
                <v-btn color="slate-700" variant="text" @click.native.stop="cancelConfirmDeletion"> No </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script lang="ts" setup>
import FileUpload from '@/components/partials/Upload/FileUpload.vue'
import DocumentTypes from '@/constants/documentTypes'
import { Document as StoreDocument } from '@/models/DocumentUpload'
import { useApplicationStore } from '@/stores/application'
import { useDealerStore } from '@/stores/dealer/dealer'
import dayjs from 'dayjs'
import { ComputedRef, Ref, computed, defineComponent, onMounted, ref, watchEffect } from 'vue'

export type Document = {
    name: string
    size?: number
    type: string
    password?: string
    types?: string[]
    lastModified?: number
    file64?: string
    file: File
    slice?: (start?: number, end?: number, contentType?: string) => Blob
}

defineComponent({
    name: 'DocumentUploadModal',
})

onMounted(() => {
    store.applicationDocuments = []
})

// define props
interface Props {
    testing_id?: string
    closeModal: Function
    accountId: number
    applicationId: number
}

const props = withDefaults(defineProps<Props>(), {
    testing_id: 'document-upload-modal',
})

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget
}

interface Queue {
    file: File
    finished: boolean
    hasError: boolean
}

// store to refs
const store = useApplicationStore()
const dealerStore = useDealerStore()

// define emits
interface EventsEmitted {
    (event: 'toggleModal'): void

    (event: 'uploading', v: boolean): void
}

const emit: EventsEmitted = defineEmits<EventsEmitted>()

// initializations
const password: Ref<string> = ref('')
const snackbarMessage = ref('')
const showSnackbar = ref(false)
const cDropAreaStyles: ComputedRef<string> = computed(() => {
    if (!hasFile.value) {
        return 'bg-white h-48'
    }

    return 'bg-gray-100 h-16'
})
const queue: Ref<Queue[]> = ref<Queue[]>([])
const cQueue: ComputedRef<Queue[]> = computed(() => queue.value)
const fileToDelete: Ref<StoreDocument | null> = ref<StoreDocument | null>(null)
const hasFileToDelete: ComputedRef<boolean> = computed(() => fileToDelete.value !== null)
const isUploading: Ref<boolean> = ref<boolean>(false)
const isLoadingAppDocs: Ref<boolean> = ref<boolean>(false)
const isWrongFileTypeError: Ref<boolean> = ref<boolean>(false)
const isDuplicateFileError: Ref<boolean> = ref<boolean>(false)
const isNoFileError: Ref<boolean> = ref<boolean>(false)
const isUploadError: Ref<boolean> = ref<boolean>(false)
const hasFile: ComputedRef<boolean> = computed(() => queue.value.length > 0)
const ready: ComputedRef<boolean> = computed(() => {
    return hasFile.value
})
const acceptedMimeTypes: ComputedRef<string> = computed(() => {
    return Object.values(DocumentTypes).join()
})
const acceptedExtensions: ComputedRef<string> = computed(() => {
    return '.' + Object.keys(DocumentTypes).join(', .')
})

if (props.accountId && props.accountId > 0) {
    store.getApplicationDocumentsByAccountID({
        accountId: props.accountId,
        applicationId: props.applicationId,
        dealerId: dealerStore?.dealer?.id as number,
    })
}
// event handlers
const onDropFiles = async (e: DragEvent): Promise<void> => {
    if (isUploading.value) {
        return
    }

    if (!e.dataTransfer) {
        return
    }

    let files = e.dataTransfer.files
    if (!files.length) {
        return
    }

    if (!validate(e.dataTransfer.items)) {
        isWrongFileTypeError.value = true
        return
    }

    queue.value = []
    for (let i = 0; i < files.length; i++) {
        queue.value.push({ file: files[i], finished: false, hasError: false })
    }
}
const onDragover = (event: DragEvent): void => {
    if (isUploading.value) {
        return
    }
    if (!event.dataTransfer?.items) {
        return
    }
    if (validate(event.dataTransfer.items)) {
        isWrongFileTypeError.value = false
    } else {
        event.dataTransfer.dropEffect = 'none'
        isWrongFileTypeError.value = true
    }
}
const onChangeInputFiles = async (e: HTMLInputEvent) => {
    if (isUploading.value) {
        return
    }
    if (!e.target || !e.target.files) {
        return
    }
    let files = e.target.files
    if (!files.length) {
        return
    }

    for (let i = 0; i < files.length; i++) {
        queue.value.push({ file: files[i], finished: false, hasError: false })
    }
}
// upload - pre-flight checks
const validate = (items: DataTransferItemList) => {
    for (let x = 0; x < items.length; x++) {
        if (!Object.values(DocumentTypes).includes(items[x].type)) {
            return false
        }
    }
    return true
}
// upload
function upload(): void {
    resetErrors()
    if (ready.value) {
        if (!isFileDuplicated()) {
            isUploading.value = true
            emit('uploading', true)
        }
    } else {
        if (!hasFile.value) {
            isNoFileError.value = true
        }
    }
}

const cleanUpFilename = (filename: string): string => {
    filename = filename.replace(' ', '_')
    filename = filename.replace(/[^a-zA-Z0-9._]/g, '')
    return filename
}

// resets and clean ups
const reset = (): void => {
    queue.value = []
    isUploading.value = false
}
const resetErrors = (): void => {
    isUploadError.value = false
    isNoFileError.value = false
    isDuplicateFileError.value = false
    isWrongFileTypeError.value = false
}

// utils
const isFileDuplicated = (): boolean => {
    if (store.applicationDocuments.length > 0) {
        store.applicationDocuments.find((document) => {
            if (queue.value.some((f) => cleanUpFilename(f.file.name) === cleanUpFilename(document.filename))) {
                isDuplicateFileError.value = true
            }
        })
    }
    return isDuplicateFileError.value
}

function createDocument(file: File): StoreDocument {
    return {
        filename: cleanUpFilename(file.name),
        size: file.size,
        type: file.type,
        file64: '',
        id: 0,
        account_id: props.accountId,
        application_id: props.applicationId,
        types: [{ id: 14 }], //set to 'other' for now
        code_word: '',
        source: 'pinpoint',
        file,
        created_at: dayjs().format('YYYY-MM-DD'),
        updated_at: dayjs().format('YYYY-MM-DD'),
        tried: false,
        tags: [],
        updated_by: {
            id: 0,
            first_name: '',
            last_name: '',
        },
    }
}

function onRemoveFileConfirmation(document: StoreDocument): void {
    fileToDelete.value = document
}

async function removeFile(): Promise<void> {
    if (fileToDelete.value) {
        isLoadingAppDocs.value = true
        const fileId = fileToDelete.value.id
        fileToDelete.value = null
        await store.deleteDocument(fileId)
        if (props.accountId && props.accountId > 0) {
            await store
                .getApplicationDocumentsByAccountID({
                    applicationId: props.applicationId,
                    accountId: props.accountId,
                })
                .finally(() => (isLoadingAppDocs.value = false))
        }
    }
}

function cancelConfirmDeletion(event: any): void {
    fileToDelete.value = null
}

function onRemoveFileFromQueue(file: File): void {
    queue.value = queue.value.filter((f) => f.file !== file)
    if (queue.value.length === 0) {
        reset()
    }
}

async function onUploadCompleted(document: StoreDocument): Promise<void> {
    const fileIndex = queue.value.findIndex((f) => cleanUpFilename(f.file.name) === cleanUpFilename(document.filename))
    queue.value[fileIndex].finished = true
}

function onUploadFail(document: StoreDocument): void {
    const fileIndex = queue.value.findIndex((f) => cleanUpFilename(f.file.name) === cleanUpFilename(document.filename))
    queue.value[fileIndex].finished = true
    queue.value[fileIndex].hasError = true
    showSnackbar.value = true

    setTimeout(() => {
        showSnackbar.value = false
    }, 3000)
    snackbarMessage.value = `The document ${document.filename} did not upload. Please try again`
}

function onValidationFail(document: StoreDocument): void {
    const fileIndex = queue.value.findIndex((f) => cleanUpFilename(f.file.name) === cleanUpFilename(document.filename))
    queue.value[fileIndex].finished = true
    queue.value[fileIndex].hasError = true
}

watchEffect(async () => {
    if (queue.value.length > 0 && queue.value.every((f) => f.finished)) {
        isUploading.value = false
        emit('uploading', false)

        isLoadingAppDocs.value = true
        await store.getApplicationDocumentsByAccountID({
            accountId: props.accountId,
            applicationId: props.applicationId,
        })

        isLoadingAppDocs.value = false

        queue.value = queue.value
            .filter((f) => f.hasError)
            .map((f) => ({ file: f.file, finished: false, hasError: false }))

        if (queue.value.length === 0) {
            showSnackbar.value = true
            setTimeout(() => {
                showSnackbar.value = false
            }, 3000)
            snackbarMessage.value = 'Your documents were successfully uploaded!'
        }
    }
})
</script>
