import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './styles/__style.scss'
import '@/styles/main.scss'
import router from './router/router.js'
import { useBrowserLogs } from '@/composables/logger'
import { installToaster } from './components/popables/Toaster/shared'
import { vuetify } from '@/plugins/vuetify'
import App from './App.vue'

import oktaResource from './auth/oktaResource'

// production only resources
if (import.meta.env.MODE === 'production') {
    // podium
    const html = document.getElementsByTagName('html')[0]
    const podium = document.createElement('script')

    podium.setAttribute('defer', '')
    podium.setAttribute('src', 'https://connect.podium.com/widget.js#ORG_TOKEN=c21fbe5f-7551-4eff-8920-cf618fce2143')
    podium.setAttribute('id', 'podium-widget')
    podium.setAttribute('data-organization-api-token', 'c21fbe5f-7551-4eff-8920-cf618fce2143')

    html.appendChild(podium)
}

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(useBrowserLogs)
app.use(vuetify)
app.use(oktaResource)
installToaster(app)
app.mount('#app')

export { app, router }
