import OktaVue from '@okta/okta-vue'
import { OktaAuth } from '@okta/okta-auth-js'
import { App } from 'vue'

const oktaAuth = new OktaAuth({
    clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
    issuer: import.meta.env.VITE_OKTA_ISSUER,
    redirectUri: import.meta.env.VITE_OKTA_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    devMode: import.meta.env.VITE_OKTA_AUTH_JS_DEV_MODE === 'true',
    pkce: true,
    tokenManager: {
        autoRemove: true,
        autoRenew: true,
        syncStorage: true,
        secure: true,
    },
})

export default (app: App) => {
    app.use(OktaVue, { oktaAuth })
}
