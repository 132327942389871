import type { ThemeDefinition } from 'vuetify'
import { lightTheme as arivoLightTheme } from '@arivo-software-development/vuetify-theme'
import defu from 'defu'

type Theme = ThemeDefinition & { colors: Partial<Record<keyof typeof arivoLightTheme, any>> }
export const lightTheme = defu(
    {
        colors: {
            'border-neutral': arivoLightTheme.colors['grey-darken-1'],
        },
        variables: {
            'border-color': arivoLightTheme.colors['grey-darken-1'],
        },
    } satisfies Theme,
    arivoLightTheme,
)
