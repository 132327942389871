export interface StatisticsDataResponse {
    dealer_id: string
    avg_funding_time_minutes_all_time: number
    avg_funding_time_minutes_30: number
    avg_funding_time_minutes_60: number
    avg_funding_time_minutes_90: number
    current_funding: number
    count_funding_status_all_time: number
    count_funding_status_12_months: number
    apps_all_time: number
    apps_30: number
    apps_60: number
    apps_90: number
    booked_all_time: number
    booked_30: number
    booked_60: number
    booked_90: number
    approvals_all_time: number
    approvals_30: number
    approvals_60: number
    approvals_90: number
    avg_time_to_approved_minutes_all_time: number
    avg_time_to_approved_minutes_30: number
    avg_time_to_approved_minutes_60: number
    avg_time_to_approved_minutes_90: number
    avg_time_approved_to_board_minutes_all_time: number
    avg_time_approved_to_board_minutes_30: number
    avg_time_approved_to_board_minutes_60: number
    avg_time_approved_to_board_minutes_90: number
    bk_7_all_time: number
    bk_7_30: number
    bk_7_prev_30: number
    bk_7_60: number
    bk_7_prev_60: number
    bk_7_90: number
    bk_7_prev_90: number
    bk_13_all_time: number
    bk_13_30: number
    bk_13_prev_30: number
    bk_13_60: number
    bk_13_prev_60: number
    bk_13_90: number
    bk_13_prev_90: number
    thin_file_all_time: number
    thin_file_30: number
    thin_file_prev_30: number
    thin_file_60: number
    thin_file_prev_60: number
    thin_file_90: number
    thin_file_prev_90: number
}

export interface StatisticsData {
    dealerId: string
    avgFundingTimeMinutesAllTime: number
    avgFundingTimeMinutes30: number
    avgFundingTimeMinutes60: number
    avgFundingTimeMinutes90: number
    currentFunding: number
    countFundingStatusAllTime: number
    countFundingStatus12Months: number
    appsAllTime: number
    apps30: number
    apps60: number
    apps90: number
    bookedAllTime: number
    booked30: number
    booked60: number
    booked90: number
    approvalsAllTime: number
    approvals30: number
    approvals60: number
    approvals90: number
    avgTimeToApprovedMinutesAllTime: number
    avgTimeToApprovedMinutes30: number
    avgTimeToApprovedMinutes60: number
    avgTimeToApprovedMinutes90: number
    avgTimeApprovedToBoardMinutesAllTime: number
    avgTimeApprovedToBoardMinutes30: number
    avgTimeApprovedToBoardMinutes60: number
    avgTimeApprovedToBoardMinutes90: number
    bk7AllTime: number
    bk730: number
    bk7Prev30: number
    bk760: number
    bk7Prev60: number
    bk790: number
    bk7Prev90: number
    bk13AllTime: number
    bk1330: number
    bk13Prev30: number
    bk1360: number
    bk13Prev60: number
    bk1390: number
    bk13Prev90: number
    thinFileAllTime: number
    thinFile30: number
    thinFilePrev30: number
    thinFile60: number
    thinFilePrev60: number
    thinFile90: number
    thinFilePrev90: number
}

export const defaultStatisticsDataResponse: StatisticsDataResponse = {
    dealer_id: '',
    avg_funding_time_minutes_all_time: 0,
    avg_funding_time_minutes_30: 0,
    avg_funding_time_minutes_60: 0,
    avg_funding_time_minutes_90: 0,
    current_funding: 0,
    count_funding_status_all_time: 0,
    count_funding_status_12_months: 0,
    apps_all_time: 0,
    apps_30: 0,
    apps_60: 0,
    apps_90: 0,
    booked_all_time: 0,
    booked_30: 0,
    booked_60: 0,
    booked_90: 0,
    approvals_all_time: 0,
    approvals_30: 0,
    approvals_60: 0,
    approvals_90: 0,
    avg_time_to_approved_minutes_all_time: 0,
    avg_time_to_approved_minutes_30: 0,
    avg_time_to_approved_minutes_60: 0,
    avg_time_to_approved_minutes_90: 0,
    avg_time_approved_to_board_minutes_all_time: 0,
    avg_time_approved_to_board_minutes_30: 0,
    avg_time_approved_to_board_minutes_60: 0,
    avg_time_approved_to_board_minutes_90: 0,
    bk_7_all_time: 0,
    bk_7_30: 0,
    bk_7_prev_30: 0,
    bk_7_60: 0,
    bk_7_prev_60: 0,
    bk_7_90: 0,
    bk_7_prev_90: 0,
    bk_13_all_time: 0,
    bk_13_30: 0,
    bk_13_prev_30: 0,
    bk_13_60: 0,
    bk_13_prev_60: 0,
    bk_13_90: 0,
    bk_13_prev_90: 0,
    thin_file_all_time: 0,
    thin_file_30: 0,
    thin_file_prev_30: 0,
    thin_file_60: 0,
    thin_file_prev_60: 0,
    thin_file_90: 0,
    thin_file_prev_90: 0,
}

export interface SimplifiedStatisticsData {
    dealerId: string
    avgFundingTimeMinutes: number
    currentFunding: number
    countFundingStatus: number
    apps: number
    booked: number
    approvals: number
    avgTimeToApprovedMinutes: number
    avgTimeApprovedToBoardMinutes: number
    bk7: number
    bk7Prev: number
    bk7PrevPercent: number
    bk7AppPercent: number
    bk13: number
    bk13Prev: number
    bk13PrevPercent: number
    bk13AppPercent: number
    thinFile: number
    thinFilePrev: number
    thinFilePrevPercent: number
    thinFileAppPercent: number
}

export function convertToStatisticsData(response: StatisticsDataResponse): StatisticsData {
    return {
        dealerId: response.dealer_id,
        avgFundingTimeMinutesAllTime: response.avg_funding_time_minutes_all_time,
        avgFundingTimeMinutes30: response.avg_funding_time_minutes_30,
        avgFundingTimeMinutes60: response.avg_funding_time_minutes_60,
        avgFundingTimeMinutes90: response.avg_funding_time_minutes_90,
        currentFunding: response.current_funding,
        countFundingStatusAllTime: response.count_funding_status_all_time,
        countFundingStatus12Months: response.count_funding_status_12_months,
        appsAllTime: response.apps_all_time,
        apps30: response.apps_30,
        apps60: response.apps_60,
        apps90: response.apps_90,
        bookedAllTime: response.booked_all_time,
        booked30: response.booked_30,
        booked60: response.booked_60,
        booked90: response.booked_90,
        approvalsAllTime: response.approvals_all_time,
        approvals30: response.approvals_30,
        approvals60: response.approvals_60,
        approvals90: response.approvals_90,
        avgTimeToApprovedMinutesAllTime: response.avg_time_to_approved_minutes_all_time,
        avgTimeToApprovedMinutes30: response.avg_time_to_approved_minutes_30,
        avgTimeToApprovedMinutes60: response.avg_time_to_approved_minutes_60,
        avgTimeToApprovedMinutes90: response.avg_time_to_approved_minutes_90,
        avgTimeApprovedToBoardMinutesAllTime: response.avg_time_approved_to_board_minutes_all_time,
        avgTimeApprovedToBoardMinutes30: response.avg_time_approved_to_board_minutes_30,
        avgTimeApprovedToBoardMinutes60: response.avg_time_approved_to_board_minutes_60,
        avgTimeApprovedToBoardMinutes90: response.avg_time_approved_to_board_minutes_90,
        bk7AllTime: response.bk_7_all_time,
        bk730: response.bk_7_30,
        bk7Prev30: response.bk_7_prev_30,
        bk760: response.bk_7_60,
        bk7Prev60: response.bk_7_prev_60,
        bk790: response.bk_7_90,
        bk7Prev90: response.bk_7_prev_90,
        bk13AllTime: response.bk_13_all_time,
        bk1330: response.bk_13_30,
        bk13Prev30: response.bk_13_prev_30,
        bk1360: response.bk_13_60,
        bk13Prev60: response.bk_13_prev_60,
        bk1390: response.bk_13_90,
        bk13Prev90: response.bk_13_prev_90,
        thinFileAllTime: response.thin_file_all_time,
        thinFile30: response.thin_file_30,
        thinFilePrev30: response.thin_file_prev_30,
        thinFile60: response.thin_file_60,
        thinFilePrev60: response.thin_file_prev_60,
        thinFile90: response.thin_file_90,
        thinFilePrev90: response.thin_file_prev_90,
    }
}

export function getDataFromSpecificTimeFrame(data: StatisticsData, timeFrame: number): SimplifiedStatisticsData {
    const apps = Number(data[`apps${timeFrame}` as keyof StatisticsData])
    const bk7 = Number(data[`bk7${timeFrame}` as keyof StatisticsData])
    const bk7Prev = Number(data[`bk7Prev${timeFrame}` as keyof StatisticsData])
    const bk13 = Number(data[`bk13${timeFrame}` as keyof StatisticsData])
    const bk13Prev = Number(data[`bk13Prev${timeFrame}` as keyof StatisticsData])
    const thinFile = Number(data[`thinFile${timeFrame}` as keyof StatisticsData])
    const thinFilePrev = Number(data[`thinFilePrev${timeFrame}` as keyof StatisticsData])

    return {
        dealerId: data.dealerId,
        avgFundingTimeMinutes: Number(data[`avgFundingTimeMinutes${timeFrame}` as keyof StatisticsData]),
        currentFunding: data.currentFunding,
        countFundingStatus: data.countFundingStatusAllTime,
        apps: apps,
        booked: Number(data[`booked${timeFrame}` as keyof StatisticsData]),
        approvals: Number(data[`approvals${timeFrame}` as keyof StatisticsData]),
        avgTimeToApprovedMinutes: Number(data[`avgTimeToApprovedMinutes${timeFrame}` as keyof StatisticsData]),
        avgTimeApprovedToBoardMinutes: Number(
            data[`avgTimeApprovedToBoardMinutes${timeFrame}` as keyof StatisticsData],
        ),
        bk7: bk7,
        bk7Prev: bk7Prev,
        bk7PrevPercent: bk7Prev !== 0 ? ((bk7 - bk7Prev) / bk7Prev) * 100 : 0,
        bk7AppPercent: apps !== 0 ? (bk7 / apps) * 100 : 0,
        bk13: bk13,
        bk13Prev: bk13Prev,
        bk13PrevPercent: bk13Prev !== 0 ? ((bk13 - bk13Prev) / bk13Prev) * 100 : 0,
        bk13AppPercent: apps !== 0 ? (bk13 / apps) * 100 : 0,
        thinFile: thinFile,
        thinFilePrev: thinFilePrev,
        thinFilePrevPercent: thinFilePrev !== 0 ? ((thinFile - thinFilePrev) / thinFilePrev) * 100 : 0,
        thinFileAppPercent: apps !== 0 ? (thinFile / apps) * 100 : 0,
    }
}
