<template>
    <histogram-slider
        ref="el"
        :min="min"
        :max="max"
        class="m-auto"
        :width="500"
        :bar-height="25"
        :bar-width="4"
        :line-height="2"
        :bar-gap="1"
        :hist-slider-gap="0"
        :hide-from-to="true"
        :handle-size="10"
        :clip="false"
        :data="cHistogramData"
        :grid="true"
        :force-edges="false"
        :drag-interval="true"
        @change="onSliderChange" />
    <v-row justify="space-between">
        <v-col cols="3" class="pt-0">
            <currency-input
                v-model="min"
                cy-id="min-histogram-input"
                class="slider-input"
                :vuetify-props="{
                    prefix: show ? '$' : '',
                    density: 'compact',
                    hideDetails: true,
                }"
                :options="{
                    currency: 'USD',
                    currencyDisplay: CurrencyDisplay.hidden,
                    precision: 0,
                    useGrouping: show,
                    valueRange: { min: 0 },
                }"
                @input="updateSlider"></currency-input>
        </v-col>
        <v-col cols="3" class="pt-0">
            <currency-input
                v-model.number="max"
                cy-id="max-histogram-input"
                class="slider-input"
                :vuetify-props="{
                    prefix: show ? '$' : '',
                    density: 'compact',
                    hideDetails: true,
                }"
                :options="{
                    currency: 'USD',
                    currencyDisplay: CurrencyDisplay.hidden,
                    valueRange: { min: 0 },
                    precision: 0,
                    useGrouping: show,
                }"
                @input="updateSlider"></currency-input>
        </v-col>
    </v-row>
</template>

<script lang="ts" setup>
import 'vue3-histogram-slider/dist/histogram-slider.css'
import {
    computed,
    ComputedRef,
    defineAsyncComponent,
    onMounted,
    reactive,
    Ref,
    ref,
    toRefs,
    watch,
    watchEffect,
} from 'vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { CurrencyDisplay } from 'vue-currency-input'
import { TSliderValues } from '@/models/Dealer/InventoryFilters'

type SliderSelectedValues = {
    from: number
    to: number
}

interface Props {
    filterData: TSliderValues
    show?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    show: false,
})

const emit = defineEmits<{
    'selected-filters': [value: { min: number; max: number }]
    enable: [value: boolean]
}>()
//@ts-ignore
const HistogramSlider = defineAsyncComponent(() => import('vue3-histogram-slider'))
const el: Ref<any> = ref(null)

const cHistogramData: ComputedRef<number[]> = computed(() => {
    if (props.filterData.Values.length > 2) {
        return props.filterData.Values
    } else {
        return []
    }
})

const minMax = reactive<SliderSelectedValues>({
    from: props.filterData.Values[0],
    to: props.filterData.Values[props.filterData.Values.length - 1],
})
const { from: min, to: max }: { from: Ref<number>; to: Ref<number> } = toRefs(minMax)

function onSliderChange(t: SliderSelectedValues): void {
    min.value = t.from
    max.value = t.to
    emitSelectedValues()
}

function updateSlider(): void {
    el.value?.update(minMax)
    emitSelectedValues()
}

function assignInitialMinMax(): void {
    if (props.filterData.selectedMax) {
        min.value = props.filterData.selectedMin
        max.value = props.filterData.selectedMax
        el.value?.update(minMax)
    }
    emit('enable', true)
}
function emitSelectedValues(): void {
    emit('enable', false)
    emit('selected-filters', { min: min.value, max: max.value })
}
onMounted(() => {
    setTimeout(() => {
        assignInitialMinMax()
    }, 100)
})
</script>

<style scoped>
:deep(.irs-grid) {
    display: none;
}
</style>
