export const MAX_DISCOUNT_PERCENTAGE = 2.0
export const MIN_LOAN_AMOUNT = 8000
export const MILEAGE_LEEWAY = 1500
export const MIN_TERM = 2
export const MIN_INVOICE_VALUE = 5925
export const MAX_USED_LTV = 1.35
export const MAX_NEW_LTV = 1.25
export const DAYS_TO_EXPIRE = 30
export const DAYS_WHEN_IN_FUNDING = 10
export const APPROVED_TERMS = [72, 66, 60, 54]
export const MAX_VEHICLE_AGE = 10
export const MAX_MILEAGE_FOR_CURRENT_YEAR_CAR = 5000