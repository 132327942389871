<template>
    <base-banner class="text-red-darken-2" :banner-text="cBannerText"></base-banner>
</template>
<script setup lang="ts">
import BaseBanner from '@/components/partials/banners/BaseBanner.vue'
import { ComputedRef, computed } from 'vue'
import phoneNumbers from '@/constants/phoneNumbers'
import { storeToRefs } from 'pinia'
import { useDealerStore } from '@/stores/dealer/dealer'

const { dealer } = storeToRefs(useDealerStore())
const phoneNumber: ComputedRef<string> = computed(() => {
    if (dealer.value.team_id) {
        return phoneNumbers[dealer.value.team_id]
    }
    return ''
})
const cBannerText: ComputedRef<string> = computed(
    () =>
        `Your dealership is in suspended status. You will still be able to structure loans but your loans will not be able to be funded until the suspension is resolved. Please call ${phoneNumber.value} to resolve it
`,
)
</script>
