import { AccountNote, AccountNoteResponse } from '@/models/Notes/AccountNote'
import { Ref, ref } from 'vue'
import pinpointClient from '@/clients/pinpointClient'

export const useNotes = () => {
    const accountNotes: Ref<AccountNote[]> = ref([])

    function whoReplied(note: AccountNote) {
        if (note.typeId === 2) {
            return 'Arivo Acceptance'
        } else if (note.typeId === 3) {
            return note.dealerName
        }
    }

    async function getAccountNotes(accountId: number): Promise<void> {
        accountNotes.value = []
        try {
            const { data } = await pinpointClient.get(`/notes/account/get/${accountId}`)
            if (data) {
                accountNotes.value = data.map((note: AccountNoteResponse): AccountNote => {
                    return {
                        id: note.id,
                        note: note.note,
                        dealerName: note.dealer_name,
                        typeId: note.type_id,
                        createdAt: note.created_at,
                    }
                })
            }
        } catch {
            return
        }
    }

    return {
        whoReplied,
        accountNotes,
        getAccountNotes,
    }
}
