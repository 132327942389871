import pinpointClient from '@/clients/pinpointClient'
import dayjs from 'dayjs'
import { Ref, ref } from 'vue'
import { useLogger } from './logger'

type TApprovalAccounts = {
    account_id: number
    decisioned: string
    created_at: string
    primary_borrower_first_name: string
    primary_borrower_last_name: string
    co_borrower_name: string
}

export function useApprovals(dealerId: number) {
    const logger = useLogger()
    const statusIds: string = '3,4'
    const startDate: string = dayjs().subtract(30, 'days').format('YYYY-MM-DD')
    const endDate: string = dayjs().add(30, 'days').format('YYYY-MM-DD')
    const sort: Ref<string> = ref<string>('decisioned desc')
    const page: Ref<number> = ref(1)
    const pageLength: Ref<number> = ref(100)
    const rows: Ref<TApprovalAccounts[]> = ref([])
    const loadingApprovals: Ref<boolean> = ref(false)

    async function getActiveApprovals() {
        const queryParams = {
            statusIds,
            startDate,
            endDate,
            sort: sort.value,
            page: page.value,
            pageLength: pageLength.value,
        }
        try {
            loadingApprovals.value = true
            const response = await pinpointClient.get(`/accounts/all/dealer/${dealerId}`, { params: queryParams })
            rows.value = response.data.Accounts
            return response.data.Accounts
        } catch (e) {
            rows.value = []
            return []
        } finally {
            loadingApprovals.value = false
        }
    }

    function updateSorting(sortingBy: string) {
        sort.value = sortingBy
        getActiveApprovals()
    }

    function setPage(pageNumber: number) {
        page.value = pageNumber
        getActiveApprovals()
    }

    function setPageLength(pageLengthNumber: number) {
        pageLength.value = pageLengthNumber
        getActiveApprovals()
    }

    return {
        getActiveApprovals,
        page,
        pageLength,
        rows,
        setPage,
        setPageLength,
        updateSorting,
        loadingApprovals,
    }
}
