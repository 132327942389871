<template>
    <v-container fluid class="!px-0 pt-0">
        <v-card class="!rounded-lg">
            <v-toolbar color="white" class="px-3">
                <v-toolbar-items class="w-1/2">
                    <inventory-search cy-id="inventory-search-bar" />
                </v-toolbar-items>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <menu-sort
                        cy-id="inventory-sort-bar-sort"
                        :sort-menu-items="cOptions"
                        :selected="filters.sort_by"
                        @emit-sort="capturingEvent" />
                </v-toolbar-items>
                <v-toolbar-items>
                    <v-btn
                        cy-id="toggle-vertical-view-btn"
                        @click="toggleHorizontalView = false"
                        :class="{ 'text-grey': toggleHorizontalView }"
                        density="compact"
                        icon="$viewModule"></v-btn>
                    <v-btn
                        cy-id="toggle-horizontal-view-btn"
                        :class="{ 'text-grey': !toggleHorizontalView }"
                        @click="toggleHorizontalView = true"
                        density="compact"
                        icon="$viewStream"></v-btn>
                </v-toolbar-items>
            </v-toolbar>
        </v-card>
    </v-container>
</template>

<script setup lang="ts">
import { computed, ComputedRef, reactive, Ref, ref } from 'vue'
import { useInventoryStore } from '@/stores/inventory'
import { storeToRefs } from 'pinia'
import { useApplicationStore } from '@/stores/application'
import MenuSort, { ISortMenu } from '@/components/MenuSort.vue'
import InventorySearch from '@/components/InventorySearch.vue'
import { inventorySortByMap } from '@/models/Dealer/InventoryFilters'
const inventoryStore = useInventoryStore()
const { account } = storeToRefs(useApplicationStore())
const { filters, toggleHorizontalView } = storeToRefs(inventoryStore)

const options = reactive<ISortMenu[]>([
    {
        title: 'Estimated Gross',
        value: inventorySortByMap.estimatedGross,
    },
    { type: 'divider' },
    { type: 'subheader', title: 'Price' },
    {
        value: inventorySortByMap.priceHighToLow,
        title: 'High to Low',
        prependTitle: 'Price',
    },
    {
        value: inventorySortByMap.priceLowToHigh,
        title: 'Low to High',
        prependTitle: 'Price',
    },
    { type: 'divider' },

    { type: 'subheader', title: 'Payment' },
    {
        value: inventorySortByMap.paymentHighToLow,
        title: 'High to Low',
        prependTitle: 'Payment',
    },
    {
        value: inventorySortByMap.paymentLowToHigh,
        title: 'Low to High',
        prependTitle: 'Payment',
    },
    { type: 'divider' },
    { type: 'subheader', title: 'Inventory' },
    {
        value: inventorySortByMap.inventoryOldToNew,
        title: 'Old to New',
        prependTitle: 'Inventory',
    },
    {
        value: inventorySortByMap.inventoryNewToOld,
        title: 'New to Old',
        prependTitle: 'Inventory',
    },
    { type: 'divider' },
    { type: 'subheader', title: 'Amount Financed' },
    {
        value: inventorySortByMap.amountFinancedHighToLow,
        title: 'High to Low',
        prependTitle: 'Amount Financed',
    },
    {
        value: inventorySortByMap.amountFinancedLowToHigh,
        title: 'Low to High',
        prependTitle: 'Amount Financed',
    },
])

const cOptions: ComputedRef<ISortMenu[]> = computed(() => {
    if (filters.value.sort_by === inventorySortByMap.relevance) {
        return [...options, { type: 'divider' }, { value: '-relevance', title: 'Relevance' }]
    }
    return options
})

function capturingEvent(sort: string): void {
    inventoryStore.updateSortByFilter(sort, account.value?.id)
}
</script>
