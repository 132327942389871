type teamNumber = { [propKey: number]: string }

const numbers: teamNumber = {
    7: '801-285-0807',
    8: '801-542-9482',
    9: '801-508-3944',
    10: '844-682-7486',
}

export default numbers
