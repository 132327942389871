<template>
    <div :class="`grid ${cGridCols} gap-2 min-w-fit justify-items-stretch w-full max-w-xl`">
        <div v-for="info in displayFields" :key="info.label">
            <div class="text-gray-500 text-[11px] font-normal">
                {{ info.label }}
            </div>
            <div
                class="text-xs font-semibold"
                :class="[
                    { 'text-vuetify-error': info.label === 'Max Fin.' && !vehicleInfo?.value },
                    { 'text-teal-600': info.label === 'List Price' },
                ]">
                {{ info.value }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { money } from '@/helpers/formatters.ts'
import { Vehicle } from '@/models/Vehicle/Vehicle.ts'
import { computed, ComputedRef } from 'vue'
import { storeToRefs } from 'pinia'
import { useInventoryStore } from '@/stores/inventory.ts'

const props = defineProps<{ vehicleInfo?: Vehicle }>()
const { filters } = storeToRefs(useInventoryStore())

const computedListPrice: ComputedRef<string> = computed(() =>
    props.vehicleInfo?.price ? money(props.vehicleInfo.price) : '-',
)

const computedPaymentText = computed(() => {
    return props.vehicleInfo?.value ? `${money(props.vehicleInfo?.dealStructure?.payment)}` : 'Invoice required'
})

const computedMaxAmountFinancedText: ComputedRef<string> = computed(() => {
    return props.vehicleInfo?.value ? `${money(props.vehicleInfo?.maxAmountFinanced)}` : 'Invoice required'
})

const displayFields: ComputedRef<{ label: string; value: string }[]> = computed(() => {
    const fields = [{ label: 'List Price', value: computedListPrice.value }]

    if (props.vehicleInfo?.used) {
        fields.push(
            { label: 'Max Fin.', value: money(props.vehicleInfo.maxAmountFinanced ?? 0) },
            { label: 'Payment', value: money(props.vehicleInfo.dealStructure?.payment ?? 0) },
            { label: 'Term', value: String(props.vehicleInfo?.maxTerm ?? 0) },
            { label: 'Total Fees', value: money(props.vehicleInfo.dealStructure?.totalFees ?? 0) },
            { label: 'Disbursement', value: money(props.vehicleInfo.dealStructure?.netDisbursement ?? 0) },
        )
    } else {
        fields.push(
            { label: 'Term', value: String(props.vehicleInfo?.maxTerm ?? 0) },
            { label: 'Max Fin.', value: computedMaxAmountFinancedText.value },
            { label: 'Payment', value: computedPaymentText.value },
        )
    }

    return fields
})

const cGridCols: ComputedRef<string> = computed(() => (filters.value?.used ? 'grid-cols-3' : 'grid-cols-2'))
</script>
