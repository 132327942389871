import { Toast } from '@/models/toaster/toast'
import pinpointClient from '@/clients/pinpointClient'
import { computed, ComputedRef, onMounted, Ref, ref } from 'vue'
import { inject } from 'vue'

type Stipulation = {
    details: string
    description: {
        id: number
        name: string
    }
    status: {
        id: number
        name: string
    }
    stipulation_id: number
    borrower_id: number
    borrowerType: string
}

type StipulationTypes = {
    id: number
    name: string
}

const stipulationTypes: Ref<StipulationTypes[]> = ref([])
const accountStipulations: Ref<Stipulation[]> = ref([])
const [pendingStatus, requestedStatus, requestCancelledStatus] = [1, 2, 3] as const
export const useStipulations = () => {
    const popToastLocal = inject('popToastLocal') as (toast: Toast) => {}

    async function getAllStipulationTypes(): Promise<void> {
        try {
            const { data } = await pinpointClient.get('stipulations/all')
            if (data) {
                stipulationTypes.value = data
                return
            }
        } catch {
            popToastLocal({
                title: 'Stipulations Error',
                message: 'We ran into a problem while trying to get all the stipulation types.',
                timer: 5000,
                location: 'top-center',
            })
        }
    }

    async function getStipulationAssociationsForAccount(accountId: number, borrowerId: number): Promise<void> {
        accountStipulations.value = []
        try {
            const { data } = await pinpointClient.get(`stipulations/account/${accountId}`)
            if (data) {
                accountStipulations.value = data
                    .map((stip: Stipulation) => ({
                        ...stip,
                        description: stipulationTypes.value.find((type) => type.id === stip.stipulation_id),
                        borrowerType: borrowerId == stip.borrower_id ? ' (Borrower)' : ' (Co-Borrower)',
                    }))
                    .filter(
                        (stip: Stipulation) =>
                            stip.status.id !== pendingStatus && stip.status.id !== requestCancelledStatus,
                    )
            }
        } catch {
            return
        }
    }

    const filteredStips: ComputedRef<{ description: string; status: string }[]> = computed(() => {
        return accountStipulations.value
            .map((stip: Stipulation) => {
                return {
                    description: stip.description.name + stip.borrowerType,
                    status: stip.status.id === requestedStatus ? 'requested' : 'received',
                }
            })
            .sort((a: { description: string; status: string }, b): number => {
                if (a.status > b.status) {
                    return -1
                }
                return 0
            })
    })
    onMounted(async () => {
        if (!stipulationTypes.value.length) {
            await getAllStipulationTypes()
        }
    })
    return {
        accountStipulations,
        getStipulationAssociationsForAccount,
        filteredStips,
    }
}
