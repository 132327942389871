import { createVuetify, VuetifyOptions } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { config } from '@arivo-software-development/vuetify-theme'
import { lightTheme } from './vuetify/theme'
import { defu } from 'defu'
import {
    mdiTrashCanOutline,
    mdiClipboardCheckOutline,
    mdiWindowClose,
    mdiMenuDown,
    mdiTrendingUp,
    mdiCheck,
    mdiClipboardListOutline,
    mdiCashMultiple,
    mdiCar,
    mdiClose,
    mdiInformationSlabCircleOutline,
    mdiCheckCircleOutline,
    mdiFileOutline,
    mdiViewModule,
    mdiViewStream,
    mdiContacts,
    mdiHeartOutline,
    mdiCloudUploadOutline,
    mdiPhoneOutline,
    mdiShareVariant,
    mdiTrainCar,
    mdiDotsHorizontal,
    mdiCloudUpload,
    mdiTimerOutline,
    mdiTrendingDown,
    mdiHeart,
} from '@mdi/js'
export const vuetify = createVuetify(
    defu(
        {
            icons: {
                defaultSet: 'mdi',
                aliases: {
                    ...aliases,
                    trashCanOutline: mdiTrashCanOutline,
                    clipboardCheckOutline: mdiClipboardCheckOutline,
                    windowClose: mdiWindowClose,
                    menuDown: mdiMenuDown,
                    trendingUp: mdiTrendingUp,
                    check: mdiCheck,
                    clipboardListOutline: mdiClipboardListOutline,
                    cashMultiple: mdiCashMultiple,
                    car: mdiCar,
                    close: mdiClose,
                    informationSlabCircleOutline: mdiInformationSlabCircleOutline,
                    checkCircleOutline: mdiCheckCircleOutline,
                    fileOutline: mdiFileOutline,
                    viewModule: mdiViewModule,
                    viewStream: mdiViewStream,
                    contacts: mdiContacts,
                    heartOutline: mdiHeartOutline,
                    cloudUploadOutline: mdiCloudUploadOutline,
                    phoneOutline: mdiPhoneOutline,
                    shareVariant: mdiShareVariant,
                    trainCar: mdiTrainCar,
                    dotsHorizontal: mdiDotsHorizontal,
                    cloudUpload: mdiCloudUpload,
                    timerOutline: mdiTimerOutline,
                    trendingDown: mdiTrendingDown,
                    heart: mdiHeart,
                },
                sets: { mdi },
            },
            defaults: {
                global: {
                    hideDetails: false,
                },
                VTextField: {
                    bgColor: '',
                },
                VBtn: {
                    class: 'custom-outlined-button',
                },
                VChip: {
                    class: 'custom-outlined-chip',
                },
                VCard: {
                    border: false,
                    rounded: 'lg',
                },
            },
            theme: {
                themes: {
                    light: lightTheme,
                },
            },
        } satisfies VuetifyOptions,
        config,
    ),
)
