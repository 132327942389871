<template>
    <v-card class="bg-white toast">
        <v-card-title
            class="!flex !flex-row justify-between items-center border-b-[1px] border-black !text-22px !font-normal">
            <div>
                <v-icon v-if="iconProps.color != ''" v-bind="iconProps" size="x-small" />
                {{ title }}
            </div>
            <span v-if="closable" @click="emitClose()" class="cursor-pointer">
                <v-icon icon="$close" size="x-small" />
            </span>
        </v-card-title>
        <v-card-text class="!text-22px !py-8">
            {{ message }}
        </v-card-text>
        <v-card-actions v-if="buttons && buttons.length > 0" class="flex flex-row justify-center !pb-4">
            <template v-for="button in buttons">
                <v-btn
                    :class="button.classes"
                    :color="button.textColor"
                    @click="button.onClick"
                    :text="button.label"
                    :append-icon="button.appendIcon" />
            </template>
        </v-card-actions>
    </v-card>
</template>
<script setup lang="ts">
import { ToastButton, ToastVariant } from '@/models/toaster/toast'
import { Ref, ref } from 'vue'
import { ComputedRef, computed } from 'vue'

interface Props {
    title: string
    message: string
    variant: ToastVariant
    labels: string[]
    buttons: ToastButton[]
    closable: boolean
    onCloseCallback?: () => void
}

const emit = defineEmits(['close'])

const props = defineProps<Props>()
const icons = {
    info: {
        icon: '$informationSlabCircleOutline',
        color: '#3b82f6',
    },
    success: {
        icon: '$checkCircleOutline',
        color: '#15803d',
    },
    warning: {
        icon: '$alertOutline',
        color: '#E84838',
    },
    error: {
        icon: '$informationSlabCircleOutline',
        color: '#E84838',
    },
    default: {
        icon: '',
        color: '',
    },
}

interface IIconProps {
    icon: string
    color: string
}

const iconProps: ComputedRef<IIconProps> = computed(() => {
    return icons[props.variant] || undefined
})

function emitClose() {
    if (props.onCloseCallback) {
        props.onCloseCallback()
    }
    emit('close')
}
</script>
