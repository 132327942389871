<template>
    <inventory-vehicle-card :horizontal-view="isHorizontalView" @click="openVinModal">
        <template #text>
            <div>
                <div
                    :class="
                        isHorizontalView
                            ? 'xl:py-2 text-22px leading-7 py-0 font-normal'
                            : 'pt-4 pb-6 text-22px flex justify-center items-center'
                    ">
                    <div
                        data-testid="add-car-card"
                        :class="{ 'max-w-[204px] text-center ': !isHorizontalView }"
                        class="whitespace-normal line-clamp-1">
                        Add a {{ vehicleType }}
                    </div>
                </div>
                <!-- card body -->
                <p v-show="isHorizontalView" class="text-xs line-clamp-3 font-medium">
                    This vehicle will stay in your Pinpoint inventory for 24 hours. To keep this vehicle in your
                    Pinpoint inventory long-term, please add it to your dealership's inventory management system.
                </p>
                <div v-show="!isHorizontalView" class="text-[12px]">This inventory item will be temporary.</div>
                <div v-show="!isHorizontalView" class="text-[12px] pt-2 w-full">
                    <v-tooltip
                        text="This vehicle will stay in your Pinpoint inventory for 24 hours. To keep this vehicle in your Pinpoint inventory long-term, please add it to your dealership's inventory management system."
                        class="!text-xs !font-inter"
                        location="top"
                        max-width="300">
                        <template #activator="{ props }">
                            See why
                            <v-icon
                                v-bind="props"
                                color="text-secondary"
                                class="ml-1.5"
                                icon="$informationSlabCircleOutline"
                                size="12" />
                        </template>
                    </v-tooltip>
                </div>
            </div>
        </template>
    </inventory-vehicle-card>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, markRaw } from 'vue'
import { useInventoryStore } from '@/stores/inventory'
import InventoryVehicleCard from '@/components/cards/InventoryVehicleCard.vue'
import VinModal from '@/components/modals/VinModal.vue'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'

interface Props {
    variant?: string
    isHorizontalView?: boolean
}

withDefaults(defineProps<Props>(), {
    isHorizontalView: false,
})

const inventoryStore = useInventoryStore()
const modal = useGlobalModal()

const vehicleType: ComputedRef<string> = computed(() => {
    return inventoryStore.filters.used ? 'Pre-owned Vehicle to Inventory' : 'New Model Vehicle to Inventory'
})

function openVinModal(): void {
    modal.openModal({
        title: inventoryStore.filters.used ? 'Add used vehicle' : 'Add new vehicle',
        dialogProps: {
            maxWidth: '500',
            persistent: true,
        },
        component: {
            name: markRaw(VinModal),
        },
    })
}
</script>
