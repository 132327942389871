<template>
    <v-dialog :attach="isTesting" v-model="open" width="874">
        <v-card>
            <v-container>
                <v-form v-model="isFormValid">
                    <v-row>
                        <v-col cols="8">
                            <v-text-field
                                data-testid="magic-link-email"
                                label="Enter your authorized dealership email here"
                                v-model="email"
                                :rules="[validators.required, validators.validEmail]"
                                type="email" />
                        </v-col>
                        <v-col cols="4">
                            <v-btn
                                data-testid="magic-link-button"
                                @click="getMagicLink"
                                :height="56"
                                color="blue"
                                class="px-10 w-full !tracking-normal"
                                :disabled="!isFormValid"
                                :loading="loading"
                                >GET LINK</v-btn
                            >
                        </v-col>
                    </v-row>
                    <v-row :no-gutters="true">
                        <v-col cols="12">
                            <p class="text-sm font-medium w-full text-center">
                                In order to access this feature, you must enter your authorized email and follow the
                                link sent to your email
                            </p>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { validators } from '@/helpers/validators'
import { useApplicationStore } from '@/stores/application'
import { ComputedRef, Ref, computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

withDefaults(
    defineProps<{
        isTesting?: boolean
    }>(),
    {
        isTesting: false,
    },
)
const applicationStore = useApplicationStore()
const route = useRoute()

const open = defineModel({
    type: Boolean,
})

const email: Ref<string> = ref('')
const isFormValid = ref(false)
const loading: Ref<boolean> = ref(false)

const cDealerId: ComputedRef<number> = computed(() => Number(route.params.dealer_id))
const callbackURL: ComputedRef<string> = computed(() => route.query?.callback as string)

function getMagicLink(): void {
    loading.value = true
    applicationStore
        .createDealerAuthenticationMagicLink(email.value, cDealerId.value, callbackURL.value)
        .finally(() => {
            open.value = false
            loading.value = false
        })
}

watch(
    () => open.value,
    (value) => {
        if (!value) {
            email.value = ''
            isFormValid.value = false
        }
    },
)
</script>
