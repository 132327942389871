<template>
    <div class="rounded-lg w-full">
        <div class="font-medium w-full pb-4">Booked Stats</div>
        <div class="flex flex-row gap-8 text-sm text-slate-900">
            <booked-status-card
                title="Booked"
                tool-tip-message="Time that this application was booked"
                :booked-date="bookedDatetime" />
            <status-card
                title="Time To Approval"
                tool-tip-message="Time from when this application was submitted to when it was approved"
                :value="String(computedTimeInApproval.value)"
                :unit="computedTimeInApproval.key" />
            <status-card
                title="Time In Funding"
                tool-tip-message="Time that this application took to be reviewed by our funding analysts"
                :value="String(computedTimeInFunding.value)"
                unit="Minutes" />
            <status-card
                v-if="showFundingStats"
                title="Time To Deposit"
                tool-tip-message="Time that this application took from the time it was approved to when you had your money deposited"
                :value="String(timeInDepositMinutes)"
                unit="Minutes" />
        </div>
    </div>
</template>

<script setup lang="ts">
import StatusCard from '@/components/partials/StatusCard.vue'
import BookedStatusCard from '@/components/partials/BookedStatusCard.vue'
import { getTimeInStatusByDate, getTimeInStatusByMilliseconds } from '@/helpers/formatters'
import { computed } from 'vue'

interface Props {
    timeToApprovalMinutes?: Date
    timeInFundingMinutes: number
    timeInDepositMinutes: number
    bookedDatetime?: Date
}

const props = defineProps<Props>()

const computedTimeInApproval = computed(() => getTimeInStatusByDate(props.timeToApprovalMinutes))
const computedTimeInFunding = computed(() => getTimeInStatusByMilliseconds(props.timeInFundingMinutes))
const showFundingStats = computed(() => import.meta.env.VITE_FEATURE_FUNDINGSTATS === 'true')
</script>
