export interface DealStructure {
    date: string
    borrower: BorrowerDeal
    co_borrower?: BorrowerDeal
    deal: Deal
    fees?: {
        acq_fee: number
        additional_acq_fee: number
        dealer_participation: number
        highest_rate_total_fee: number
        open_bk: number
        processing: number
        spread_fee: number
        technological_convenience_fee: number
    }
    models?: {
        pti?: number
        dti_with_payment?: number
        dti_without_payment?: number
        ltv?: number
        ltv_max?: number
        payment_max?: number
    }
    vehicle: VehicleDeal
}

export interface VehicleDeal {
    odometer?: number
    value?: number
    year?: number
}

export interface Deal {
    amount_financed: number
    state?: string
    amount_financed_max: number
    term: number
    buy_rate: number
    contract_rate: number
    payment: number
    max_contract_rate: number
    disbursement?: number
    down_payment?: number
}

export interface BorrowerDeal {
    income?: number
    debt?: number
    bk_chapter?: number
    bk_disposition?: string
    income_discretionary?: number
}

export enum CalculationType {
    'AmountFinanced' = 0,
    'DealStructure' = 1,
}
