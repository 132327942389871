<template>
    <div class="px-2 pt-3 bg-white">
        <div class="pl-2 pt-1 pb-2 text-xs font-medium leading-16 text-vuetify-text-secondary">
            App# {{ applicationStore.account?.id }}
        </div>
        <div class="pl-2 text-vuetify-text-normal">
            <div id="primaryBorrower" class="text-22px leading-7">
                {{ borrower?.first_name + ' ' + borrower?.last_name }}
            </div>
            <div v-if="coborrower" id="coBorrower" class="text-base">
                {{ coborrower?.first_name + ' ' + coborrower?.last_name }}
            </div>
            <div class="border-b border-slate-700 pt-1"></div>
        </div>
        <div class="pt-3 pb-2">
            <div class="flex flex-row gap-x-2 pb-2">
                <div class="bg-vuetify-teal-lighten-5 text-vuetify-teal-darken-2 p-1 pt-3 pl-2 rounded-lg w-full">
                    <p class="text-[12px] font-medium">{{ applicationStore.application_status }}</p>
                    <p class="text-[16px] font-semibold">
                        {{ getExpirationDate(applicationStore.account?.created_at ?? '') }}
                    </p>
                </div>
                <div class="bg-vuetify-bg-surface-200 text-vuetify-text-secondary p-1 pt-3 pl-2 rounded-lg w-full">
                    <p class="text-[12px] font-meduim">Max Payment</p>
                    <p data-test-max-payment-id class="text-[16px] font-semibold">
                        ${{ roundDownWithTwoDecimals(applicationStore.customer_max_payment) }}
                    </p>
                </div>
            </div>
            <v-row no-gutters align="baseline">
                <v-col>
                    <currency-input
                        v-if="application"
                        data-testid="input_down_payment"
                        :vuetify-props="{
                            label: 'Down Payment',
                            clearable: true,
                            hideDetails: true,
                            placeholder: 'Optional',
                            prefix: '$',
                            bgColor: 'white',
                            id: 'input-down-payment',
                        }"
                        :options="{
                            currency: 'USD',
                            currencyDisplay: CurrencyDisplay.hidden,
                            precision: 2,
                        }"
                        class="pt-2"
                        v-model="application.terms.cash_down"
                        @blur="onDownPaymentChange"
                        @input="onDownPaymentInactivity" />
                </v-col>
                <v-col v-if="!showAPRDisclaimer" cols="5" class="pl-2">
                    <apr-menu
                        :current-rate="inventoryStore.filters?.apr"
                        :has-spread-fee="dealerConfigs.hasSpreadFee"
                        :min="terms?.rate_capped_buy"
                        :max="applicationStore.maxAPR"
                        @rate-applied="handleConfirm" />
                </v-col>
            </v-row>
            <v-row
                v-if="showAPRDisclaimer"
                no-gutters
                align="baseline"
                class="gap-1 mt-1"
                data-testid="inventory-apr-disclaimer">
                <v-col cols="auto">
                    <v-tooltip
                        text="Your state usury laws require the the vehicle information before we can determine your APR">
                        <template #activator="{ props }">
                            <v-icon
                                v-bind="props"
                                color="text-secondary"
                                icon="$informationSlabCircleOutline"
                                size="16" />
                        </template>
                    </v-tooltip>
                </v-col>
                <v-col class="text-xs">Due to your state usury laws, you must select a vehicle to configure APR </v-col>
            </v-row>
        </div>
    </div>
</template>
<script setup lang="ts">
import { inject, ref, Ref } from 'vue'
import { useApplicationStore } from '@/stores/application'
import { storeToRefs } from 'pinia'
import eventBus, { BUS_EVENTS } from '@/eventBus'
import { useInventoryStore } from '@/stores/inventory'
import { roundDownWithTwoDecimals } from '@/helpers/formatters'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import { useDealerStore } from '@/stores/dealer/dealer'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { CurrencyDisplay } from 'vue-currency-input'
import AprMenu from '@/components/partials/aprMenu/AprMenu.vue'

// stores and refs
const inventoryStore = useInventoryStore()
const applicationStore = useApplicationStore()

const { borrower, coborrower, application, terms, showAPRDisclaimer, maxAPR, account } = storeToRefs(applicationStore)
const { dealerConfigs } = storeToRefs(useDealerStore())

// apr related
const isOpen: Ref<boolean> = ref(false)

eventBus.$on(BUS_EVENTS.CLOSE_POPOUTS, () => {
    closePopoutIfOpen(isOpen)
})

function handleConfirm(confirmedRate: number): void {
    inventoryStore.updateApr(confirmedRate, account.value?.id)
    if (terms.value?.rate_contract) {
        terms.value.rate_contract = confirmedRate
    }
}

function closePopoutIfOpen(popout: Ref<boolean>): void {
    if (popout.value) popout.value = !popout.value
}

// Down Payment related
const onDownPaymentInactivity: _.DebouncedFunc<() => void> = debounce(onDownPaymentChange, 1000)

function onDownPaymentChange(): void {
    inventoryStore.updateDownPayment(Number(terms.value?.cash_down || 0), account.value?.id)
}

// expiration date
function getExpirationDate(created_at: string): string {
    if (dayjs(created_at).isValid()) {
        return 'Exp ' + dayjs(created_at).startOf('day').add(31, 'days').format('M/DD')
    }
    return ''
}
</script>
<script lang="ts">
export default {
    name: 'ApplicantDetails',
}
</script>
