import dayjs from 'dayjs'
import { usePolicyStore } from '@/stores/policy'
import { storeToRefs } from 'pinia'
import { MAX_MILEAGE_FOR_CURRENT_YEAR_CAR } from '@/constants/amounts'

export const isNew = (year: number | string, mileage: number | string, accountCreationDate: string | Date): boolean => {
    if (Number(mileage) > 5000) return false

    const dateObj = dayjs(accountCreationDate)
    const thisYear = dateObj.get('year')
    const thisMonth = dateObj.get('month')

    if (Number(year) > thisYear - 1) return true
    if (thisMonth < 3 && Number(year) === thisYear - 1) return true

    return false
}

export const isMileageValid = (mileage: number | string | undefined): boolean => {
    const { policy } = storeToRefs(usePolicyStore())
    return Number(mileage) <= policy.value.MAX_MILEAGE_ALLOWED
}

export const isConfirmMileageValid = (
    mileage: number | string | undefined,
    confirmMileage: number | string | undefined,
): { isValid: boolean; message: string } => {
    const { policy } = storeToRefs(usePolicyStore())

    const isConfirmMileageGreaterThanMaxAllowed: boolean = Number(confirmMileage) > policy.value.MAX_MILEAGE_ALLOWED
    const isConfirmMileageEqualToMileage: boolean = Number(mileage) === Number(confirmMileage)
    const isConfirmMileageIsEmpty: boolean = confirmMileage === undefined || confirmMileage === ''

    if (isConfirmMileageIsEmpty) {
        return { isValid: true, message: '' }
    }

    if (isConfirmMileageGreaterThanMaxAllowed) {
        return {
            isValid: false,
            message: `Please enter a mileage that is ${Intl.NumberFormat().format(
                policy.value.MAX_MILEAGE_ALLOWED,
            )} or less.`,
        }
    }

    if (!isConfirmMileageEqualToMileage) {
        return { isValid: false, message: 'Mileage and confirm mileage must match' }
    }

    return { isValid: true, message: '' }
}

export const isVINValid = (vin: string): boolean => {
    if (!vin) return false
    return vin.trim().length === 17
}

export function invalidMilageForCurrentYear(mileage: number, year: number) : boolean {                     
    if (!mileage) return true
    if (!year) return true    
    if (year === new Date().getFullYear()) {
        return mileage > MAX_MILEAGE_FOR_CURRENT_YEAR_CAR 
    }
    return false
} 
