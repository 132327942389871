import { Ref, ref } from 'vue'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { TVehicleState } from '@/components/cards/VehicleForm.vue'
import { useInventoryStore } from '@/stores/inventory.ts'
import { useApplicationStore } from '@/stores/application.ts'

export type TEditMileageResponse = { vehicle: Vehicle | undefined; milesDifference: number }

type TUsedEditVehicleMileageReturn = {
    editMileage: (editedVehicle: TVehicleState, currentVehicle?: Vehicle) => Promise<TEditMileageResponse>
    loading: Ref<boolean>
}

export function useEditVehicleMileage(): TUsedEditVehicleMileageReturn {
    const applicationStore = useApplicationStore()
    const inventoryStore = useInventoryStore()
    const loading: Ref<boolean> = ref(false)

    async function editMileage(editedVehicle: TVehicleState, currentVehicle?: Vehicle): Promise<TEditMileageResponse> {
        const milesDiff: number = Number(editedVehicle.mileage) - Number(currentVehicle?.miles)
        if (!milesDiff) {
            return { vehicle: currentVehicle, milesDifference: milesDiff }
        }

        loading.value = true

        const updatedVehicle = await inventoryStore.updateVehicle(
            {
                miles: Number(editedVehicle.mileage),
                zipcode: inventoryStore.dealerZipCode ?? '',
                year: Number(editedVehicle.year),
                vin: currentVehicle?.vin || '',
            },
            currentVehicle?.vin || '',
            applicationStore.account?.id,
        )
        loading.value = false

        return { vehicle: updatedVehicle, milesDifference: milesDiff }
    }

    return { editMileage, loading }
}
