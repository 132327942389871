<template>
    <div v-for="(positionedToasts, position) in toastLocations" :[position]="true">
        <v-overlay v-model="open" class="toaster" :[position]="true" :persistent="isPersistent">
            <toast
                v-for="toast in positionedToasts"
                :id="toast.key"
                :message="toast.message"
                :variant="toast.type"
                :title="toast.title || ''"
                :labels="[]"
                :buttons="toast?.buttons || []"
                :closable="toast?.closable ?? true"
                :on-close-callback="toast?.onCloseCallback"
                @close="eatToastTemplate(toast.key || '')" />
        </v-overlay>
    </div>
</template>
<script setup lang="ts">
import { Ref, inject, computed, ComputedRef, ref } from 'vue'
import Toast from '../Toast.vue'
import { Toast as ToastType } from './shared'
import { locationOptions } from './shared'
import { eatToast } from './shared'

defineOptions({
    name: 'Toaster',
})

let toasts: Ref<ToastType[]> = inject('toastsLocal') as Ref<ToastType[]>
type locationsObject = {
    [key in locationOptions]: ToastType[]
}
const toastLocations: ComputedRef<locationsObject> = computed(() => {
    return toasts.value.reduce((acc, toast): any => {
        if (toast.location && !acc[toast.location]) {
            acc[toast.location] = []
        }
        if (toast.location) {
            acc[toast.location].push(toast)
        }
        return acc
    }, {} as locationsObject)
})

const open: Ref<boolean> = ref(true)
const isPersistent: ComputedRef<boolean> = computed(() => {
    return toasts.value.some((toast) => toast.closable === false || toast?.persistent === true)
})
function eatToastTemplate(key: string): void {
    eatToast(key, toasts)
}
</script>

<style lang="scss" src="../../../styles/toaster.scss" />
