<template>
    <div class="flex flex-col bg-[url('@/assets/global-background.png')] bg-cover overflow-auto h-full text-white">
        <img alt="arivo-logo" class="top-0 left-0 mt-8 ml-8 w-[135px] h-[82px]" src="@/assets/arivo-logo-white.svg" />

        <div class="flex flex-col self-center space-y-10 mx-4 sm:mx-auto lg:max-w-4xl md:max-w-2xl sm:max-w-xl">
            <div cy-id="welcome" class="flex justify-center pt-20 mx-4 sm:mx-0">
                <img alt="" src="@/assets/pinpoint-logo.svg" />
            </div>

            <div v-if="alert" cy-id="alert" class="mx-auto w-[752px] bg-white text-black">
                <info :index="alert" @close-info-box="closeInfoBox">Default Info Text</info>
            </div>
            <div v-else-if="isRootPath">
                <div class="mx-auto w-[46.5rem] h-[10.8125rem] bg-white text-black">
                    <info cy-id="not-authorized-info-box" index="notAuthorized" />
                </div>
            </div>

            <div v-else-if="needEmail" class="bg-white/[0.9] rounded-lg border-white border-[3px]">
                <div class="px-6 pt-6 lg:w-[874px]">
                    <v-form v-model="formValid" @submit.prevent>
                        <v-row no-gutters>
                            <v-col class="flex flex-col sm:flex-row gap-x-12 w-full sm:items-start items-center">
                                <v-text-field
                                    id="get-link-email-input"
                                    v-model="emailAddress"
                                    clearable
                                    bg-color="white"
                                    label="Enter your authorized dealership email here"
                                    class="w-full"
                                    type="email"
                                    :rules="emailRules"
                                    placeholder="user@domain.com"
                                    @keydown.space.prevent
                                    @click:clear="formValid = false" />
                                <v-btn
                                    id="get-link-button"
                                    variant="flat"
                                    text="GET LINK"
                                    size="x-large"
                                    width="208"
                                    :disabled="!formValid"
                                    class="!tracking-normal text-shades-black"
                                    :class="{ '!opacity-30': !formValid }"
                                    @click="onButtonClick">
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                <p class="text-sm text-slate-900 text-center font-medium whitespace-pre-wrap px-6 pt-6 pb-4">
                    For assistance with Pinpoint or to consult with our experts about enrolling in our program,
                    <a class="underline font-semibold" href="https://arivo.com" target="_blank">click here</a> or call
                    {{ number[10] }}
                </p>
            </div>
            <div
                v-else
                class="box-border bg-white/[0.9] p-6 rounded-lg border-white border-[3px] text-black h-[110px] flex justify-center">
                <div id="link-sent">
                    <p class="py-0">We've sent your access link to {{ emailAddress }}.</p>
                    <p class="py-3 relative">
                        Didn't receive your link?
                        <a id="resend-link" class="text-[#009F97]" href="" @click.prevent="callBackEnd(true)"
                            >Resend
                            <v-snackbar
                                v-model="showResentSnackbar"
                                min-width="0"
                                contained
                                location="top"
                                color="success"
                                class="text-center w-full h-full mt-4 mr-8"
                                variant="text"
                                timeout="1000"
                                text="Resent!" /> </a
                        >, or
                        <a id="toggle-email-link" class="text-[#009F97]" href="" @click.prevent="toggleEmailEntry"
                            >enter a different email</a
                        >.
                    </p>
                </div>
            </div>
        </div>

        <div class="flex-auto" />
        <div class="self-end my-32 w-full">
            <div
                class="grid grid-cols-1 md:grid-cols-3 gap-16 lg:gap-32 2xl:gap-64 mx-16 lg:mx-32 2xl:mx-64 content-end">
                <div>
                    <div class="text-[28px] leading-9 mb-4">Inventory</div>
                    <div class="text-base font-medium">
                        Use this tool to search, sort, and manage your dealership’s inventory. You will be able to
                        quickly find your customers cars that match their needs, preferences, and individual budgets.
                    </div>
                </div>
                <div>
                    <div class="text-[28px] leading-9 mb-4">Loan Structure</div>
                    <div class="text-base font-medium">
                        Our loan structure tool enables you to tailor your customer’s loan to Arivo’s program
                        guidelines, and find the optimal structure that fits their budget. All of this, while giving you
                        the ability to optimize your profit on every loan.
                    </div>
                </div>
                <div>
                    <div class="text-[28px] leading-9 mb-4">Dashboard</div>
                    <div class="text-base font-medium">
                        Your dealership dashboard will help you keep track of all of your current approvals, outstanding
                        issues, and give you a real time snapshot of your dealership’s performance. Join us for the
                        future of auto lending!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, ComputedRef, Ref, ref } from 'vue'
import { useRoute } from 'vue-router'
import Info from './Info.vue'
import { useApplicationStore } from '@/stores/application'
import number from '@/constants/phoneNumbers'

// initialization
// store
const route = useRoute()
// alert
let closeBox: Ref<boolean> = ref<boolean>(false)

const alert: ComputedRef<string> = computed(() => {
    return !closeBox.value ? <string>route?.params?.alert : ''
})

const isRootPath: ComputedRef<boolean> = computed(() => {
    return route.path === '/'
})

// e-mail related
const formValid = ref(false)
const showResentSnackbar = ref(false)
const needEmail: Ref<boolean> = ref(true)
const emailAddress: Ref<string> = ref('')
const emailRules = [
    (value: string) => {
        if (value) return true

        return 'E-mail is requred.'
    },
    (value: string) => {
        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
            return true
        }
        return 'E-mail must be valid.'
    },
]

const dealerID: ComputedRef<number> = computed(() => Number(route.params.dealer_id as string))
const callbackURL: ComputedRef<string> = computed(() => route.query?.callback as string)

// event handlers
async function onButtonClick(event: Event) {
    if (formValid.value) {
        callBackEnd()
        toggleEmailEntry()
    }
}

function toggleEmailEntry(): void {
    needEmail.value = !needEmail.value
    if (needEmail.value) emailAddress.value = ''
}

async function callBackEnd(resend: boolean = false): Promise<void> {
    if (!emailAddress.value || !dealerID.value) {
        return
    }
    if (resend) {
        showResentSnackbar.value = true
    }
    await useApplicationStore().createDealerAuthenticationMagicLink(
        emailAddress.value,
        dealerID.value,
        callbackURL.value,
    )
}

function closeInfoBox(event: Event): void {
    closeBox.value = true
}
</script>
