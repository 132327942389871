import pinpointClient from '@/clients/pinpointClient'
import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { Ref, ref, watchEffect } from 'vue'

interface IPolicy {
    MAX_MILEAGE_ALLOWED: number
    MAX_TERM_MILEAGE_CONSTRAINT: number
    MAX_TERM_OVER_MILEAGE_CONSTRAINT: number
    MAX_TERM_UNDER_MILEAGE_CONSTRAINT: number
}

interface GetPolicesResponse {
    maxMileage: number
    maxTerms: {
        maxTermMileageConstraint: number
        maxTermOverMileageConstraint: number
        maxMileageUnderTermConstraint: number
    }
}

export const usePolicyStore = defineStore('policy', () => {
    const policy: Ref<IPolicy> = ref({
        MAX_MILEAGE_ALLOWED: 125000,
        MAX_TERM_MILEAGE_CONSTRAINT: 100000,
        MAX_TERM_OVER_MILEAGE_CONSTRAINT: 54,
        MAX_TERM_UNDER_MILEAGE_CONSTRAINT: 72,
    })

    async function getPolicies(date: Date | null = new Date()): Promise<void> {
        let queryParams = {}

        if (date) {
            queryParams = {
                date: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
            }
        }

        const response = await pinpointClient.get<GetPolicesResponse>('/policies', { params: queryParams })
        if (response?.data) {
            policy.value.MAX_MILEAGE_ALLOWED = response?.data?.maxMileage
            policy.value.MAX_TERM_MILEAGE_CONSTRAINT = response?.data?.maxTerms.maxTermMileageConstraint
            policy.value.MAX_TERM_OVER_MILEAGE_CONSTRAINT = response?.data?.maxTerms.maxTermOverMileageConstraint
            policy.value.MAX_TERM_UNDER_MILEAGE_CONSTRAINT = response?.data?.maxTerms.maxMileageUnderTermConstraint
        }
    }

    function getMaxTermAllowedForMileage(mileage: number): number {
        if (mileage <= policy.value.MAX_TERM_MILEAGE_CONSTRAINT) {
            return policy.value.MAX_TERM_UNDER_MILEAGE_CONSTRAINT
        } else {
            return policy.value.MAX_TERM_OVER_MILEAGE_CONSTRAINT
        }
    }

    return {
        policy: <Readonly<IPolicy>>policy.value,
        getPolicies,
        getMaxTermAllowedForMileage,
    }
})
