<template>
    <div class="flex h-full w-full flex-col bg-white p-2">
        <div class="flex flex-row justify-between pt-2">
            <span class="mb-3 text-left text-sm text-gray-900">Filters</span>
            <v-btn
                v-if="hasSelectedFilter"
                append-icon="$windowClose"
                data-testid="dt-clear-all"
                text="Clear all"
                class="text-none !tracking-normal"
                size="small"
                density="compact"
                @click="removeAllFilters" />
        </div>
        <filter-selector
            cy-id="payment-filter"
            class="payment-filter"
            filter-label="payment"
            filter-key="payment"
            :loading="loading"
            :data="inventoryFilters.payment"
            card-title="Choose Monthly Payment Range"
            @confirm-filter="setFilterParams($event)"
            @remove-filter="removeQueryParam($event)" />
        <filter-selector
            cy-id="price-filter"
            filter-label="list price"
            filter-key="price"
            :loading="loading"
            :data="inventoryFilters.price"
            card-title="Choose List Price Range"
            @confirm-filter="setFilterParams($event)"
            @remove-filter="removeQueryParam($event)" />
        <filter-selector
            cy-id="vehicle-location-filter"
            filter-label="vehicle location"
            filter-key="vehicle_location"
            :loading="loading"
            :data="inventoryFilters.vehicle_location"
            card-title="Choose Vehicle Location(s)"
            @confirm-filter="setFilterParams($event)"
            @remove-filter="removeQueryParam($event)" />
        <filter-selector
            cy-id="body-filter"
            filter-label="body"
            filter-key="body_type"
            :loading="loading"
            :data="inventoryFilters.body_type"
            card-title="Choose Body Type(s)"
            @confirm-filter="setFilterParams($event)"
            @remove-filter="removeQueryParam($event)" />
        <filter-selector
            cy-id="year-filter"
            filter-label="year"
            filter-key="year"
            :loading="loading"
            :data="inventoryFilters.year"
            card-title="Choose Year Range"
            @confirm-filter="setFilterParams($event)"
            @remove-filter="removeQueryParam($event)" />
        <filter-selector
            cy-id="make-filter"
            filter-label="make"
            filter-key="make"
            :loading="loading"
            :data="inventoryFilters.make"
            card-title="Choose Make(s)"
            @confirm-filter="setFilterParams($event)"
            @remove-filter="removeQueryParam($event)" />
        <filter-selector
            cy-id="model-filter"
            filter-label="model"
            filter-key="model"
            :loading="loading"
            :data="inventoryFilters.model"
            card-title="Choose Model(s)"
            @confirm-filter="setFilterParams($event)"
            @remove-filter="removeQueryParam($event)" />
        <filter-selector
            cy-id="drive-train-filter"
            filter-label="drive type"
            filter-key="drive_train"
            :loading="loading"
            :data="inventoryFilters.drive_train"
            card-title="Choose Drive Type(s)"
            @confirm-filter="setFilterParams($event)"
            @remove-filter="removeQueryParam($event)" />
        <filter-selector
            cy-id="miles-filter"
            filter-label="Mileage"
            filter-key="miles"
            :loading="loading"
            :data="inventoryFilters.miles"
            card-title="Choose Max Mileage"
            @confirm-filter="setFilterParams($event)"
            @remove-filter="removeQueryParam($event)" />
    </div>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, nextTick, ref, Ref, watch } from 'vue'
import { useInventoryStore } from '@/stores/inventory'
import { useApplicationStore } from '@/stores/application'
import FilterSelector, { TConfirmEmit, TRemoveEmit } from '@/components/sidebar/FilterSelector.vue'
import { storeToRefs } from 'pinia'
import { keysToFind, TFilterColumn } from '@/models/Dealer/InventoryFilters'

export interface SearchPayload {
    stock?: string
    VIN?: string
}

// initializations
// from store
const applicationStore = useApplicationStore()
const inventoryStore = useInventoryStore()
const { account } = storeToRefs(applicationStore)
const { inventoryFilters, loading, filters } = storeToRefs(inventoryStore)

// VIN
const VIN: Ref<string> = ref(filters.value?.vin || '')

const isVinFocused: Ref<boolean> = ref(false)
const vinFocusClass: ComputedRef<string> = computed(() => {
    return VIN.value || VIN.value.length > 0 || isVinFocused.value ? 'focus' : ''
})

function validateVin(newValue: string): void {
    if (newValue || newValue.length > 0) {
        nextTick(() => {
            VIN.value = newValue.replace(/[^a-zA-Z0-9]/g, '').slice(0, 17)
        })
    }
}

watch(VIN, (newValue) => {
    validateVin(newValue)
})

const onSearchInput = (): void => {
    const search: SearchPayload = {
        stock: stock.value || undefined,
        VIN: VIN.value || undefined,
    }
    isStockFocused.value = false
    isVinFocused.value = false
    onSearch(search)
}

const stock: Ref<string> = ref(filters.value?.stock_number || '')
const isStockFocused: Ref<boolean> = ref(false)
const stockFocusClass: ComputedRef<string> = computed(() => {
    return stock.value || stock.value.length > 0 || isStockFocused.value ? 'focus' : ''
})

const hasSelectedFilter: ComputedRef<boolean> = computed(() => {
    for (let key in filters.value) {
        if (keysToFind.includes(key as keyof TFilterColumn) && filters.value[key] !== undefined) {
            return true
        }
    }
    return false
})
function onSearch(search: SearchPayload): void {
    inventoryStore.updateSearchValues(search, applicationStore.account?.id)
}

async function removeAllFilters(): Promise<void> {
    for (let filter in filters.value) {
        if (keysToFind.includes(filter as keyof TFilterColumn)) {
            filters.value[filter] = undefined
        }
    }
    await inventoryStore.setInventoryFilters(account.value?.id)
}

async function setFilterParams(selectedValues: TConfirmEmit): Promise<void> {
    const { key, type, min, max, multiSelectedValues } = selectedValues
    const newFilters: { [index: string]: any } = {
        slider: { [`${key}_min`]: min, [`${key}_max`]: max },
        'multi-select': { [`${key}`]: multiSelectedValues || undefined },
    }
    if (!newFilters[type]) return
    filters.value = { ...filters.value, ...newFilters[type] }
    inventoryStore.resetPage()
    await inventoryStore.setInventoryFilters(account.value?.id)
}

async function removeQueryParam(filter: TRemoveEmit): Promise<void> {
    const { type, key: filterKey, selectedFilters } = filter
    if (type === 'slider') {
        removeSliderFilters(filterKey)
    } else {
        removeMultiSelectFilters(filterKey, selectedFilters)
    }
    inventoryStore.resetPage()
    await inventoryStore.setInventoryFilters(account.value?.id)
}

function removeSliderFilters(filterKey: string): void {
    for (let key in filters.value) {
        if (key.includes(filterKey)) {
            filters.value[key] = undefined
        }
    }
}

function removeMultiSelectFilters(filterKey: string, selectedFilter: string): void {
    const filter = filters.value[filterKey]
    if (!filter || typeof filter !== 'string') {
        return
    }
    const oldFilters = filter.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/).map((element) => element.replace(/"/g, ''))

    const filterIndex = oldFilters.indexOf(selectedFilter)
    if (filterIndex !== -1) {
        oldFilters.splice(filterIndex, 1)
        if (oldFilters.length) {
            filters.value[filterKey] = oldFilters.join(',')
        } else {
            filters.value[filterKey] = undefined
        }
    }
}
defineExpose({ setFilterParams, removeAllFilters, removeQueryParam, removeSliderFilters, removeMultiSelectFilters })
</script>
