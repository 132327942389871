<template>
    <v-toolbar class="bg-transparent">
        <v-toolbar-items class="w-full max-w-[330px]">
            <v-btn
                width="100%"
                :to="`/dealer/${inventoryStore.id}/approvals`"
                class="text-none !tracking-normal !text-base !font-medium flex justify-start pl-0">
                <v-icon icon="$chevronLeft" size="32" class="mr-0" color="text-secondary" start></v-icon>
                Back to Approval
            </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
            <v-tabs v-model="selectedTab" @update:model-value="updateTab">
                <v-tab
                    v-for="item in tabs"
                    data-testid="sort-header-tabs"
                    class="text-none !tracking-normal flex-1 my-2"
                    :key="item.value"
                    :text="item.title"
                    :value="item.value"></v-tab>
            </v-tabs>
        </v-toolbar-items>
        <v-spacer />
        <v-toolbar-items class="gap-2 py-4">
            <div>
                <v-chip
                    @click="openVinModal"
                    data-testid="add-vehicle-chip"
                    color="primary"
                    variant="outlined"
                    :text="cAddVehicleLabel" />
            </div>
            <div>
                <v-chip
                    data-test-saved-button
                    @click="openFavoritesModal()"
                    variant="outlined"
                    text="Favorites"
                    color="primary"
                    prepend-icon="$heartOutline">
                </v-chip>
            </div>
            <div>
                <v-chip
                    data-test-upload-documents
                    @click="openDocumentUploadModal()"
                    color="primary"
                    variant="outlined"
                    text="Upload Documents"
                    prepend-icon="$cloudUploadOutline">
                </v-chip>
                <base-modal
                    id="document-upload-modal"
                    :open="isOpenDocumentUploadModal"
                    :dialog-props="{
                        attach: true,
                        persistent: true,
                        width: '100%',
                        maxWidth: '1008px',
                    }"
                    title="Document Upload"
                    :loading="false"
                    :custom-loader="documentLoading"
                    :disable-action-btn="false"
                    @close-modal="isOpenDocumentUploadModal = false"
                    @handle-save="">
                    <document-upload-modal
                        testing_id="data-test-modal-upload"
                        :close-modal="
                            () => {
                                isOpenDocumentUploadModal = false
                            }
                        "
                        :account-id="account?.id || 0"
                        :application-id="application?.id || 0"
                        @uploading="documentLoading = $event" />
                </base-modal>
            </div>
        </v-toolbar-items>
    </v-toolbar>
</template>
<script lang="ts">
export default {
    name: 'SortHeader',
}
</script>
<script lang="ts" setup>
import BaseModal from '@/components/modals/BaseModal.vue'
import DocumentUploadModal from '@/components/popables/DocumentUploadModal.vue'
import { useApplicationStore } from '@/stores/application'
import { useInventoryStore } from '@/stores/inventory'
import { storeToRefs } from 'pinia'
import { ComputedRef, Ref, computed, ref, watch, markRaw } from 'vue'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'
import VinModal from '@/components/modals/VinModal.vue'
import FavoritesModal from '@/components/popables/FavoritesModal.vue'

export type Tabs = {
    value: string | number
    title: string
}

const inventoryStore = useInventoryStore()
const { application, account } = storeToRefs(useApplicationStore())
const { filters, inventoryFilters } = storeToRefs(inventoryStore)
const modal = useGlobalModal()
const selectedTab: Ref<number> = ref(filters.value.used ? 0 : 1)

const emit = defineEmits(['update', 'submit'])

const isOpenDocumentUploadModal: Ref<boolean> = ref(false)
const documentLoading: Ref<boolean> = ref(false)
function openDocumentUploadModal(): void {
    isOpenDocumentUploadModal.value = true
}

function openFavoritesModal() {
    modal.openModal({
        title: 'Favorites',
        dialogProps: {
            width: '100%',
            maxWidth: '900',
        },
        component: {
            name: markRaw(FavoritesModal),
        },
    })
}

function openVinModal() {
    modal.openModal({
        title: selectedTab.value ? 'Add new vehicle' : 'Add used vehicle',
        dialogProps: {
            maxWidth: '500',
            persistent: true,
        },
        component: {
            name: markRaw(VinModal),
        },
    })
}

const usedCarsCount: ComputedRef<Number> = computed(() => {
    return inventoryFilters.value.used?.Values[1]?.count ?? 0
})
const newCarsCount: ComputedRef<Number> = computed(() => {
    return inventoryFilters.value.used?.Values[0]?.count ?? 0
})
const tabs: ComputedRef<Tabs[]> = computed(() => {
    return [
        {
            value: 0,
            title: `${usedCarsCount.value} Used`,
        },
        {
            value: 1,
            title: `${newCarsCount.value} New`,
        },
    ]
})

const cAddVehicleLabel: ComputedRef<string> = computed(() =>
    filters.value.used ? 'Add a used vehicle' : 'Add a new vehicle',
)

function updateTab(): void {
    inventoryStore.isFavorited = false
    inventoryStore.updateConditionFilter(account.value?.id)
}
</script>
