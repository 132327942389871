<template>
    <div class="flex flex-row w-full border-slate-900 min-h-[4.4375rem]">
        <div class="max-w-[52px] flex flex-row items-center w-14 min-h-[4.4375rem] px-4">
            <img :src="cIcon" alt="announcement icon" class="w-6 h-6" />
        </div>
        <div class="flex flex-col py-2">
            <h3 data-announcement-headline class="font-inter text-base font-normal text-[#1C1B1F]">{{ headline }}</h3>
            <h4 data-announcement-date class="font-inter text-xs tracking-[0.0115rem] text-[#49454F]">
                {{ dayjs(date).format('MM/DD/YY') }}
            </h4>
            <p data-announcement-message class="font-inter text-xs tracking-[0.0115rem] text-[#49454F]">
                {{ message }}
            </p>
        </div>
    </div>
</template>
<script setup lang="ts">
import operationalIcon from '@/assets/icons/megaphone.svg'
import policyIcon from '@/assets/icons/newspaper.svg'
import productIcon from '@/assets/icons/tools.svg'
import dayjs from 'dayjs'
import { ComputedRef, computed } from 'vue'

type AnnouncementType = 'operational' | 'policy' | 'product'

interface IProps {
    type: AnnouncementType
    headline: string
    message: string
    date: string
}

const props = defineProps<IProps>()
const cIcon: ComputedRef<string> = computed(() => {
    let icon = ''

    if (props.type === 'operational') {
        icon = operationalIcon
    } else if (props.type === 'policy') {
        icon = policyIcon
    } else if (props.type === 'product') {
        icon = productIcon
    }

    return icon
})
</script>
