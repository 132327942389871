import { createRouter, createWebHistory, RouteRecordRaw, RouterOptions } from 'vue-router'

import AccountRouter from '@/router/views/AccountRouter.vue'
import Approvals from './views/Approvals.vue'
import Dashboard from '@/router/views/Dashboard.vue'
import Accounts from '@/router/views/Accounts.vue'
import Global from '@/router/views/Global.vue'
import Inventory from '@/router/views/Inventory.vue'
import NotFound from '@/router/views/NotFound.vue'
import SelectedVehicle from '@/router/views/partials/SelectedVehicle.vue'
import DealerView from '@/router/views/Dealer.vue'
import { useApplicationStore } from '@/stores/application'
import { useInventoryStore } from '@/stores/inventory'
import { Dealer } from '@/models/Dealer/Dealer'
import EmailAuthenticationSession from '@/router/views/EmailAuthenticationSession.vue'
import { LoginCallback } from '@okta/okta-vue'
import { storeToRefs } from 'pinia'
import { datadogRum } from '@datadog/browser-rum'
import { useDealerStore } from '@/stores/dealer/dealer'
import SetupWalkthrough from '@/router/views/SetupWalkthrough.vue'

export const routes: RouteRecordRaw[] = [
    {
        name: 'NotFound',
        path: '/:match(.*)*',
        component: NotFound,
    },
    {
        name: 'EmailAuthentication',
        path: '/dealer/:dealer_id?/email',
        component: Global,
    },
    {
        name: 'Home',
        path: '/',
        component: Global,
    },
    {
        name: 'GlobalError',
        path: '/alert/:alert?',
        component: Global,
    },
    {
        name: 'AccountError',
        path: '/dealer/:dealer_id/alert/:alert?',
        component: Global,
    },
    {
        name: 'Callback',
        path: '/login/callback',
        component: LoginCallback,
        props: () => ({
            inheritAttrs: false,
        }),
    },
    {
        name: 'DealerAuthenticationKeySession',
        path: '/auth/dak/:dak',
        component: AccountRouter,
    },
    {
        name: 'OktaSession',
        path: '/auth/okta/:account_id',
        component: AccountRouter,
    },
    {
        name: 'EmailAuthenticationSession',
        path: '/auth/email',
        component: EmailAuthenticationSession,
    },
    {
        name: 'Account',
        path: '/dealer/:dealer_id/account/:account_id',
        component: AccountRouter,
    },
    {
        name: 'Inventory',
        path: '/dealer/:dealer_id/account/:account_id/inventory',
        component: Inventory,
    },
    {
        name: 'SelectedVehicle',
        path: '/dealer/:dealer_id/account/:account_id/vehicle/:vin',
        component: SelectedVehicle,
        meta: { selectedVehicleFromApi: null },
        beforeEnter: async (to, from, next) => {
            const inventoryStore = useInventoryStore()
            const { inventory } = storeToRefs(inventoryStore)
            const vin = to.params.vin
            const accountId = to.params.account_id
            const dealerId = to.params.dealer_id
            const vehicle = inventory.value.find((vehicle) => vehicle.vin === vin)
            if (!vehicle) {
                to.meta.selectedVehicleFromApi = await inventoryStore.getVehicle(vin as string, Number(accountId))
                if (!to.meta.selectedVehicleFromApi) {
                    return next({
                        name: 'Inventory',
                        params: { dealer_id: dealerId, account_id: accountId },
                    })
                }
            }
            next()
            return
        },
    },
    {
        name: 'Approvals',
        path: '/dealer/:dealer_id/approvals',
        component: Approvals,
    },
    {
        name: 'Dashboard',
        path: '/dealer/:dealer_id/dashboard',
        component: Dashboard,
    },
    {
        name: 'Accounts',
        path: '/dealer/:dealer_id/accounts/:account_id?',
        component: Accounts,
    },
    {
        name: 'Dealer',
        path: '/dealer/:dealer_id/manage',
        component: DealerView,
    },
    {
        name: 'Setup',
        path: '/dealer/:dealer_id/setup',
        component: SetupWalkthrough,
    },
]

const routerOptions: RouterOptions = {
    history: createWebHistory(),
    routes,
}

function setDatadogUser(dealer: Dealer | undefined) {
    if (dealer) {
        datadogRum.setUser({
            id: dealer.id.toString(),
            name: dealer.name,
            state: dealer.state,
        })
    }
}

const router = createRouter(routerOptions)

router.beforeEach(async (to, from, next) => {
    if (to.params.dealer_id == undefined || to.name?.toString() == 'EmailAuthentication') {
        next()
        return
    }
    const appStore = useApplicationStore()

    const { requestDealerId } = storeToRefs(useDealerStore())
    requestDealerId.value = Number(to.params.dealer_id as string)

    setDatadogUser(appStore.dealer)

    const dealRoute = to.name && ['Inventory', 'SelectedVehicle'].includes(to.name?.toString())
    if (!dealRoute) {
        next()
        return
    }

    if (appStore.account) {
        next()
        return
    }

    await appStore.getAccount(parseInt(to.params.account_id as string))

    if (!appStore.account) {
        next({ name: 'GlobalError', params: { alert: 'error' } })
        return
    }

    if (appStore.isApplicationExpired) {
        next({ name: 'GlobalError', params: { alert: 'expired' } })
        return
    }

    setDatadogUser(appStore.dealer)

    if (appStore.account) {
        const inventoryStore = useInventoryStore()
        inventoryStore.id = parseInt(to.params.dealer_id as string)
        next()
        return
    }

    next({ name: 'GlobalError', params: { alert: 'error' } })
})

export default router
