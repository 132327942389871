<template>
    <v-container>
        <v-row :class="`p-6 gap-6 ${isSetupPage && 'px-0'}`">
            <v-col lg="8">
                <v-row class="bg-slate-50 px-6 rounded-md">
                    <v-col>
                        <h1 class="text-xl text-slate-800 font-medium border-b p-4 mb-6">Employee Directory</h1>
                        <v-row class="gap-4" v-if="cContacts.length">
                            <v-col class="border !p-0 rounded m-3">
                                <v-card
                                    v-for="contact in cContacts"
                                    :key="contact.id"
                                    elevation="0"
                                    class="!p-1 !border-b !rounded-none first:!rounded-t last:!rounded-b last:!border-b-0">
                                    <v-card-title class="!text-sm !font-semibold">
                                        <v-row align-content="space-between">
                                            <v-col class="text-slate-800">
                                                <span class="mr-1 text-slate-800">{{ contact.fullName }}</span>
                                                <v-chip color="indigo-darken-3" class="!text-xs" density="compact" label
                                                    >{{ contact.title }}
                                                </v-chip>
                                            </v-col>
                                            <v-col class="flex justify-end gap-6">
                                                <v-btn
                                                    :id="`dealer-contact-edit-button-${contact.id}`"
                                                    :cy-id="`dealer-contact-edit-button-${contact.id}`"
                                                    icon
                                                    color="white"
                                                    density="compact"
                                                    elevation="0"
                                                    @click="onSetDealerToEdit(contact)">
                                                    <v-icon
                                                        size="20"
                                                        color="grey-darken-1"
                                                        icon="$pencilOutline"></v-icon>
                                                </v-btn>
                                                <v-btn
                                                    :id="`dealer-contact-delete-button-${contact.id}`"
                                                    :cy-id="`dealer-contact-delete-button-${contact.id}`"
                                                    icon
                                                    color="white"
                                                    density="compact"
                                                    elevation="0"
                                                    @click="confirmDeletion(contact)">
                                                    <v-icon size="20" color="grey-darken-1" icon="$trashCanOutline">
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row class="px-3 py-1" justify="space-between">
                                            <div class="flex flex-row gap-1">
                                                <span v-if="contact.phone" class="text-xs text-slate-600 !font-normal"
                                                    >Work: {{ contact.phone }}
                                                </span>
                                                <div v-if="contact.mobile" class="w-[1px] border-r"></div>
                                                <span
                                                    v-if="contact.mobile"
                                                    class="text-xs text-slate-600 !font-semibold"
                                                    >Cell: {{ contact.mobile }}</span
                                                >
                                            </div>

                                            <span class="text-xs text-slate-800">{{ contact.email }} </span>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <p v-else class="w-full font-inter text-base text-center">No contact found</p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row class="bg-slate-50 px-6 rounded-md">
                    <v-col>
                        <v-form ref="form" v-model="isFormValid" class="contact-field">
                            <h1 class="text-xl text-slate-800 font-medium border-b p-4 mb-6">
                                {{ isEditing ? 'Edit contact' : 'Add new contact' }}
                            </h1>
                            <v-text-field
                                id="dealer-contact-first-name"
                                cy-id="dealer-contact-first-name"
                                bg-color="white"
                                v-model="createUpdateDealerContact.first_name"
                                label="First name"
                                :rules="[validators.required]"
                                placeholder="First name"
                                outlined></v-text-field>
                            <v-text-field
                                id="dealer-contact-last-name"
                                cy-id="dealer-contact-last-name"
                                bg-color="white"
                                v-model="createUpdateDealerContact.last_name"
                                label="Last name"
                                :rules="[validators.required]"
                                placeholder="Last name"
                                outlined></v-text-field>
                            <v-select
                                id="dealer-contact-title"
                                cy-id="dealer-contact-title"
                                class="dealer-contact-title"
                                bg-color="white"
                                :items="cTitles"
                                v-model="createUpdateDealerContact.dealer_contact_title_id"
                                label="Title"
                                :rules="[validators.required]"
                                required
                                placeholder="Title"
                                outlined></v-select>
                            <v-text-field
                                id="dealer-contact-work-phone"
                                cy-id="dealer-contact-work-phone"
                                bg-color="white"
                                v-model="createUpdateDealerContact.phone"
                                v-maska:[phoneMaskOptions]
                                maxLength="14"
                                :rules="[validators.required, validators.validPhoneNumber('invalid phone number')]"
                                label="Work phone"
                                placeholder="000-000-0000"
                                outlined></v-text-field>
                            <v-text-field
                                id="dealer-contact-cell-phone"
                                cy-id="dealer-contact-cell-phone"
                                bg-color="white"
                                label="Cell phone"
                                :rules="[validators.validPhoneNumber('invalid phone number')]"
                                v-model="createUpdateDealerContact.mobile"
                                maxLength="14"
                                v-maska:[phoneMaskOptions]
                                placeholder="000-000-0000"
                                outlined></v-text-field>
                            <v-text-field
                                id="dealer-contact-email"
                                cy-id="dealer-contact-email"
                                :rules="[validators.validEmail, validators.required]"
                                v-model="createUpdateDealerContact.email"
                                bg-color="white"
                                label="Email"
                                outlined></v-text-field>
                        </v-form>
                        <v-row justify="end" align="end" class="p-4 gap-4">
                            <v-btn color="blue" class="text-none" variant="text" @click="resetDealerToCreateUpdate"
                                >Cancel
                            </v-btn>
                            <v-btn
                                id="dealer-contact-save-button"
                                color="blue"
                                class="text-none"
                                :disabled="!isFormValid"
                                :loading="loading"
                                @click="onSaveButtonClick()"
                                >Save
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
    <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        :close-on-content-click="false">
        {{ snackbar.message }}
        <template v-if="snackbar.showAction">
            <v-btn class="mr-4" density="comfortable" variant="text" flat @click="onSnackBarNo()">No</v-btn>
            <v-btn density="comfortable" :loading="snackbar.loading" @click="onSnackbarYes()">Yes</v-btn>
        </template>
    </v-snackbar>
</template>
<script lang="ts" setup>
import { validators } from '@/helpers/validators'
import { vMaska } from 'maska/vue'
import { computed, ComputedRef, Ref, ref, toRefs } from 'vue'
import { phoneNumber as formatterPhoneNumber } from '@/helpers/formatters'
import { TAllContacts, TContact, TContactTitles } from '@/stores/dealer/dealerTypes'
import pinpointClient from '@/clients/pinpointClient'
import { useRoute } from 'vue-router'

const phoneMaskOptions = {
    mask: '(###) ###-####',
}

interface Props {
    contacts: TAllContacts
    titles: TContactTitles[]
    isSetupPage?: boolean
}

const props = defineProps<Props>()
const { contacts, titles } = toRefs(props)

const form: Ref<HTMLFormElement | null> = ref(null)

const route = useRoute()
const snackbar: Ref<{
    show: boolean
    message: string
    color: string
    loading: boolean
    timeout: number
    showAction: boolean
    valueId?: number
}> = ref({
    show: false,
    message: '',
    color: '',
    loading: false,
    timeout: 3000,
    showAction: false,
})
const dealerContact: TContact = {
    first_name: '',
    last_name: '',
    phone: '',
    mobile: '',
    email: '',
    phone_extension: '',
}
const createUpdateDealerContact: Ref<TContact> = ref({ ...dealerContact })
const isFormValid: Ref<boolean> = ref(false)
const loading: Ref<boolean> = ref(false)
const isEditing: Ref<boolean> = ref(false)
const cTitles: ComputedRef<Array<{ title: string; value: string }>> = computed(() =>
    titles.value.map((contactTitle) => ({
        title: contactTitle.label,
        value: contactTitle.value,
    })),
)

interface IContacts extends TContact {
    fullName: string
    title: string
}
const cContacts: ComputedRef<Array<IContacts>> = computed(() =>
    contacts.value.data.map((contact) => ({
        ...contact,
        fullName: getFullName(contact),
        phone: getPhone(contact?.phone, contact?.phone_extension),
        mobile: getPhone(contact?.mobile, contact?.phone_extension),
        title: getTitle(contact?.dealer_contact_title_id),
    })),
)

function onSetDealerToEdit(contact: TContact): void {
    isEditing.value = true
    createUpdateDealerContact.value = { ...contact }
}

async function onDelete(): Promise<void> {
    const request = pinpointClient.delete(`/dealer/${route.params?.dealer_id}/contacts/${snackbar.value?.valueId}`)

    snackbar.value.loading = true
    request
        .then(() => {
            loading.value = false
            contacts.value.data = contacts.value.data.filter((contact) => contact.id !== snackbar.value?.valueId)
            snackbar.value = {
                show: true,
                message: 'Contact deleted successfully',
                color: 'white',
                loading: false,
                timeout: 3000,
                showAction: false,
            }
        })
        .catch(() => {
            loading.value = false
            snackbar.value = {
                show: true,
                message: 'An error occurred while deleting the contact',
                color: 'white',
                loading: false,
                timeout: 3000,
                showAction: false,
            }
        })
        .finally(() => {
            snackbar.value.loading = false
            resetDealerToCreateUpdate()
        })
}

function confirmDeletion(contact: TContact): void {
    snackbar.value = {
        show: true,
        message: `Are you sure you want to delete ${getFullName(contact)}?`,
        color: 'white',
        loading: false,
        timeout: -1,
        showAction: true,
        valueId: contact.id,
    }
}

async function resetDealerToCreateUpdate(): Promise<void> {
    form.value?.reset()
    isEditing.value = false
    createUpdateDealerContact.value = { ...dealerContact }
}

function resetSnackbar(): void {
    snackbar.value = {
        show: false,
        message: '',
        color: '',
        loading: false,
        timeout: 3000,
        showAction: false,
    }
}

async function onSaveButtonClick(): Promise<void> {
    if (createUpdateDealerContact.value) {
        loading.value = true
        const request = pinpointClient.post(`/dealer/${route.params.dealer_id}/contacts/createOrUpdate`, {
            ...createUpdateDealerContact.value,
            phone: createUpdateDealerContact.value.phone?.replace(/\D/g, '') ?? '',
            mobile: createUpdateDealerContact.value.mobile?.replace(/\D/g, '') ?? '',
        })
        request
            .then(({ data }) => {
                if (createUpdateDealerContact.value.id) {
                    contacts.value.data = contacts.value.data.map((contact) => {
                        if (contact.id === createUpdateDealerContact.value.id) {
                            return data
                        }
                        return contact
                    })
                } else {
                    contacts.value.data.push(data)
                }
                snackbar.value = {
                    show: true,
                    message: 'Contact saved successfully',
                    color: 'white',
                    loading: false,
                    timeout: 3000,
                    showAction: false,
                }
            })
            .catch((error) => {
                if (error.response?.status === 409) {
                    snackbar.value = {
                        show: true,
                        message: 'The email address provided has already been used.',
                        color: 'red',
                        loading: false,
                        timeout: 3000,
                        showAction: false,
                    }
                } else {
                    snackbar.value = {
                        show: true,
                        message: 'An error occurred while saving the contact',
                        color: 'red',
                        loading: false,
                        timeout: 3000,
                        showAction: false,
                    }
                }
            })
            .finally(() => {
                loading.value = false
                resetDealerToCreateUpdate()
            })
    }
}

function getFullName(contact: TContact): string {
    return `${contact.first_name} ${contact.last_name ?? ''}`
}

function getPhone(phone?: string, extension?: string): string {
    if (phone) {
        const phoneNumber = `${phone} ${extension ? `ext. ${extension}` : ''}`
        return formatterPhoneNumber(phoneNumber.trim())
    }
    return ''
}

function getTitle(titleId?: string): string {
    let title = ''
    if (titleId) {
        title = props.titles.find((title) => title.value == titleId)?.label ?? ''
    }
    return title
}

function onSnackbarYes(): void {
    onDelete()
}

function onSnackBarNo(): void {
    resetSnackbar()
}
</script>

<style>
.contact-field input {
    color: #1e293b !important;
}
</style>
