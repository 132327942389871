//@ts-ignore
import { Vehicle } from '@/models/Vehicle/Vehicle'

export interface IPagination {
    page: number
    pageCount: number
    pageSize: number
    totalNew: number
    totalRecords: number
    totalUsed: number
}

export interface InventoryFiltersResponse {
    inventory: Vehicle[]
    pagination: IPagination
}

export type IFilterQueryParams = {
    [key: string]: string | number | boolean | undefined
    make?: string
    model?: string
    drive_train?: string
    body_type?: string
    price_min?: number
    price_max?: number
    vehicle_location?: string
    miles_min?: number
    miles_max?: number
    payment_min?: number
    payment_max?: number
    used: boolean
    vin?: string
    stock_number?: string
    year_min?: number
    year_max?: number
    apr?: number
    page: number
    pageSize: number
    pageCount: number
    down_payment?: number
    sort_by: string
    search?: string
}

export type TFilterColumn = Pick<
    IFilterQueryParams,
    | 'price_min'
    | 'price_max'
    | 'payment_min'
    | 'payment_max'
    | 'miles_min'
    | 'miles_max'
    | 'year_max'
    | 'year_min'
    | 'vehicle_location'
    | 'body_type'
    | 'make'
    | 'model'
    | 'drive_train'
>

export const keysToFind: Array<keyof TFilterColumn> = [
    'price_min',
    'price_max',
    'payment_min',
    'payment_max',
    'miles_min',
    'miles_max',
    'year_max',
    'year_min',
    'vehicle_location',
    'body_type',
    'make',
    'model',
    'drive_train',
]

export interface TSliderValues {
    Values: number[]
    selectedMin: number
    selectedMax: number
}

export interface TMultiSelectValues {
    count: number
    name: string
    selected?: boolean
}

export interface TSlider {
    Type: string
    Values: TSliderValues
}

export interface TMultiSelect {
    Type: string
    Values: TMultiSelectValues[]
}

export interface IFilters {
    body_type: TMultiSelect
    drive_train: TMultiSelect
    vehicle_location: TMultiSelect
    make: TMultiSelect
    miles: TSlider
    model: TMultiSelect
    payment: TSlider
    price: TSlider
    year: TSlider
    used: {
        Type: 'single-select'
        Values: TMultiSelectValues[]
    }
}
export type IFilterKeys = keyof IFilters

export function isSlider(data?: TSlider | TMultiSelect): data is TSlider {
    return (data as TSlider).Type === 'slider'
}

interface ISortBy {
    [index: string]: string
    estimatedGross: string
    paymentLowToHigh: string
    paymentHighToLow: string
    amountFinancedLowToHigh: string
    amountFinancedHighToLow: string
    priceLowToHigh: string
    priceHighToLow: string
    inventoryOldToNew: string
    inventoryNewToOld: string
    relevance: string
}

export const inventorySortByMap: ISortBy = {
    estimatedGross: '-profitability',
    paymentLowToHigh: 'payment',
    paymentHighToLow: '-payment',
    amountFinancedLowToHigh: 'financed',
    amountFinancedHighToLow: '-financed',
    priceLowToHigh: 'price',
    priceHighToLow: '-price',
    inventoryOldToNew: '-age',
    inventoryNewToOld: 'age',
    relevance: '-relevance',
}
