<template>
    <v-card class="!bg-vuetify-background !rounded-lg" height="100%">
        <v-card-title class="border-b border-black text-normal !tracking-normal !text-base !p-4 px-6"
            >Loan Structure</v-card-title
        >
        <v-card-text class="!p-2 relative">
            <div
                v-if="loanStructureLoader"
                class="absolute z-10 flex justify-center align-center h-full w-full !bg-vuetify-background !bg-opacity-50">
                <v-progress-circular indeterminate color="primary" />
            </div>
            <v-form @submit.prevent>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-btn
                                id="loan-structure-maximize-deal"
                                data-testid="loan-structure-maximize-deal"
                                width="100%"
                                color="primary"
                                variant="outlined"
                                class="text-none !tracking-normal button-custom-border !font-inter !font-medium !bg-white !rounded-lg"
                                :disabled="cDisableMaximizeDealButton"
                                @click="maximizeDeal()">
                                <v-icon icon="$trendingUp" size="24" start></v-icon>
                                Maximize this deal
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <p
                                class="text-xs font-inter text-vuetify-text-secondary select-none flex flex-row items-center h-full">
                                Applies the max amount financed to "Amount financed"
                            </p>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <currency-input
                                :class="{ invalid_field: !isAmountFinancedValid }"
                                :vuetify-props="{
                                    hideDetails: 'auto',
                                    label: 'Amount Financed',
                                    variant: 'filled',
                                    clearable: true,
                                    prefix: '$',
                                    bgColor: 'white',
                                    error: !isAmountFinancedValid,
                                    errorMessages: amountFinancedValidationMessage,
                                    disabled: isApplicationReadOnly,
                                    id: 'loan-structure-amount-financed',
                                }"
                                :options="{
                                    currency: 'USD',
                                    currencyDisplay: CurrencyDisplay.hidden,
                                    precision: 2,
                                }"
                                v-model="myDeal.amountFinanced"
                                @input="calculate(CalculationType.DealStructure)" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <currency-input
                                class="pointer-events-none read-only"
                                :class="{ invalid_field: !isAmountFinancedValid }"
                                :vuetify-props="{
                                    hideDetails: 'auto',
                                    label: 'Max Amount Financed',
                                    prefix: '$',
                                    variant: 'filled',
                                    error: !isAmountFinancedValid,
                                    disabled: isApplicationReadOnly,
                                    bgColor: 'transparent',
                                    id: 'loan-structure-max-amount-financed',
                                    readonly: true,
                                }"
                                :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                                v-model="myDeal.maxAmountFinanced" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <apr-menu
                                data-testid="apr_menu_loan_structure"
                                :current-rate="myDeal.apr"
                                :has-spread-fee="hasSpreadFee"
                                :deal="myDeal"
                                :max="myDeal.max_contract_rate"
                                :min="myDeal.buy_rate"
                                :selected-vehicle="selectedVehicle"
                                variant="filled"
                                :disabled="isApplicationReadOnly || isRequiredInputsInvalid"
                                @rate-applied="onAprConfirmClick" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <currency-input
                                class="pointer-events-none read-only"
                                :class="availableAdvanceStyleColor"
                                :vuetify-props="{
                                    hideDetails: 'auto',
                                    label: 'Available Advance',
                                    prefix: '$',
                                    disabled: isApplicationReadOnly,
                                    variant: 'filled',
                                    readonly: true,
                                    bgColor: 'transparent',
                                    id: 'loan-structure-available-advance',
                                }"
                                :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                                v-model="myAvailableAdvance" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <term-menu
                                :current-term="props.deal.term"
                                :disabled="isApplicationReadOnly"
                                :mileage="selectedVehicle?.miles ?? 0"
                                @term-applied="onTermConfirmButton" />
                        </v-col>
                        <v-col> </v-col>
                        <v-col cols="12" sm="6">
                            <currency-input
                                :vuetify-props="{
                        hideDetails:'auto',
                            label: 'Payment',
                            clearable: true,
                            prefix: '$',
                            error: !isPaymentValid,
                            variant: 'filled',
                            bgColor: 'white',
                            disabled: isApplicationReadOnly,
                            id: 'loan-structure-payment',
                            rules: [
                                (value: string) => !!value || 'Payment is required',
                                (value: string) => parseFloat(value) > 0 || 'Payment must be greater than zero',
                                (value: string) => parseFloat(value) <= myDeal.maxPayment || 'Cannot exceed max payment'
    ],
                        }"
                                :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                                v-model="myDeal.payment"
                                @input="onPaymentChange(CalculationType.AmountFinanced)" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <currency-input
                                class="pointer-events-none read-only"
                                :class="{ invalid_field: !isPaymentValid }"
                                :vuetify-props="{
                                    hideDetails: 'auto',
                                    label: 'Max Payment',
                                    prefix: '$',
                                    error: !isPaymentValid,
                                    variant: 'filled',
                                    disabled: isApplicationReadOnly,
                                    bgColor: 'transparent',
                                    readonly: true,
                                    id: 'loan-structure-max-payment',
                                }"
                                :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                                v-model="myDeal.maxPayment" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script lang="ts" setup>
import { debounce } from 'lodash'
import { computed, ComputedRef, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import eventBus, { BUS_EVENTS } from '@/eventBus'
import { useApplicationStore } from '@/stores/application'
import { storeToRefs } from 'pinia'
import { ApplicationDeal } from '@/models/Application/ApplicationDeal'
import { MIN_LOAN_AMOUNT } from '@/constants/amounts'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { CalculationType } from '@/models/Deal/DealStructure'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { CurrencyDisplay } from 'vue-currency-input'
import { usePolicyStore } from '@/stores/policy'
import AprMenu from '@/components/partials/aprMenu/AprMenu.vue'
import TermMenu from '@/components/partials/termMenu/TermMenu.vue'

defineComponent({
    name: 'LoanStructure',
})
type EmitDeal = {
    myDeal: Ref<ApplicationDeal>
    calculation: CalculationType
}

interface EventsEmitted {
    (event: 'calculateDeal', dealData: EmitDeal): void

    (event: 'validation', isValid: boolean): void

    (event: 'updatedAprByDealStructure', alert: boolean): void
}

const emit = defineEmits<EventsEmitted>()

eventBus.$on(BUS_EVENTS.CLOSE_POPOUTS, () => {
    closePopoutIfOpen(aprOpen)
    closePopoutIfOpen(isTermPopOutOpen)
})

// store refs
const {
    rolledAmount,
    loanStructureLoader,
    isApplicationReadOnly,
    showAPRDisclaimer: isUsuryState,
} = storeToRefs(useApplicationStore())
const { policy } = storeToRefs(usePolicyStore())

// define props
interface Props {
    availableAdvance: number
    deal: ApplicationDeal
    selectedVehicle: Vehicle | undefined
    shouldUpdateAPR: boolean
    hasSpreadFee: boolean | true
}

const props = defineProps<Props>()

// initializations
// apr related
const aprOpen: Ref<boolean> = ref(false)
const confirmedApr: Ref<number> = ref<number>(props.deal.apr)
// term related
const cMaxTermPolicy: ComputedRef<number> = computed(() =>
    (props.selectedVehicle?.miles ?? 0) > policy.value.MAX_TERM_MILEAGE_CONSTRAINT
        ? policy.value.MAX_TERM_OVER_MILEAGE_CONSTRAINT
        : policy.value.MAX_TERM_UNDER_MILEAGE_CONSTRAINT,
)
const isTermPopOutOpen: Ref<boolean> = ref(false)
const isTermValid: Ref<boolean> = ref(cMaxTermPolicy.value >= props.deal.term)
const manuallySelectedAPR: Ref<boolean> = ref<boolean>(false)
const amountFinancedValidationMessage: Ref<string> = ref('')

// deal related
const myDeal: Ref<ApplicationDeal> = ref(props.deal)
const myAvailableAdvance: Ref<number> = ref(props.availableAdvance)
const isRequiredInputsInvalid: ComputedRef<boolean> = computed(
    () =>
        !myDeal.value.amountFinanced || !myDeal.value.payment || !isAmountFinancedValid.value || !isPaymentValid.value,
)

// computed validations
const isAmountFinancedValid: ComputedRef<boolean> = computed(() => {
    if (myDeal.value.amountFinanced < MIN_LOAN_AMOUNT) {
        amountFinancedValidationMessage.value = `Must be greater than $${MIN_LOAN_AMOUNT}`
        return false
    }
    if (myDeal.value.amountFinanced > myDeal.value.maxAmountFinanced) {
        amountFinancedValidationMessage.value = 'Cannot exceed max amount financed'
        return false
    }
    amountFinancedValidationMessage.value = ''
    return true
})
const isPaymentValid: ComputedRef<boolean> = computed(() => {
    return myDeal.value.payment <= myDeal.value.maxPayment && myDeal.value.payment > 0
})

// computed styles
const availableAdvanceStyleColor: ComputedRef<string> = computed(() => {
    if (myAvailableAdvance.value > 0) {
        return 'positive_available_advance '
    }
    return ''
})

const cDisableMaximizeDealButton: ComputedRef<boolean> = computed(() => {
    return myDeal.value.amountFinanced === myDeal.value.maxAmountFinanced
})

// popout event handlers
function closePopoutIfOpen(popout: Ref<boolean>): void {
    if (popout.value) popout.value = !popout.value
}

// apr related
function onAprConfirmClick(confirmedRate: number): void {
    myDeal.value.apr = confirmedRate
    emit('calculateDeal', { myDeal, calculation: CalculationType.DealStructure })
}

// term related
function onTermConfirmButton(confirmedTerm: number): void {
    myDeal.value.term = confirmedTerm
    emit('calculateDeal', { myDeal, calculation: CalculationType.DealStructure })
}

// calculate deal functions
const debounceDeal: _.DebouncedFunc<(calculation: CalculationType) => void> = debounce(calculateDeal, 2000)

function calculate(calculation: CalculationType): void {
    if (isRequiredInputsInvalid.value) {
        return
    }
    debounceDeal(calculation)
}

function calculateDeal(calculation: CalculationType): void {
    if (isRequiredInputsInvalid.value) {
        return
    }

    emit('calculateDeal', { myDeal, calculation })
    validate()
}

function onPaymentChange(calculation: CalculationType): void {
    calculate(calculation)
}

function validate(): void {
    if (!isPaymentValid.value) {
        emit('validation', false)
        return
    }
    if (!isAmountFinancedValid.value) {
        emit('validation', false)
        return
    }
    if (aprOpen.value) {
        emit('validation', false)
        return
    }
    if (isTermPopOutOpen.value || !isTermValid.value) {
        emit('validation', false)
        return
    }
    emit('validation', true)
}

function maximizeDeal(): void {
    myDeal.value.amountFinanced = myDeal.value.maxAmountFinanced
    calculate(CalculationType.DealStructure)
}

onMounted(() => {
    validate()
})

// watchers
watch(
    () => props.deal,
    (val) => {
        myDeal.value = val
        //todo what does this do?
        if (
            isUsuryState &&
            props.shouldUpdateAPR &&
            (!manuallySelectedAPR.value || confirmedApr.value > props.deal.max_contract_rate)
        ) {
            confirmedApr.value = val.apr
            emit('updatedAprByDealStructure', manuallySelectedAPR.value)
        }
        validate()
    },
    { deep: true },
)

watch(
    () => myDeal,
    () => validate(),
    { deep: true },
)

watch(
    () => props.availableAdvance,
    (val) => (myAvailableAdvance.value = val),
    { deep: true },
)
</script>

<style scoped>
:deep(.v-field__input) {
    font-weight: 600 !important;
}
</style>
