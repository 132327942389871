type StyleMapper = {
    [string: string]: (days: number, isIndependent: boolean) => string
}

const stylerMapper: StyleMapper = {
    outstanding: styleOutstandingDaysBadge,
    recourse: styleRecourseDaysBadge,
    fastfund: styleFastFundDaysBadge,
}

export const yellowBadgeStyle = 'bg-[#FDF9D2] text-[#B18B04]'
export const redBadgeStyle = 'bg-[#FEE8ED] text-[#D3214B]'
export const defaultBadgeStyle = 'bg-transparent text-slate-500'

export function styleIssueLineBadgeBackground(queue: string, days: number, isIndependent: boolean): string {
    const styler = stylerMapper[queue]
    if (styler) {
        return styler(days, isIndependent)
    } else {
        return 'bg-transparent text-slate-500'
    }
}

function styleOutstandingDaysBadge(days: number, independent: boolean): string {
    if ((days < 40 && !independent) || days < 15) {
        return defaultBadgeStyle
    } else if ((days < 50 && !independent) || days < 20) {
        return yellowBadgeStyle
    } else {
        return redBadgeStyle
    }
}

function styleRecourseDaysBadge(days: number): string {
    if (days < 5) {
        return defaultBadgeStyle
    } else if (days < 8) {
        return yellowBadgeStyle
    } else {
        return redBadgeStyle
    }
}

function styleFastFundDaysBadge(days: number): string {
    if (days < 5) {
        return defaultBadgeStyle
    } else if (days < 8) {
        return yellowBadgeStyle
    } else {
        return redBadgeStyle
    }
}
