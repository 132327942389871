<template>
    <v-text-field v-bind="vuetifyProps" ref="inputRef" v-model="formattedValue" />
</template>

<script setup lang="ts">
import { useCurrencyInput, CurrencyInputOptions } from 'vue-currency-input'
import { VTextField } from 'vuetify/components'
import { watch } from 'vue'

defineOptions({
    name: 'CurrencyInput',
})

const props = defineProps<{
    options: CurrencyInputOptions
    modelValue: number | null
    vuetifyProps: VTextField['$props']
}>()
const { inputRef, formattedValue, setValue } = useCurrencyInput(props.options)

watch(
    () => props.modelValue,
    (value) => {
        setValue(value)
    },
)
defineExpose({ inputRef, formattedValue, setValue })
</script>

<style lang="scss">
.slider-input .v-field {
    --v-field-input-padding-top: 0;
    --v-field-input-padding-bottom: 0;
}
.slider-input .v-text-field__prefix {
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 4px;
}
.v-field__input {
    padding-right: 0;
    font-weight: 500 !important;
}

.read-only .v-field__input {
    color: #94a3b8 !important;
}

.positive_available_advance .v-field__input {
    color: #087670 !important;
}

.negative_available_advance .v-field__input {
    color: #94a3b8 !important;
}

.invalid_field .v-field__input {
    color: #ae362a !important;
}

.selected_vehicle_disbursement .v-field-label {
    font-size: 14px !important;
}

.accounts-page .v-field {
    --v-field-padding-end: 10px !important;
    --v-field-padding-start: 10px !important;
    border-radius: 5px;
}

.accounts-page .v-field__input {
    font-size: 16px !important;
}

.accounts-page .v-field__outline {
    --v-field-border-width: 0 !important;
}

.accounts-page .v-field-label {
    font-size: 14px !important;
}
</style>
