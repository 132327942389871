import { defineStore } from 'pinia'
import { computed, markRaw, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
    IFilterQueryParams,
    IFilters,
    InventoryFiltersResponse,
    isSlider,
    TMultiSelect,
    TSlider,
} from '@/models/Dealer/InventoryFilters'
import { UpdateVehicle, Vehicle } from '@/models/Vehicle/Vehicle'
import { useApplicationStore } from './application'
import pinpointClient from '@/clients/pinpointClient'
import default_car_image from '@/assets/default_car_image.png'
import { AxiosError } from 'axios'
import { sortOptionsAlphabetically } from '@/helpers/sorters'
import { makeFlatArray } from '@/helpers/formatters'
import VinModal from '@/components/modals/VinModal.vue'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'

export const useInventoryStore = defineStore('inventory', {
    state: () => {
        const applicationStore = useApplicationStore()
        const id = ref(applicationStore.dealer?.id ?? 0)
        const dealerZipCode = ref(applicationStore.dealer?.postal_code)
        const inventory = ref<Vehicle[]>([])
        const favorites = ref<Vehicle[]>([])
        const loading = ref(false)
        const isFavorited = ref(false)
        const toggleHorizontalView = ref<boolean>(false)
        const hasInventory = ref(false)
        const inventoryFilters = reactive<IFilters>({} as IFilters)
        const filters = reactive<IFilterQueryParams>({
            page: 1,
            pageSize: 20,
            pageCount: 1,
            apr: applicationStore.maxAPR,
            sort_by: '-profitability',
            used: true,
        })
        const applicationFees = computed(() => applicationStore.fees)
        return {
            dealerZipCode,
            inventory,
            id,
            favorites,
            filters,
            loading,
            applicationFees,
            hasInventory,
            inventoryFilters,
            isFavorited,
            toggleHorizontalView,
        }
    },

    actions: {
        async addVehicle(vehicle: Vehicle, accountId?: number) {
            vehicle.photoURL = default_car_image

            try {
                const { data } = await pinpointClient.put(`/dealer/${this.id}/add/vehicle`, vehicle, {
                    params: { account_id: accountId },
                })
                this.inventory.push(data)
                return data as Vehicle
            } catch (error) {
                return
            }
        },
        async deleteVehicle(vin: string, accountId?: number) {
            const { data } = await pinpointClient.delete(`/dealer/${this.id}/delete/vehicle/${vin}`)
            this.updateFavorite(vin, false, accountId)
            const index = this.inventory.findIndex((vehicle) => vehicle.vin === vin)
            if (index > -1) {
                this.inventory.splice(index, 1)
            }
            return data as string
        },
        async updateVehicle(vehicle: UpdateVehicle, vin: string, accountId?: number): Promise<Vehicle | undefined> {
            try {
                this.loading = true

                const response = await pinpointClient.put(`/dealer/${this.id}/update/vehicle/${vin}`, vehicle, {
                    params: { account_id: accountId },
                })

                if (response instanceof AxiosError) {
                    throw new Error('Error updating vehicle')
                }
                const index = this.inventory.findIndex((v) => v.vin == response.data.vin)
                if (index > -1) {
                    this.inventory[index] = response.data
                } else {
                    this.inventory.push(response.data)
                }
                this.loading = false
                return response.data
            } catch {
                this.loading = false
            }
        },
        async deleteInventoryVehicle(vin: string, accountId?: number) {
            const queryParams = { account_id: accountId }
            await pinpointClient.delete(`/dealer/${this.id}/delete/vehicle/${vin}`, { params: queryParams })
            this.inventory = this.inventory.filter((vehicle) => vehicle.vin !== vin)
        },
        async getNewFilters(filters: IFilterQueryParams, accountId?: number) {
            try {
                const response = await pinpointClient.get(
                    `/dealer/${this.id}/inventory/filters?account_id=${accountId}`,
                    {
                        params: {
                            ...filters,
                        },
                    },
                )
                if (response && response.data) {
                    for (const key in response.data.filters) {
                        const filter: TMultiSelect | TSlider = response.data?.filters[key]
                        if (isSlider(filter)) {
                            filter.Values.Values = makeFlatArray(filter.Values.Values)
                        } else {
                            filter.Values = filter.Values.sort(sortOptionsAlphabetically)
                        }
                    }
                    return response.data.filters
                }
            } catch (error) {
                return error
            }
        },
        async getInventory(filters: IFilterQueryParams, accountId?: number) {
            const dealerId: number = this.id
            try {
                const data = await pinpointClient.get(`/dealer/${dealerId}/get/inventory`, {
                    params: { ...filters, account_id: accountId },
                })
                const response: InventoryFiltersResponse = data?.data
                if (response) {
                    this.updateInventory(response)

                    return response
                }
            } catch (error) {
                return error
            }
        },
        async setInventoryFilters(accountId?: number): Promise<void> {
            try {
                this.loading = true
                const filterResponse = (await this.getNewFilters(this.filters, accountId)) as IFilters
                if (filterResponse) {
                    this.inventoryFilters = { ...filterResponse }
                }
                const inventoryResponse = (await this.getInventory(this.filters, accountId)) as InventoryFiltersResponse
                if (inventoryResponse) {
                    this.filters.page = inventoryResponse.pagination.page
                    this.filters.pageCount = inventoryResponse.pagination.pageCount
                    this.inventory = inventoryResponse.inventory
                }
            } catch {
                return
            } finally {
                this.loading = false
            }
        },

        async getVehicle(vin: string, accountId?: number): Promise<Vehicle | undefined> {
            const dealerId: number = this.id
            const queryParams = { vin: vin, account_id: accountId }
            const response = await pinpointClient.get<InventoryFiltersResponse>(`/dealer/${dealerId}/get/inventory`, {
                params: queryParams,
            })
            if (response?.data?.inventory?.length) {
                return response?.data?.inventory[0]
            }
        },
        async getFavorites(accountId?: number, overwriteApr: number | undefined = undefined): Promise<Vehicle[]> {
            const dealerId: number = this.id
            const queryParams = { account_id: accountId, apr: overwriteApr || 0 }

            try {
                const data = await pinpointClient.get(`/dealer/${dealerId}/get/favorites`, {
                    params: queryParams,
                })

                let response: Vehicle[] = data.data || []
                response = this.adjustTotalFees({ inventory: response } as InventoryFiltersResponse)
                this.favorites = [...response]
                return response
            } catch {
                return []
            }
        },
        updateSortByFilter(sort: string, accountId?: number): void {
            this.filters.sort_by = sort
            this.resetPage()
            this.setInventoryFilters(accountId)
        },
        updateConditionFilter(accountId?: number): void {
            this.resetPage()
            this.filters.used = !this.filters.used
            this.setInventoryFilters(accountId)
        },
        updateSearchValues(searchValues: { VIN?: string; stock?: string }, accountId?: number): void {
            const isChanged = this.filters.vin !== searchValues.VIN || this.filters.stock_number !== searchValues.stock
            if (isChanged) {
                this.resetPage()
                this.filters.vin = searchValues.VIN || undefined
                this.filters.stock_number = searchValues.stock || undefined
                this.setInventoryFilters(accountId)
            }
        },
        updateApr(apr: number, accountId?: number): void {
            this.resetPage()
            const isChanged = this.filters.apr !== apr
            if (isChanged) {
                this.resetPage()
                this.filters.apr = apr
                this.setInventoryFilters(accountId)
            }
        },
        updateDownPayment(downPayment: number, accountId?: number): void {
            const isChanged = this.filters.downPayment !== downPayment
            if (isChanged) {
                this.resetPage()
                this.filters.downPayment = downPayment || undefined
                this.setInventoryFilters(accountId)
            }
        },
        saveToFavorites(vin: string, accountId?: number): Promise<any> | undefined {
            return pinpointClient
                .put(`/dealer/${this.id}/add/favorite/${vin}?account_id=${accountId}`)
                .then(() => {
                    updateInventoryFavoriteStatus(this.inventory, vin, true)
                })
                .catch((error) => {
                    updateInventoryFavoriteStatus(this.inventory, vin, false)
                })
        },
        removeFromFavorites(vin: string, accountId?: number): Promise<any> | undefined {
            removeFromFavoriteList(this.favorites, vin)
            return pinpointClient
                .put(`/dealer/${this.id}/remove/favorite/${vin}?account_id=${accountId}`)
                .then(() => {
                    updateInventoryFavoriteStatus(this.inventory, vin, false)
                })
                .catch((error) => {
                    updateInventoryFavoriteStatus(this.inventory, vin, true)
                })
        },
        updateFavorite(vin: string, isFavorite: boolean, accountId?: number): Promise<any> | undefined {
            if (isFavorite) {
                return this.saveToFavorites(vin, accountId)
            } else {
                return this.removeFromFavorites(vin, accountId)
            }
        },
        updateInventory(response: InventoryFiltersResponse): void {
            if (response?.inventory?.length > 0) {
                response.inventory = this.adjustTotalFees(response)
            }
            // this is to toggle the addVehicleModal for dealers with no inventory
            if (response.pagination?.totalRecords) {
                this.hasInventory = true
            } else {
                if (!this.hasInventory) {
                    useGlobalModal().openModal({
                        title: this.filters.used ? 'Add used vehicle' : 'Add new vehicle',
                        dialogProps: {
                            maxWidth: '500',
                            persistent: true,
                        },
                        component: {
                            name: markRaw(VinModal),
                        },
                    })
                    //set this to true so we only toggle the openAddVehicle once
                    this.hasInventory = true
                }
            }
        },
        adjustTotalFees(response: InventoryFiltersResponse): Vehicle[] {
            response.inventory.forEach((vehicle: Vehicle): void => {
                if (vehicle.dealStructure?.totalFees) {
                    vehicle.dealStructure.totalFees +=
                        (this.applicationFees?.multiple_bk ?? 0) + (this.applicationFees?.ch_13_bk ?? 0)
                }
                if (vehicle.dealStructure?.netDisbursement) {
                    vehicle.dealStructure.netDisbursement =
                        (vehicle?.amountFinanced ?? 0) -
                        (vehicle.dealStructure?.totalFees ?? 0) +
                        (vehicle.dealStructure?.dealerReserve ?? 0)
                }
            })
            return response.inventory
        },
        resetPage() {
            this.filters.page = 1
        },
    },
    getters: {
        selectedVehicle: (state) => {
            const route = useRoute()
            return state?.inventory.find((vehicle) => {
                return vehicle.vin === route?.params?.vin
            })
        },
    },
})

function updateInventoryFavoriteStatus(inventory: Vehicle[], vin: string, favoriteStatus: boolean) {
    const vehicle = inventory.find((vehicle) => vehicle.vin === vin)
    if (vehicle) {
        vehicle.favorited = favoriteStatus
    }
}

function removeFromFavoriteList(favorites: Vehicle[], vin: string) {
    if (favorites && favorites.length > 0) {
        const index = favorites.findIndex((vehicle) => vehicle.vin === vin)
        if (index > -1) {
            favorites.splice(index, 1)
        }
    }
}
