import { round } from 'lodash'
import dayjs from 'dayjs'

export const onlyDigitsPlusTwoDecimals: Function = (value: string): string => {
    return value.replace(/[^0-9.]|(?<=.*\..*)\./g, '').replace(/(?:\.\d{0,2}).+/g, (match) => {
        return match.slice(0, match.indexOf('.') + 3)
    })
}

export const onlyLettersAndNumbers: Function = (value: string): string => {
    return value.replace(/[^a-zA-Z0-9]/g, '')
}

export const onlyNumbers: Function = (value: string): string => {
    return value.replace(/[^0-9]/g, '')
}

export const convertToPercent: Function = (value: number): number => {
    return round(value * 100, 2)
}

export const daysTillExpireDateTime = (days: number): string => {
    return dayjs().add(days, 'day').set('hour', 0).set('minute', 0).set('second', 0).toISOString()
}
