import { TMultiSelectValues } from '@/models/Dealer/InventoryFilters'

export function sortOptionsAlphabetically(a: TMultiSelectValues, b: TMultiSelectValues) {
    const aName = a.name.toLowerCase()
    const bName = b.name.toLowerCase()
    if (aName < bName) return -1
    if (aName > bName) return 1
    return 0
}

export function sortAscending<Type>(a: Type, b: Type) {
    if (a < b) return -1
    if (a > b) return 1
    return 0
}

export function sortDescending<Type>(a: Type, b: Type) {
    if (a > b) return -1
    if (a < b) return 1
    return 0
}
