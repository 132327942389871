<template>
    <div :class="`${horizontalCss} p-1 `">
        <add-car :class="hoverCss" :is-horizontal-view="isHorizontalView" />
        <div v-for="(car, index) in cVehicleListWithAd" :key="`${index}-${car.vin}-${car.favorited}`" :class="hoverCss">
            <component
                :is="getComponent(index)"
                :test-data="filters.used ? 'used-car' : 'new-car'"
                :horizontal-view="isHorizontalView"
                :vehicle-info="car"
                @click="goToVehicleDetails(car)" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Vehicle } from '@/models/Vehicle/Vehicle'
import AddCar from '@/components/cards/AddCar.vue'
import { computed, ComputedRef, defineComponent, markRaw } from 'vue'
import { storeToRefs } from 'pinia'
import { useInventoryStore } from '@/stores/inventory'
import { useRoute, useRouter } from 'vue-router'
import InventoryVehicleCard from '@/components/cards/InventoryVehicleCard.vue'
import { isEmpty } from 'lodash'
import EditVehicleType from '@/constants/editVehicleCardTypes.ts'
import VehicleForm from '@/components/cards/VehicleForm.vue'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'
import AdCampaign from '@/components/partials/AdCampaign.vue'

defineComponent({
    name: 'InventoryCards',
})

// define props
interface Props {
    vehicleList: Array<Vehicle>
    variant?: string | undefined
}

const props = defineProps<Props>()
const modal = useGlobalModal()
const { filters } = storeToRefs(useInventoryStore())
const router = useRouter()
const route = useRoute()
const accountId: string | string[] = route?.params?.account_id
const dealerId: string | string[] = route?.params?.dealer_id

const isHorizontalView: ComputedRef<boolean> = computed(() => props.variant === 'horizontal')

const randomIndex: ComputedRef<number> = computed(() => Math.floor(Math.random() * props.vehicleList.length))
const cVehicleListWithAd = computed(() => {
    const newVehicleList = [...props.vehicleList]
    newVehicleList.splice(randomIndex.value, 0, {} as Vehicle)
    return newVehicleList
})

const horizontalCss: ComputedRef<string> = computed(() =>
    isHorizontalView.value ? 'space-y-3' : 'grid grid-cols-[repeat(auto-fill,minmax(270px,1fr))] gap-4',
)
const hoverCss: ComputedRef<string> = computed(
    () => 'hover:cursor-pointer hover:drop-shadow-[0_2px_4px_rgba(0,0,0,0.6)]',
)
function goToVehicleDetails(car?: Vehicle): void {
    if (!isEmpty(car) && !car.used && !car.value) {
        openGlobalModal(car)
    } else {
        router.push({
            name: 'SelectedVehicle',
            params: { dealer_id: dealerId, account_id: accountId, vin: car?.vin },
        })
        modal.closeModal()
    }
}

function openGlobalModal(car: Vehicle): void {
    modal.openModal({
        title: 'Edit invoice',
        dialogProps: { maxWidth: '800' },
        component: {
            name: markRaw(VehicleForm),
            props: {
                selectedVehicle: car,
                type: EditVehicleType.EditInvoice,
                callback: routeToSelectedVehicle,
            },
        },
    })
}

function routeToSelectedVehicle(vehicle?: Vehicle): void {
    router.push({
        name: 'SelectedVehicle',
        params: { dealer_id: dealerId, account_id: accountId, vin: vehicle?.vin },
    })
    modal.closeModal()
}

function getComponent(index: number) {
    if (index === randomIndex.value) {
        return AdCampaign
    }
    return InventoryVehicleCard
}
</script>
