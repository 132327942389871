<template>
    <v-card class="!px-3 min-w-[220px] w-full bg-background">
        <v-table class="bg-transparent">
            <thead>
                <tr>
                    <th v-for="col in columns" class="!h-0 pt-4 pb-0 !border-none text-vuetify-text-secondary text-sm">
                        {{ col }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(term, index) in terms"
                    :key="index"
                    :class="{ 'bg-vuetify-bg-surface-300': selected === index }"
                    @click="selected = index">
                    <td class="w-12">
                        <v-radio-group v-model="selected" hide-details color="teal-lighten-1">
                            <v-radio :value="index" hide-details></v-radio>
                        </v-radio-group>
                    </td>
                    <td v-if="index !== terms.length - 1">{{ term }} months</td>
                    <td v-else>
                        <p v-if="selected !== terms.length - 1" class="capitalize">
                            {{ custom ? `${custom} months` : 'custom' }}
                        </p>
                        <v-form v-else v-model="validTerm" class="w-24">
                            <currency-input
                                v-model="custom"
                                data-testid="term_menu_custom_input"
                                @update:model-value="updateCustomValue"
                                :vuetify-props="{
                                    label: customMessage,
                                    variant: 'filled',
                                    persistentPlaceholder: true,
                                    rules: [validations.overMax, validations.underMin],
                                    density: 'compact',
                                    hideDetails: true,
                                    placeholder: '00',
                                    bgColor: 'white',
                                }"
                                :options="{
                                    currency: 'USD',
                                    currencyDisplay: CurrencyDisplay.hidden,
                                    precision: 0,
                                    valueRange: {
                                        max: 999,
                                    },
                                }">
                            </currency-input>
                        </v-form>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <v-card-actions class="px-0 pt-0">
            <v-row>
                <v-col class="pr-1">
                    <v-btn
                        block
                        variant="outlined"
                        color="primary"
                        class="!text-xs !tracking-normal text-none grow"
                        @click="closeMenu"
                        >Close
                    </v-btn>
                </v-col>
                <v-col class="pl-1">
                    <v-btn
                        :disabled="!validTerm && selected === terms.length - 1"
                        block
                        variant="flat"
                        data-testid="term_menu_confirm_btn"
                        color="primary"
                        class="!text-xs !tracking-normal text-none grow"
                        @click="handleConfirm"
                        >Apply
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
import { CurrencyDisplay } from 'vue-currency-input'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { reactive, ref, Ref, watch } from 'vue'
import { APPROVED_TERMS, MIN_TERM } from '@/constants/amounts.ts'
import { storeToRefs } from 'pinia'
import { usePolicyStore } from '@/stores/policy.ts'

// define props
interface Props {
    currentTerm?: number
    mileage: number
    disabled?: boolean
    testing?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    testing: false,
    disabled: false,
})

const emit = defineEmits<{
    close: [v: void]
    confirmed: [v: number]
}>()
const { policy } = storeToRefs(usePolicyStore())

const columns: string[] = ['', 'Term']
const validTerm: Ref<boolean> = ref(false)
const maxTerm =
    props.mileage && props.mileage > policy.value.MAX_TERM_MILEAGE_CONSTRAINT
        ? policy.value.MAX_TERM_OVER_MILEAGE_CONSTRAINT
        : policy.value.MAX_TERM_UNDER_MILEAGE_CONSTRAINT
const approvedTerms = APPROVED_TERMS.filter((v) => v <= maxTerm)
const custom: Ref<number | null> = ref(setCustom())
const terms = reactive<Array<number | null>>([...approvedTerms, custom.value])
const customMessage: Ref<string> = ref('custom')
const selected: Ref<number> = ref(terms.indexOf(props.currentTerm ?? 0))

const validations: Ref<Record<string, (value: number) => boolean | string>> = ref({
    overMax: (value: number) => {
        if (value <= maxTerm) {
            customMessage.value = 'Custom'
            return true
        }
        customMessage.value = `Max: ${maxTerm} months`
        return ''
    },
    underMin: (value: number) => {
        if (value >= MIN_TERM) {
            customMessage.value = 'Custom'
            return true
        }
        customMessage.value = `Min: ${MIN_TERM} months`
        return ''
    },
})

function updateCustomValue(val: number): void {
    terms[terms.length - 1] = val
}
function setCustom(): number | null {
    if (props.currentTerm && !approvedTerms.includes(props.currentTerm)) {
        return props.currentTerm
    }
    return null
}
function handleConfirm(): void {
    let selectedTerm: number | null = terms[selected.value]
    if (selectedTerm) {
        emit('confirmed', selectedTerm)
    }
}

function closeMenu(): void {
    emit('close')
}
</script>

<style scoped>
:deep(.v-label) {
    font-size: 10px !important;
    left: -8px;
}
:deep(.v-field__input) {
    padding-left: 8px;
}
</style>
