<template>
    <div class="flex flex-col flex-grow bg-slate-100 py-2">
        <div class="min-h-[150px] lg:min-h-[100px] pb-2">
            <ad-campaign variant="horizontal" />
        </div>
        <div class="flex justify-between mb-4">
            <div class="text-22px text-vuetify-text-normal">Active Approvals</div>
            <menu-sort
                id="menu-sort"
                :sort-menu-items="sortByOptions"
                :selected="sortSelectedSection"
                @emit-sort="onChangeSortOptions" />
        </div>
        <div
            class="bg-white w-full h-full"
            :class="{ 'flex items-center justify-center h-full w-full': !rows?.length || loadingApprovals }">
            <div v-if="loadingApprovals">
                <v-progress-circular color="primary" indeterminate :size="42" :width="6"></v-progress-circular>
            </div>
            <div v-else-if="!loadingApprovals && !rows?.length">
                <empty-state
                    border-size="400"
                    icon-subtitle="No active approvals"
                    footer-text="Enter a loan application into your DMS tool"
                    :vuetify-icon-props="{
                        icon: '$chartBoxOutline',
                        color: 'grey-darken-1',
                        size: '160',
                    }" />
            </div>
            <v-data-table
                v-else
                :headers="approvalsColumns"
                :header-props="{ class: 'text-xs !font-semibold' }"
                items-per-page="50"
                :items="rows">
                <template #item="{ item }">
                    <tr class="text-base">
                        <td>{{ dayjs(item.decisioned).format('MM/DD/YYYY') }}</td>
                        <td>{{ calculateDaysUntil(dayjs(item.created_at).startOf('day').add(30, 'days')) }}</td>
                        <td>{{ item.account_id }}</td>
                        <td>
                            <div>{{ item.primary_borrower_first_name }} {{ item.primary_borrower_last_name }}.</div>
                            <div v-show="item?.co_borrower_name" class="text-xs">{{ item.co_borrower_name }}.</div>
                        </td>
                        <td class="w-lg-50">
                            <div class="w-full flex justify-end">
                                <v-btn
                                    class="text-none !tracking-normal"
                                    color="primary"
                                    variant="outlined"
                                    @click="openDeal(item.account_id)"
                                    >Open</v-btn
                                >
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script lang="ts">
import { useApplicationStore } from '@/stores/application'

export default {
    name: 'Approvals',
}
</script>

<script setup lang="ts">
import { Ref, reactive, ref, onMounted } from 'vue'
import { calculateDaysUntil } from '@/helpers/formatters'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { useApprovals } from '@/composables/approvals'
import EmptyState from '@/components/partials/EmptyState.vue'
import AdCampaign from '@/components/partials/AdCampaign.vue'
import { useInventoryStore } from '@/stores/inventory'
import MenuSort, { ISortMenu } from '@/components/MenuSort.vue'

const route = useRoute()
const router = useRouter()
const applicationStore = useApplicationStore()
const inventoryStore = useInventoryStore()
const dealerID = applicationStore.dealer?.id ?? route.params.dealer_id
const { getActiveApprovals, page, pageLength, updateSorting, rows, loadingApprovals } = useApprovals(
    parseInt(dealerID as string),
)

interface Columns {
    key: string
    title: string
    sortable?: boolean
}

const approvalsColumns: Ref<Columns[]> = ref([
    {
        key: 'decisioned',
        title: 'APPROVAL DATE',
        align: 'start',
        sortable: false,
        rowCustomData: (e: string) => dateFormat(e),
    },
    {
        key: 'expires_at',
        sortable: false,
        align: 'start',
        title: 'DAYS TO EXP.',
        rowCustomData: (e: string) => daysFrom(e),
    },
    { key: 'account_id', sortable: false, align: 'start', title: 'APPLICATION #' },
    { key: 'name', sortable: false, title: 'CUSTOMER' },
    { key: 'open', sortable: false, title: '', align: 'end' },
])

const sortSelectedSection: Ref<string> = ref('decisioned:desc-default')

const sortByOptions: ISortMenu[] = reactive([
    {
        value: 'decisioned:desc-default',
        title: 'Approval Date',
        subtitle: 'Default',
    },
    { type: 'divider' },
    { type: 'subheader', title: 'Days To Expiration' },
    {
        value: 'created_at:desc',
        title: 'High to Low',
        prependTitle: 'Days To Expiration',
    },
    {
        value: 'created_at:asc',
        title: 'Low to High',
        prependTitle: 'Days To Expiration',
    },
    { type: 'divider' },
    { type: 'subheader', title: 'Approval Date' },
    {
        value: 'decisioned:asc',
        title: 'Old to New',
        prependTitle: 'Approval Date',
    },
    {
        value: 'decisioned:desc',
        title: 'New to Old',
        prependTitle: 'Approval Date',
    },
])
const openDeal = (accountId: number): void => {
    inventoryStore.$reset()
    router.push({ name: 'Account', params: { account_id: accountId } })
}

function onChangeSortOptions(option: string): void {
    sortSelectedSection.value = option
    const [column, order] = option.replace('-default', '').split(':')
    updateSorting(`${column} ${order}`)
}

const daysFrom = (date: string): number => {
    return -dayjs().diff(date, 'day')
}
const dateFormat = (date: string): string => {
    return dayjs(date).format('MM/DD/YYYY')
}

onMounted(async () => {
    await getActiveApprovals()
})
</script>

<style scoped lang="scss">
:deep(.v-btn.v-btn--density-default.menu-sort-v-btn) {
    background-color: rgb(var(--v-theme-background));
}
</style>
