import { useAsyncState, UseAsyncStateOptions } from '@vueuse/core'
import { MaybeRefOrGetter, toValue } from 'vue'
import pinpointClient from '@/clients/pinpointClient'

export function useAPIState(
    request: MaybeRefOrGetter,
    defaultValue: any,
    asyncStateOptions: UseAsyncStateOptions<boolean, any> = {},
) {
    async function fetchAPI() {
        const res = await pinpointClient({
            ...toValue(request),
        })
        return res.data
    }

    const { execute, ...asyncState } = useAsyncState(fetchAPI, defaultValue, {
        resetOnExecute: false,
        ...asyncStateOptions,
    })

    return { ...asyncState, execute }
}
