import { storeToRefs } from 'pinia'
import { useApplicationStore } from '@/stores/application'
import pinpointClient from '@/clients/pinpointClient'
import { useRoute } from 'vue-router'
import { computed, ComputedRef, onMounted, ref, Ref } from 'vue'

type FundedDate = {
    funded_datetime: string
}

type BankDetails = {
    account_number: string
    routing_number: string
}

type Transaction = {
    transferDate?: string
    accountName?: string
    transferNumber?: string
    accountNumber?: string
}
export const useGetTransactionDetails = () => {
    const applicationStore = useApplicationStore()
    const route = useRoute()
    const { dealer } = storeToRefs(applicationStore)
    const dealerID = computed(() => route.params.dealer_id)
    const details: Ref<Transaction> = ref({
        accountName: dealer.value?.name,
    })
    const getBankDetails = async (): Promise<void> => {
        try {
            const { data } = await pinpointClient.get<BankDetails>(`dealer/bank/${dealerID.value}`)
            if (data.account_number) {
                details.value.accountNumber = `——— ——— ————— ${data.account_number}`
            }
            if (data.routing_number) {
                details.value.transferNumber = String(data.routing_number)
            }
        } catch {
            return
        }
    }

    const getFundedDate = async (accountId: string): Promise<void> => {
        try {
            const { data } = await pinpointClient.get<FundedDate>(`portfolio/${accountId}`)
            if (data.funded_datetime) {
                details.value.transferDate = data.funded_datetime
            }
        } catch {
            return
        }
    }

    const transactionDetails: ComputedRef<Transaction> = computed(() => details.value)
    onMounted(() => getBankDetails())
    return { transactionDetails, getFundedDate }
}
