<template>
    <v-row data-testid="vehicle-data-top">
        <v-col>
            <div class="text-gray-500 text-[11px] font-normal">List Price</div>
            <div class="text-teal-600 text-xs font-semibold">{{ computedListPrice }}</div>
        </v-col>

        <v-col v-for="info in topFields" :key="info.label">
            <div class="text-gray-500 text-[11px] font-normal">{{ info.label }}</div>
            <div class="text-xs font-semibold">{{ info.value }}</div>
        </v-col>
    </v-row>

    <v-row data-testid="vehicle-data-bottom">
        <v-col v-for="info in bottomFields" :key="info.label">
            <div class="text-gray-500 text-[11px] font-normal">{{ info.label }}</div>
            <div
                class="text-xs font-semibold"
                :class="{ 'text-vuetify-error': info.label === 'Max Fin.' && !vehicleInfo?.value }">
                {{ info.value }}
            </div>
        </v-col>
    </v-row>
</template>
<script setup lang="ts">
import { money } from '@/helpers/formatters.ts'
import { Vehicle } from '@/models/Vehicle/Vehicle.ts'
import { computed, ComputedRef } from 'vue'

const props = defineProps<{ vehicleInfo?: Vehicle }>()
const computedListPrice: ComputedRef<string> = computed(() =>
    props.vehicleInfo?.price ? money(props.vehicleInfo?.price) : '-',
)
const computedPaymentText = computed(() => {
    return props.vehicleInfo?.value ? `${money(props.vehicleInfo?.dealStructure?.payment)}` : 'Invoice required'
})

const computedMaxAmountFinancedText: ComputedRef<string> = computed(() => {
    return props.vehicleInfo?.value ? `${money(props.vehicleInfo?.maxAmountFinanced)}` : 'Invoice required'
})

const topFields = computed(() => {
    if (props.vehicleInfo?.used) {
        return [
            { label: 'Max Fin.', value: money(props.vehicleInfo.maxAmountFinanced ?? 0) },
            { label: 'Payment', value: money(props.vehicleInfo.dealStructure?.payment ?? 0) },
        ]
    } else {
        return [{ label: 'Term', value: props.vehicleInfo?.maxTerm ?? 0 }]
    }
})

const bottomFields = computed(() => {
    if (props.vehicleInfo?.used) {
        return [
            { label: 'Term', value: props.vehicleInfo?.maxTerm ?? 0 },
            { label: 'Total Fees', value: money(props.vehicleInfo.dealStructure?.totalFees ?? 0) },
            { label: 'Disbursement', value: money(props.vehicleInfo.dealStructure?.netDisbursement ?? 0) },
        ]
    }
    return [
        { label: 'Max Fin.', value: computedMaxAmountFinancedText.value },
        { label: 'Payment', value: computedPaymentText.value },
    ]
})
</script>
