<template>
    <div class="flex flex-col w-full">
        <div class="bg-white mb-0.5 border-r-white rounded-tl rounded-bl flex items-center">
            <p class="text-xs pl-2">Show:</p>
            <v-tabs
                id="account-list-tabs"
                color="black"
                variant="flat"
                density="compact"
                v-model="selectedTab"
                class="min-w-min">
                <v-tab id="funding-tab" class="!tracking-normal text-capitalize !text-xs" value="8">Funding</v-tab>
                <v-tab id="booked-tab" class="!tracking-normal text-capitalize !text-xs" value="9,12">Booked</v-tab>
            </v-tabs>
        </div>

        <div v-if="!accountList.length" class="bg-white rounded-tl rounded-bl py-2 px-4 font-medium">
            There are currently no accounts in funding status. When there are accounts in funding, you’ll be able to
            reference them here.
        </div>
        <div v-else class="flex flex-row" v-for="(account, index) in accountList">
            <div :class="`${getWidthClass(account.selected)}`"></div>
            <div
                cy-id="data-test-account-item"
                :class="`${getItemClasses(account.selected)}`"
                @click="onSelectAccount(index)"
                :key="`${account.accountId}-${account.firstName}-${account.lastName}`">
                <div class="w-fit">
                    <v-chip
                        size="small"
                        variant="flat"
                        density="compact"
                        :text="getAccountStatusLabel(account.statusId)"
                        :color="setColorByType(account.statusId)">
                    </v-chip>
                </div>
                <p class="text-sm">{{ adjustName(account.firstName, account.lastName) }}</p>
                <div class="flex flex-row w-full">
                    <p class="text-[0.6875rem] font-medium">App# {{ account.accountId }}</p>
                    <p :class="`text-[0.6875rem] font-medium ml-auto  ${getTextColor(account)}`">
                        {{ setDateMessage(account) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { adjustName, calculateDaysUntil, getDays, isInFunding } from '@/helpers/formatters'
import { AccountStatuses } from '@/constants/accounts'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

export type AccountListItem = {
    firstName: string
    lastName: string
    coborrower?: string
    accountId: number
    applicationId: number
    statusDate: Date
    decisionedDate: Date
    selected: boolean
    assignedDate: Date
    expiresInDays: number
    statusId: string
    primaryBorrowerId: number
}

interface EmittedEvents {
    (event: 'selected', account: AccountListItem): void

    (event: 'selected-account-status', accountId: string): void
}

const emits = defineEmits<EmittedEvents>()

const props = defineProps<{
    accountList: AccountListItem[]
}>()
const selectedTab = defineModel()

// styles stuff
function getItemClasses(selected: boolean): string {
    const defaultClasses =
        'flex flex-col relative w-full p-2  font-normal text-slate-900 border-b bg-gray-50 rounded-l cursor-pointer transition-all'
    const conditionalClasses = selected ? 'bg-white border-r-white' : 'border-r border-gray-200'
    return `${defaultClasses} ${conditionalClasses}`
}

function getWidthClass(selected: boolean): string {
    const defaultClasses = 'bg-transparent transition-all'
    const conditionalClasses = selected ? 'w-0' : 'w-1'
    return `${defaultClasses} ${conditionalClasses}`
}

function setColorByType(status: string): string {
    return isInFunding(status) ? 'primary' : 'green-darken-2'
}

function getTextColor(account: AccountListItem): string {
    if (isInFunding(account.statusId)) {
        const date = getDays(account.decisionedDate, account.assignedDate)
        return calculateDaysUntil(date) <= 2 ? 'text-[#C2103A]' : 'text-gray-500'
    }
    return 'text-gray-500'
}

function setDateMessage(account: AccountListItem) {
    if (isInFunding(account.statusId)) {
        return `Exp in ${account.expiresInDays}d`
    }
    return `Booked ${dayjs(account.statusDate).format('MM/DD/YYYY')}`
}

function getAccountStatusLabel(status: string): string {
    return status == AccountStatuses.Funding ? 'Funding' : 'Booked'
}

// event handlers
function onSelectAccount(index: number): void {
    const currentAccount = props.accountList.find((a) => a.selected)
    props.accountList.forEach((account, i) => {
        account.selected = i === index
        if (currentAccount?.accountId !== account.accountId && account.selected) {
            emits('selected', account)
            router.push({
                name: 'Accounts',
                params: { dealer_id: route.params.dealer_id, account_id: account.accountId },
            })
        }
    })
}
</script>
