<template>
    <div class="w-full text-slate-900 pr-2" cy-id="funding-requirements">
        <header class="font-medium w-full pb-2" id="requirements-header">
            Funding Requirements
            <span>{{ stipsCompleted }} </span>
        </header>
        <empty-state
            v-if="!requirements.length"
            border-size="240"
            icon-subtitle="All set!"
            footer-text="There are currently no missing documents or pending stipulations for this account"
            :vuetify-icon-props="{ icon: '$clipboardCheckOutline', color: 'grey-darken-1', size: '120' }" />
        <div v-else class="overflow-y-auto custom-scrollbar" cy-id="requirements">
            <div
                v-for="(req, i) in requirements"
                :key="i"
                class="flex flex-row text-sm pr-1.5 funding-requirement overflow-auto">
                <div class="flex px-2 py-4 w-full border-b border-slate-300 bg-[#F9FAFB]">
                    <div class="flex flex-col">
                        <div class="flex req-description">{{ req.description }}</div>
                        <div class="flex pt-1">
                            <v-chip
                                label
                                data-testid="req-status"
                                size="small"
                                :color="statuses[req.status].color"
                                :text="statuses[req.status].description"
                                density="compact"
                                :prepend-icon="statuses[req.status].iconName">
                            </v-chip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, defineComponent, reactive } from 'vue'
import EmptyState from '@/components/partials/EmptyState.vue'

defineComponent({
    name: 'FundingRequirements',
})

// define props
interface Props {
    requirements: Array<Requirement>
}

const props = defineProps<Props>()

// internal types
type Requirement = {
    description: string
    status: string
}
type StatusObject = {
    description: string
    color: string
    iconName: string
}

interface StatusNames {
    [key: string]: StatusObject
}

// internal data
const statuses: StatusNames = reactive({
    requested: {
        description: 'Pending review',
        color: 'info',
        iconName: '$dotsHorizontal',
    },
    received: {
        description: 'Stip requirements met',
        color: 'green-darken-2',
        iconName: '$check',
    },
})
const stipsCompleted: ComputedRef<string> = computed(() => {
    const completed: number = props.requirements.filter((stip) => stip.status == 'received').length
    return `${completed}/${props.requirements.length}`
})
</script>

<style scoped>
.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color 0.3s;
}

.custom-scrollbar:hover {
    scrollbar-color: #d9d9d9 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 9999px;
    background-color: transparent;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
}
</style>
