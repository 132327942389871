<template>
    <div class="rounded-lg w-full">
        <div class="font-medium w-full pb-4 text-vuetify-text-normal">Funding Stats</div>
        <div class="flex flex-row gap-8 text-sm text-slate-900">
            <status-card
                title="Time In Funding"
                tool-tip-message="Time since this application entered funding status"
                :value="String(computedFundingDate.value)"
                :unit="computedFundingDate.key" />
            <status-card
                id="avg-funding-minutes"
                title="Avg Funding Time"
                :unit="computedAvgTimeInFunding.key"
                tool-tip-message="Average time that an application you submit takes to be reviewed by our funding analysts"
                :value="String(computedAvgTimeInFunding.value)" />
            <status-card
                v-if="showFundingStats"
                class="flex flex-col items-center border-dashed border-b-2 border-black"
                id="natl-funding-minutes"
                tool-tip-message="Average time that applications submitted nationwide take to be reviewed by our funding analysts"
                title="Natl Funding Time"
                :value="String(nationalFundingMinutes)"
                unit="Minutes" />
            <status-card
                title="Funding Expires"
                :unit="computedYear"
                tool-tip-message="This funding is time sensitive and will expire on the date below"
                :value="computedMonthDay" />
        </div>
    </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { computed, ComputedRef, defineComponent } from 'vue'
import { getTimeInStatusByMilliseconds } from '@/helpers/formatters'
import StatusCard from '@/components/partials/StatusCard.vue'

defineComponent({ name: 'FundingStats' })

// define props
interface Props {
    timeInFunding: number
    averageFundingMinutes: number
    nationalFundingMinutes: number
    fundingExpirationDate: Date
}

const props = withDefaults(defineProps<Props>(), {
    averageFundingMinutes: 0,
})

// internal formatting functions
const computedFundingDate = computed(() => getTimeInStatusByMilliseconds(props.timeInFunding))
const computedAvgTimeInFunding = computed(() => {
    const millisecondsInAMinute = 60000
    const convertedToMilliseconds = props.averageFundingMinutes * millisecondsInAMinute
    return getTimeInStatusByMilliseconds(convertedToMilliseconds)
})

const computedMonthDay: ComputedRef<string> = computed(() => dayjs(props.fundingExpirationDate).format('MM/DD'))

const computedYear: ComputedRef<string> = computed(() => dayjs(props.fundingExpirationDate).format('YYYY'))

const showFundingStats = computed(() => import.meta.env.VITE_FEATURE_FUNDINGSTATS === 'true')
</script>
