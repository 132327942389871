<template>
    <div class="text-center mt-32 text-2xl">{{ text }}</div>
</template>
<script lang="ts" setup>
import { ComputedRef, computed, defineComponent, onMounted } from 'vue'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import { ref, Ref } from 'vue'
import { useApplicationStore } from '@/stores/application'
import { isNew, isVINValid } from '@/helpers/vehicles'
import { useInventoryStore } from '@/stores/inventory'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { daysTillExpireDateTime } from '@/helpers/sanitizers'
import { useAuth } from '@okta/okta-vue'
import pinpointClient from '@/clients/pinpointClient'
import { DealerAuthenticationKeyInformation } from '@/models/DealerAuthenticationKey/DealerAuthenticationKeyInformation'

defineComponent({
    name: 'AccountRouter',
})
// initializations
const route = useRoute()
const appStore = useApplicationStore()
const text: Ref<string> = ref<string>('Routing you to the appropriate page based on account status...')
const dealerAuthenticationKeyInformation = ref<DealerAuthenticationKeyInformation | undefined>(undefined)

const dak: ComputedRef<string> = computed(() => {
    return route.params?.dak as string
})

const account_id: ComputedRef<string> = computed(() => {
    return route.params?.account_id as string
})

const route_name: ComputedRef<string> = computed(() => {
    return route.name as string
})

const approvedRoute = async (): Promise<RouteLocationRaw> => {
    if (!appStore.application) {
        return { name: 'GlobalError', params: { alert: 'error' } }
    }

    const vehicle = appStore.application.vehicle
    if (vehicle && isVINValid(vehicle.vin) && appStore.account) {
        const isAccountVehicleNew = isNew(vehicle.year, vehicle.mileage, appStore.account.created_at)

        const accountVehicle: Vehicle = {
            dealer_id: appStore.account.dealer_id,
            make: appStore.application.vehicle.make,
            miles: appStore.application.vehicle.mileage,
            model: appStore.application.vehicle.model,
            year: appStore.application.vehicle.year,
            used: !isAccountVehicleNew,
            vin: appStore.application.vehicle.vin,
            zipcode: appStore.dealer?.postal_code,
            expiresAt: daysTillExpireDateTime(1),
            favorited: false,
        }
        if (isAccountVehicleNew) {
            accountVehicle.value = appStore.application.vehicle.invoice
        }
        const inventoryStore = useInventoryStore()

        let addedVehicle: Vehicle | undefined
        try {
            addedVehicle = await inventoryStore.addVehicle(accountVehicle, appStore.account.id)
        } catch (error: any) {
            console.error('error adding vehicle', error)
            if (
                error.response?.status === 400 &&
                error.response?.data?.message == 'cannot add used vehicle from current year'
            ) {
                return {
                    name: 'AccountError',
                    params: { alert: 'invalidVehicle', dealer_id: appStore.account.dealer_id },
                }
            }
        }

        if (addedVehicle) {
            return {
                name: 'SelectedVehicle',
                params: { dealer_id: appStore.account?.dealer_id, account_id: appStore.account?.id, vin: vehicle.vin },
            }
        }
    }

    return { name: 'Inventory', params: { dealer_id: appStore.account?.dealer_id, account_id: appStore.account?.id } }
}

// lifecycle hooks / routing
onMounted(async () => {
    const router = useRouter()
    if (typeof dak.value !== 'string' && typeof account_id.value !== 'string') {
        await router.push({ name: 'GlobalError', params: { alert: 'error' } })
        return
    }

    switch (route_name.value) {
        case 'DealerAuthenticationKeySession':
            await createDealerAuthenticationKeySession(dak.value)
            await getDealerAuthenticationKeyInformationByDAK(dak.value)
            if (dealerAuthenticationKeyInformation.value?.account_id) {
                await appStore.getAccount(dealerAuthenticationKeyInformation.value?.account_id, true)
            }
            break
        case 'OktaSession':
            if (!appStore.account) {
                const auth = useAuth()

                let isAuthenticated: boolean | undefined = false

                isAuthenticated = await auth.isAuthenticated()

                if (!isAuthenticated) {
                    await auth.revokeRefreshToken()
                    auth.setOriginalUri(route.path)
                    await auth.signInWithRedirect({})
                    return
                }

                const authResponse = await pinpointClient.get('/jwt/session', {
                    headers: { Authorization: `Bearer ${auth.getAccessToken()}` },
                })
                if (authResponse.status != 200) {
                    router.push({ name: 'GlobalError', params: { alert: 'error' } })
                    return
                }
                await appStore.getAccount(parseInt(account_id.value))
            }
            break
        default:
            await appStore.getAccount(parseInt(account_id.value), true)
    }

    if (!appStore.account) {
        const hasNoDealerIdAndNoAccountId =
            !dealerAuthenticationKeyInformation.value?.dealer_id &&
            !dealerAuthenticationKeyInformation.value?.account_id
        const hasNoAccountId = !dealerAuthenticationKeyInformation.value?.account_id
        if (hasNoDealerIdAndNoAccountId) {
            router.push({ name: 'GlobalError', params: { alert: 'error' } })
        }
        if (hasNoAccountId) {
            router.push({
                name: 'AccountError',
                params: { dealer_id: dealerAuthenticationKeyInformation.value?.dealer_id, alert: 'error' },
            })
        }
        router.push({
            name: 'EmailAuthentication',
            params: { dealer_id: dealerAuthenticationKeyInformation.value?.dealer_id },
            query: {
                callback: `/dealer/${dealerAuthenticationKeyInformation.value?.dealer_id}/account/${dealerAuthenticationKeyInformation.value?.account_id}`,
            },
        })
        return
    }

    if (appStore.isApplicationExpired) {
        router.push({ name: 'AccountError', params: { dealer_id: appStore.account?.dealer_id, alert: 'expired' } })
        return
    }

    let path

    switch (appStore.account.status.status) {
        case 'Auto Approved':
        case 'Approved':
            path = await approvedRoute()
            break
        case 'Application':
        case 'Awaiting Decision':
        case 'Contract Management':
            path = { name: 'AccountError', params: { dealer_id: appStore.account?.dealer_id, alert: 'undecided' } }
            break
        case 'Auto Declined':
        case 'Declined':
            path = { name: 'AccountError', params: { dealer_id: appStore.account?.dealer_id, alert: 'declined' } }
            break
        case 'Funding':
            path = {
                name: 'Accounts',
                params: { dealer_id: appStore.account?.dealer_id, account_id: appStore.account?.id },
            }
            break
        default:
            path = { name: 'AccountError', params: { dealer_id: appStore.account?.dealer_id, alert: 'other_status' } }
    }
    if (path) {
        router.push(path)
    }
})

const createDealerAuthenticationKeySession = async (key: string) => {
    try {
        const config = {
            headers: { Authorization: `Bearer ${key}` },
            data: { ignoreGlobalCatch: true },
        }
        await pinpointClient.get(`/dak/session`, config)
    } catch {
        //if the DAK is expired we don't redirect.  We fall back to the cookie.
    }
}

const getDealerAuthenticationKeyInformationByDAK = async (key: string) => {
    try {
        const response = await pinpointClient.get(`/dak/${key}`)
        dealerAuthenticationKeyInformation.value = response.data
    } catch {
        //we handle errors in the main block
    }
}
</script>
