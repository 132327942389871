import { Toast } from '@/models/toaster/toast'
import { Socket, io } from 'socket.io-client'
import { Ref, ref } from 'vue'

// event types const
export const INCOME_DEBT_CHANGE_EVENT = 'income-debt-change'
export const ACCOUNT_STATUS_CHANGED = 'account-status-changed'
export const DEALER_SUBMITTED_DEAL = 'dealer-submitted-deal'
export const DEALER_STATUS_CHANGE = 'dealer-status-change'
// locations const
export const LOCATION_PINPOINT = 'pinpoint'
export const LOCATION_NORMAN_APPLICATION = 'application'

// websocket events
const CONNECT = 'connect'
const NOTIFICATION = 'notification'
const SUBSCRIBE = 'subscribe'
const UNSUBSCRIBE = 'unsubscribe'

export const socket: Ref<Socket | null> = ref(null)

export const connectArecibo = async (): Promise<void> => {
    if (socket.value) {
        return
    }
    socket.value = await io(import.meta.env.VITE_URL_ARECIBO, {
        path: '/arecibo/socket.io',
        autoConnect: true,
        reconnectionAttempts: 24,
        reconnectionDelay: 5000,
    })
}

export const registerArecibo = (id: string, location: string, notificationHandler = (payload: any) => {}) => {
    if (!socket?.value) {
        return console.error('Socket not connected')
    }

    const payload = {
        room: id,
    }
    socket?.value?.emit(SUBSCRIBE, payload)

    socket?.value?.on(NOTIFICATION, (data) => {
        notificationHandler({
            data,
            current: {
                location,
                sessionId: socket?.value?.id,
                userId: '',
            },
        })
    })
}

export const closeArecibo = (id: string) => {
    const payload = {
        room: id,
    }
    socket?.value?.emit(UNSUBSCRIBE, payload)
    socket?.value?.disconnect()
    socket.value = null
}

export const emitDealerSubmittedDeal = async (id: string) => {
    await socket?.value?.emit(NOTIFICATION, {
        id,
        location: LOCATION_NORMAN_APPLICATION,
        socketId: socket.value?.id,
        target: {
            type: 'room',
            id,
        },
        content: {
            eventType: DEALER_SUBMITTED_DEAL,
        },
    })
}

export const applicationUpdatedToast: Toast = {
    title: 'Deal Updated!',
    message:
        'Data in this deal has been updated by a member of our originations team. Please refresh this window to ensure that your deal is accurate.',
    type: 'warning',
    key: 'income-debt-change',
    location: 'top-center',
    autoClose: false,
    closable: false,
    buttons: [
        {
            label: 'Refresh Page',
            variant: 'primary',
            appendIcon: '$arrowRight',
            textColor: '#0F172A',
            classes:
                '!bg-teal-200 !normal-case !font-normal font-inter text-sm !hover:!bg-teal-300 !tracking-normal !p-4 !h-12',
            onClick: () => {
                window.location.reload()
            },
        },
    ],
}
