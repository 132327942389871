<template>
    <div class="flex flex-col bg-gray-50 p-4 gap-4 w-full">
        <div class="flex flex-row gap-4">
            <div class="flex flex-col gap-2 w-fit">
                <div class="self-center">
                    <v-icon :size="cFileIcon.iconSize" :color="cFileIcon.iconColor" icon="$fileOutline" />
                </div>
                <div v-if="compact" :class="`text-[11px] w-[70px] text-center font-medium ${cFileIcon.labelColor}`">
                    {{ convertBytesIntoLeastUnit(file?.size || 0) }}{{ errorType == 'file_too_large' ? '!' : '' }}
                </div>
            </div>
            <div class="flex flex-col justify-center gap-2 w-full break-all">
                <div class="flex flex-row">
                    <div>{{ file?.filename }}</div>
                </div>
                <div v-if="compact" class="flex flex-col gap-1 w-full">
                    <v-progress-linear
                        v-if="uploadState == 'uploading' || uploadState == 'error'"
                        :model-value="progressPercent"
                        :bg-color="cProgressColor.bgColor"
                        :color="cProgressColor.color"
                        :height="4"></v-progress-linear>
                    <div v-if="uploadState == 'uploading'" class="text-slate-700 text-xs font-normal leading-4">
                        {{ progressPercent }}%
                    </div>
                    <div
                        v-else-if="(uploadState == 'preparing' || uploadState == 'error') && errorType !== ''"
                        class="text-red-700 text-xs font-normal leading-4">
                        {{ errorMessage }}
                    </div>
                    <div
                        v-else-if="uploadState == 'uploaded'"
                        class="flex flex-row justify-between text-slate-700 text-xs font-normal leading-4 w-full select-none">
                        <p>{{ dayjs(file.created_at).format('MM/DD/YYYY') }}</p>
                    </div>
                </div>
                <div v-else>{{ dayjs(file.created_at).format('MM/DD/YYYY') }}</div>
            </div>
            <div
                v-if="isSideRemoveButtonVisible"
                cy-id="file-upload-remove-button"
                data-file-upload-remove-button
                class="self-center cursor-pointer"
                @click="onDeleteDocument()">
                <v-icon size="large" color="#00000054" icon="$close"></v-icon>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Document } from '@/models/DocumentUpload'
import { useApplicationStore } from '@/stores/application'
import { useDealerStore } from '@/stores/dealer/dealer'
import dayjs from 'dayjs'
import { ComputedRef, Ref, computed, onBeforeUnmount, ref, watch } from 'vue'

const applicationStore = useApplicationStore()
const dealerStore = useDealerStore()
const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20MB

interface IProps {
    compact: boolean
    file: Document
    uploadState: UploadState
    fileId: number
    codeWord: string
    accountId: number
    applicationId: number
}
interface Events {
    (event: 'remove-file', documentId: number): void
    (event: 'upload-completed', document: Document): void
    (event: 'upload-fail', document: Document): void
    (event: 'validation-fail', document: Document): void
}

const props = defineProps<IProps>()

const compact: Ref<boolean> = ref(props.compact)
const file: Ref<Document> = ref(props.file)
const fileId: Ref<number> = ref(props.fileId)
const parentState: Ref<UploadState> = ref(props.uploadState)
const codeWord: Ref<string> = ref(props.codeWord)

const emit = defineEmits<Events>()
const requestController = new AbortController()
type Errors = 'upload_fails' | 'file_too_large' | 'duplicated' | ''
type UploadState = 'uploading' | 'error' | 'preparing' | 'uploaded'

const progressPercent = ref(0)

const errorType: Ref<Errors> = ref('')
const uploadState: Ref<UploadState> = ref(parentState)
const errorMessage: ComputedRef<string> = computed(() => {
    if (errorType.value === 'upload_fails') {
        return 'Upload failed. Please try again'
    } else if (errorType.value === 'file_too_large') {
        return `File exceeds maximum file size(${convertBytesIntoLeastUnit(MAX_FILE_SIZE, 0)}). Cannot upload`
    } else if (errorType.value === 'duplicated') {
        return 'File already exists'
    }

    return ''
})

const cleanUpFilename = (): void => {
    file.value.filename = file.value.filename.replace(' ', '_')
    file.value.filename = file.value.filename.replace(/[^a-zA-Z0-9._]/g, '')
}
if (uploadState.value === 'preparing') {
    isFileTooLarge()
    isFileDuplicated()
}

const cProgressColor: ComputedRef<{
    color: string
    bgColor: string
}> = computed(() => {
    if (uploadState.value === 'uploading') {
        return {
            color: '#087670',
            bgColor: '#91E3DF',
        }
    } else if (uploadState.value === 'error') {
        return {
            color: '#D3214B',
            bgColor: '#FFCDD9',
        }
    }
    return {
        color: '#087670',
        bgColor: '#91E3DF',
    }
})

const isSideRemoveButtonVisible: ComputedRef<boolean> = computed(() => {
    if (uploadState.value === 'uploaded' || uploadState.value === 'uploading') {
        return false
    }

    return true
})

const cFileIcon: ComputedRef<{ iconColor: string; labelColor: string; iconSize: number }> = computed(() => {
    let iconProps = {
        iconColor: '#64748B',
        labelColor: 'text-slate-900',
        iconSize: 32,
    }

    if (errorType.value === 'file_too_large') {
        iconProps = {
            iconColor: '#D14132',
            labelColor: 'text-red-700 font-bold',
            iconSize: 32,
        }
    }

    return iconProps
})

function convertBytesIntoLeastUnit(bytes: number, decimalPlaces: number = 2): string {
    if (bytes < 1024) {
        return bytes + ' B'
    } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(decimalPlaces) + ' KB'
    } else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(decimalPlaces) + ' MB'
    } else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(decimalPlaces) + ' GB'
    }
}

function onDeleteDocument(): void {
    emit('remove-file', fileId.value)
}

async function upload(): Promise<void> {
    const payload = {
        account_id: props.accountId || 0,
        application_id: props.applicationId || 0,
        types: [{ id: 14 }], //set to 'other' for now
        filename: file.value.filename || '',
        size: Number(file.value?.size || 0),
        type: file.value?.type || '',
        file64: file.value?.file64 || '',
        file: file.value?.file || new File([''], 'filename'),
        code_word: codeWord.value || '',
        source: 'pinpoint',
        dealer_id: dealerStore.dealer.id || 0,
    }

    try {
        await applicationStore.uploadDocument(payload, progressPercent, requestController)
        emit('upload-completed', file.value)
    } catch {
        errorType.value = 'upload_fails'
        uploadState.value = 'error'
        emit('upload-fail', file.value)
    }
}

function isFileDuplicated(): void {
    cleanUpFilename()
    if (applicationStore.applicationDocuments.length > 0) {
        applicationStore.applicationDocuments.find((document) => {
            if (document.filename === file.value.filename) {
                errorType.value = 'duplicated'
                uploadState.value = 'preparing'
                emit('validation-fail', file.value)
            }
        })
    }
}

function isFileTooLarge(): void {
    if (file.value.size > MAX_FILE_SIZE) {
        errorType.value = 'file_too_large'
        uploadState.value = 'preparing'
        emit('validation-fail', file.value)
    }
}
onBeforeUnmount(() => {
    if (uploadState.value === 'uploading' || uploadState.value === 'uploaded') {
        requestController.abort()
    }
})

watch(
    () => props.uploadState,
    (newVal, oldVal) => {
        if (newVal === 'uploading' && oldVal === 'preparing') {
            if (errorType.value === '' || errorType.value === 'upload_fails') {
                uploadState.value = 'uploading'
                upload()
            }
        }
    },
)
</script>
