<template>
    <SortHeader :cars="inventoryStore.inventory" class="sticky top-0" />
    <div class="flex w-full overflow-hidden gap-x-2">
        <div class="w-full max-w-[326px] h-full overflow-auto space-y-2">
            <div>
                <applicant-details />
            </div>
            <div class="pb-12 bg-white">
                <sidebar />
            </div>
        </div>
        <div class="w-full relative overflow-auto">
            <inventory-sort-bar class="sticky top-0 z-10" />
            <div v-if="dealerIsSuspended" class="p-1">
                <suspended-banner />
            </div>
            <div v-if="inventoryStore.loading" class="flex justify-center items-center h-full gap-2">
                <v-progress-circular indeterminate size="20" color="primary" inventory />
                <p class="font-poppins text-2xl font-semibold bg-transparent">Loading</p>
            </div>
            <template v-else-if="inventoryStore.inventory?.length">
                <inventory-cards
                    :vehicleList="inventoryStore.inventory"
                    :variant="toggleHorizontalView ? 'horizontal' : undefined" />
                <v-pagination v-model="page" :length="filters.pageCount" total-visible="6">
                    <template #next="props">
                        <v-btn
                            v-bind="props"
                            data-testid="inventory-pagination-next"
                            color="primary"
                            variant="outlined"
                            class="text-none !tracking-normal"
                            >Next</v-btn
                        >
                    </template>
                    <template #prev="props">
                        <v-btn
                            v-bind="props"
                            data-testid="inventory-pagination-prev"
                            color="primary"
                            variant="outlined"
                            class="text-none !tracking-normal"
                            >Prev</v-btn
                        >
                    </template>
                </v-pagination>
            </template>
            <div v-else>
                <add-car :is-horizontal-view="toggleHorizontalView" />
                <p class="flex justify-center content-center text-2xl">No Inventory Available</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, onBeforeMount, onMounted, WritableComputedRef } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import SortHeader from './partials/SortHeader.vue'
import { useInventoryStore } from '@/stores/inventory'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import InventoryCards from '@/components/partials/InventoryCards.vue'
import { storeToRefs } from 'pinia'
import { useApplicationStore } from '@/stores/application'
import { Toast } from '@/models/toaster/toast'
import { TOAST_VIEWS } from '@/constants/toast'
import AddCar from '@/components/cards/AddCar.vue'
import {
    ACCOUNT_STATUS_CHANGED,
    applicationUpdatedToast,
    closeArecibo,
    connectArecibo,
    INCOME_DEBT_CHANGE_EVENT,
    LOCATION_PINPOINT,
    registerArecibo,
} from '@/composables/websocket'
import SuspendedBanner from '@/components/partials/banners/SuspendedBanner.vue'
import { useDealerStore } from '@/stores/dealer/dealer'
import InventorySortBar from '@/components/toolbars/InventorySortBar.vue'
import ApplicantDetails from '@/components/cards/ApplicantDetails.vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'

const { dealerIsSuspended } = storeToRefs(useDealerStore())
const inventoryStore = useInventoryStore()
const { filters, toggleHorizontalView } = storeToRefs(inventoryStore)
const popToastLocal = inject('popToastLocal') as (toast: Toast) => {}

const applicationStore = useApplicationStore()
const { account } = storeToRefs(applicationStore)

// pagination
const page: WritableComputedRef<number> = computed({
    get() {
        return filters.value.page || 1
    },
    set(value: number) {
        if (value != filters.value.page) {
            filters.value.page = value
            inventoryStore.setInventoryFilters(applicationStore.account?.id)
        }
    },
})

onMounted(async () => {
    const toast = useApplicationStore().getToastFromQueue(TOAST_VIEWS.INVENTORY)
    if (toast) {
        popToastLocal(toast.toast)
        useApplicationStore().removeToastFromQueue(toast)
    }

    await connectArecibo()
    registerArecibo(account.value?.id.toString() || '', LOCATION_PINPOINT, (payload) => {
        if (
            payload?.data?.eventType === INCOME_DEBT_CHANGE_EVENT ||
            payload?.data?.eventType === ACCOUNT_STATUS_CHANGED
        ) {
            popToastLocal(applicationUpdatedToast)
        }
    })
})

// life cycle hooks
onBeforeMount(async () => {
    await inventoryStore.setInventoryFilters(applicationStore.account?.id)
})

onBeforeRouteLeave((to, from, next) => {
    closeArecibo(account.value?.id.toString() || '')
    next()
})
</script>
