<template>
    <div>
        <div class="bg-slate-50 rounded px-4 text-normal">
            <h1 class="text-xl font-medium border-b py-4">Verify your bank account</h1>
            <div class="flex flex-col gap-6">
                <p cy-id="verify-account-text" class="text-sm text-[#00000099] py-6">
                    Please enter the two micro-deposit amounts made to your {{ bankName }} account ending in
                    {{ digits?.replaceAll('*', '') }}
                    {{ date ? `on or around ${date}` : '' }}
                </p>
                <currency-input
                    :vuetify-props="{
                        class: 'py-2',
                        label: 'Transaction # 1',
                        clearable: true,
                        prefix: '$',
                        bgColor: 'white',
                        rules: [validators.required],
                        id: 'transaction1',
                        disabled: isVerifiedAccount,
                    }"
                    :options="{
                        currency: 'USD',
                        currencyDisplay: CurrencyDisplay.hidden,
                        precision: 2,
                    }"
                    v-model="transaction1" />
                <currency-input
                    :vuetify-props="{
                        label: 'Transaction # 2',
                        clearable: true,
                        prefix: '$',
                        bgColor: 'white',
                        rules: [validators.required],
                        id: 'transaction2',
                        disabled: isVerifiedAccount,
                    }"
                    :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                    v-model="transaction2" />
            </div>
        </div>
        <div class="flex flex-row gap-4 py-4 justify-end">
            <v-btn
                class="text-none"
                variant="plain"
                :disabled="loading || isVerifiedAccount"
                color="primary"
                :height="36"
                @click="cancel"
                >Cancel</v-btn
            >
            <v-btn
                color="primary"
                cy-id="verify-account-button"
                class="text-none"
                :disabled="isVerifyButtonDisabled"
                :height="36"
                @click="verifyAccount()"
                :loading="loading"
                >Verify account</v-btn
            >
        </div>
        <teleport to="body">
            <v-dialog v-model="dialog" max-width="645" :persistent="true">
                <v-card class="!py-4 !px-6">
                    <v-card-title data-test-dialog-title class="font-inter !text-base !font-semibold">{{
                        title
                    }}</v-card-title>
                    <v-card-text data-test-dialog-description>{{ description }} </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn color="primary" class="!rounded-lg" variant="flat" @click="onCLose"
                            ><span class="!font-inter !text-sm !font-medium capitalize">Close</span></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </teleport>
    </div>
</template>

<script lang="ts" setup>
import { ComputedRef, Ref, computed, ref } from 'vue'
import { CurrencyDisplay } from 'vue-currency-input'
import CurrencyInput from '@/components/CurrencyInput.vue'
import pinpointClient from '@/clients/pinpointClient'
import { validators } from '@/helpers/validators'

const emit = defineEmits(['account-verified'])

const props = defineProps({
    bankName: String,
    digits: String,
    date: String || undefined,
    rowId: Number,
    dealerId: Number,
})

const transaction1: Ref<number> = ref(0)
const transaction2: Ref<number> = ref(0)

const loading: Ref<boolean> = ref(false)
const title: Ref<string> = ref('')
const description: Ref<string> = ref('')
const dialog: Ref<boolean> = ref(false)

const isVerifiedAccount: Ref<boolean> = ref(false)
const isVerifyButtonDisabled: ComputedRef<boolean> = computed(() => {
    return !transaction1.value || !transaction2.value || loading.value || isVerifiedAccount.value
})

function onCLose(): void {
    dialog.value = false
    emit('account-verified')
}

function verifyAccount(): void {
    loading.value = true
    verifyAccountRequest()
        .then(() => {
            isVerifiedAccount.value = true
            setFeedbackTextAndShow(true)
        })
        .catch(() => {
            setFeedbackTextAndShow(false)
        })
        .finally(() => {
            loading.value = false
        })
}

function setFeedbackTextAndShow(isVerified: boolean): void {
    if (isVerified) {
        title.value = 'Account Verified'
        description.value = 'You entered the correct micro-deposit amounts. Your account is now verified.'
    } else {
        title.value = 'Account not verified!'
        description.value = 'The amounts you entered were incorrect, and your account is not verified. Please try again'
    }
    dialog.value = true
}

function verifyAccountRequest(): Promise<void> {
    return pinpointClient.put(`/dealer/bank/verify/${props.rowId}`, {
        dealer_id: props.dealerId,
        micro_transaction_1: transaction1.value,
        micro_transaction_2: transaction2.value,
    })
}

function cancel(): void {
    transaction1.value = 0
    transaction2.value = 0
}

defineExpose({ verifyAccountRequest, title, description, dialog, setFeedbackTextAndShow })
</script>
