<template>
    <div class="flex flex-col items-center justify-between border-dashed border-b-2 border-black pb-2">
        <div class="pb-2" data-test-tile>
            {{ title }}
            <v-tooltip :text="toolTipMessage" location="top" data-testid="tooltip">
                <template #activator="{ props }">
                    <v-icon v-bind="props" color="text-secondary" icon="$informationSlabCircleOutline" size="12" />
                </template>
            </v-tooltip>
        </div>
        <div class="text-2xl font-black text-[#087670]" data-test-date>{{ formattedDate(bookedDate) }}</div>
        <div class="text-xs font-normal" data-test-time>{{ formattedTime(bookedDate) }}</div>
    </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

interface IProps {
    title: string
    toolTipMessage: string
    bookedDate?: Date
}

defineProps<IProps>()

// format functions
function formattedDate(date?: Date): string {
    return dayjs(date).format('MM/DD/YY')
}

function formattedTime(date?: Date): string {
    const format = 'hh:mm a [ET]'
    return dayjs(date, 'HH:mm').format(format)
}
</script>
