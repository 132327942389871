<template>
    <v-card
        :class="`!rounded-lg ${horizontalView ? 'd-flex' : ''}`"
        :height="horizontalView ? '' : '100%'"
        min-width="280"
        :max-width="horizontalView ? '100%' : '330'"
        :max-height="horizontalView ? '105' : ''"
        elevation="1"
        hover>
        <slot>
            <v-img
                :width="horizontalView ? '150' : ''"
                :max-width="horizontalView ? '150' : ''"
                :min-height="horizontalView ? '100' : '220'"
                color="background"
                :src="vehicleInfo?.photoURL ?? default_car_image"
                :lazy-src="default_car_image">
                <template v-slot:error>
                    <v-img :src="default_car_image"></v-img>
                </template>
                <v-btn
                    v-if="vehicleInfo"
                    data-testid="inventory-favorite-btn"
                    :icon="favorite ? '$heart' : '$heartOutline'"
                    variant="tonal"
                    base-color="red"
                    :class="{ 'float-right': !horizontalView }"
                    :color="favorite ? 'red' : 'grey'"
                    @click.stop="onFavoriteClick">
                </v-btn>
            </v-img>

            <v-card-text :class="{ 'd-flex': horizontalView }">
                <slot name="text">
                    <v-row class="max-w-fit">
                        <v-col>
                            <div class="flex items-baseline w-full justify-between">
                                <div class="text-gray-500 text-xs font-light">
                                    Stock # {{ (vehicleInfo?.stockNo ?? '').toUpperCase() }}
                                </div>
                                <v-btn
                                    v-if="vehicleInfo?.expiresAt"
                                    data-testid="inventory-card-delete-btn"
                                    size="small"
                                    density="compact"
                                    text="Remove"
                                    class="text-none !tracking-normal"
                                    append-icon="$windowClose"
                                    @click.stop="openDeleteVehicleModal(vehicleInfo?.vin)" />
                            </div>
                            <div class="font-medium truncate">
                                <span class="text-base font-medium pr-1">{{ vehicleInfo?.year }}</span>
                                <span class="text-base font-medium pr-1">{{ vehicleInfo?.make }}</span>
                                <span class="text-base">{{ vehicleInfo?.model }}</span>
                            </div>
                            <p class="text-gray-500 text-xs">{{ vehicleInfo?.miles }}mi</p>
                        </v-col>
                    </v-row>
                    <v-spacer />
                    <vehicle-data v-if="!horizontalView" :vehicle-info="vehicleInfo" />
                    <vehicle-data-slim v-else :vehicle-info="vehicleInfo" />
                </slot>
            </v-card-text>
        </slot>
    </v-card>
</template>
<script setup lang="ts">
import default_car_image from '@/assets/default_car_image.png'
import { markRaw, Ref, ref } from 'vue'
import { Vehicle } from '@/models/Vehicle/Vehicle.ts'
import { useRoute } from 'vue-router'
import { useInventoryStore } from '@/stores/inventory.ts'
import VehicleData from '@/components/cards/VehicleData.vue'
import VehicleDataSlim from '@/components/cards/VehicleDataSlim.vue'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'
import DeleteVehicle from '@/components/cards/DeleteVehicle.vue'

interface Props {
    vehicleInfo?: Vehicle
    horizontalView?: boolean
}

// initializations
const modal = useGlobalModal()

const route = useRoute()
const accountId: string | undefined = route?.params?.account_id as string | undefined
const inventoryStore = useInventoryStore()
const props = defineProps<Props>()

const favorite: Ref<boolean> = ref((props.vehicleInfo?.favorited || props.vehicleInfo?.is_favorite) ?? false)

function openDeleteVehicleModal(vin?: string): void {
    modal.openModal({
        title: 'Remove Vehicle',
        dialogProps: {
            maxWidth: '600',
        },
        component: {
            name: markRaw(DeleteVehicle),
            props: {
                vin,
            },
        },
    })
}

function onFavoriteClick(): void {
    favorite.value = !favorite.value
    inventoryStore.updateFavorite(props.vehicleInfo?.vin ?? '', favorite.value, Number(accountId))
}
</script>
