import pinpointClient from '@/clients/pinpointClient'
import { AccountItem } from '@/components/cards/UrgentUnresolvedIssues.vue'
import { useLogger } from '@/composables/logger'
import { calculateDaysSince } from '@/helpers/formatters'
import { AccountByEvents, AccountStatus } from '@/models/Account/Account'
import dayjs from 'dayjs'

const logger = useLogger()

export async function getAccountsByDealerIdAndEventIds(
    dealerId: number,
    containEventIds: number[],
    notContainEventIds: number[],
): Promise<AccountItem[]> {
    let accountList: AccountItem[] = []
    const today = dayjs().add(1, 'day').format('YYYY-MM-DD')
    const last90Days = dayjs().subtract(90, 'day').format('YYYY-MM-DD')

    const allEventIds = [...containEventIds, ...notContainEventIds]

    const queryParams = {
        eventIds: allEventIds.join(','),
        statusIds: AccountStatus.Active,
        startDate: last90Days,
        endDate: today,
        page: 1,
        //this is a work around for a bug on the backend
        //this is not really the page length but the number of accounts will check for event id
        //it needs to be large enough to match all active accounts
        //talk to Josh or Candice for details about fixing this
        pageLength: 1000000000,
    }

    const { data } = await pinpointClient.get(`/accounts/events/dealer/${dealerId}`, {
        params: queryParams,
    })

    const accounts = data.Accounts as AccountByEvents[]

    if (accounts) {
        const accountsToExclude: number[] = accounts
            .filter((account: AccountByEvents) => {
                return notContainEventIds.includes(account.event_id)
            })
            .map((account: AccountByEvents) => account.account_id)

        accountList = data.Accounts.filter((account: AccountByEvents) => {
            return accountsToExclude.includes(account.account_id) === false
        })
            .map((account: AccountByEvents) => {
                return {
                    name: account.primary_borrower_name,
                    account: account.account_id.toString(),
                    days: Number(calculateDaysSince(account.created_at)),
                }
            })
            .sort((a: AccountItem, b: AccountItem) => b.days - a.days)
    }
    return accountList
}
