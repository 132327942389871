<template>
    <div class="bg-slate-50 py-4 px-6 rounded-lg text-normal">
        <h1 class="text-xl font-medium py-4 border-b mb-6">Didn’t receive the micro-deposits in your account?</h1>
        <div class="flex flex-col gap-4">
            <p class="text-sm font-medium">
                Be sure to verify that the account information listed here is accurate. If it is not accurate, click
                here to correct it.
            </p>
            <v-btn
                variant="outlined"
                width="264"
                id="cy-id-resend-micro-transactions-correct-bank-info-button"
                class="text-none"
                @click="onCorrectBankInformation"
                >Correct bank information</v-btn
            >
            <p class="text-sm font-medium">
                If the information is correct and you didn’t receive the micro-deposits, click here to send again.
            </p>
            <v-btn
                id="cy-id-resend-micro-transactions-resend-button"
                variant="outlined"
                width="264"
                class="text-none"
                @click="confirmResendMicroDeposits"
                >Resend micro deposits</v-btn
            >
            <p v-if="props.isSetupPage" class="text-sm font-medium">
                If you are still waiting for the micro-transactions in your account, you can come back to finish this
                step later
            </p>
            <v-btn
                v-if="props.isSetupPage"
                id="cy-id-resend-micro-transactions-skip-button"
                data-testid="resend-micro-transactions-skip-button"
                variant="outlined"
                width="264"
                class="text-none"
                @click="onSkipVerification"
                >Skip verification for now</v-btn
            >
        </div>
        <!-- Modal -->
        <v-dialog v-model="dialog" :attach="isTesting" max-width="645" data-confirm-resend-dialog>
            <v-card class="!py-4 !px-6">
                <v-card-title data-test-dialog-title class="!text-base !font-semibold">
                    Ready to verify your account?
                </v-card-title>
                <v-card-text class="text-[#00000099] !text-sm"
                    >You will receive 2 micro-deposits in the account that you entered. You will be asked to enter the
                    exact amounts of the deposits on the next screen to verify your account</v-card-text
                >
                <v-card-actions class="justify-end">
                    <v-btn color="primary" @click="dialog = false" class="text-none">Cancel</v-btn>
                    <v-btn
                        color="primary"
                        :loading="loading"
                        :disabled="loading"
                        variant="flat"
                        class="text-none"
                        id="cy-id-resend-micro-transactions-confirm-resend-button"
                        @click="resendMicroDeposits"
                        >Continue</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script lang="ts" setup>
import pinpointClient from '@/clients/pinpointClient'
import { Ref, ref } from 'vue'
import { useLogger } from '@/composables/logger'

const logger = useLogger()
const props = withDefaults(
    defineProps<{
        accountRowId: number
        isSetupPage: boolean
        isTesting?: boolean
    }>(),
    {
        isTesting: false,
    },
)

const emit = defineEmits(['update-bank-information', 'skip-verification'])

const dialog: Ref<boolean> = ref(false)
const loading: Ref<boolean> = ref(false)

function onCorrectBankInformation(): void {
    emit('update-bank-information')
}

function onSkipVerification(): void {
    emit('skip-verification')
}

function confirmResendMicroDeposits(): void {
    dialog.value = true
}

async function resendMicroDeposits(): Promise<void> {
    loading.value = true
    pinpointClient
        .put(`/dealer/bank/resend/${props.accountRowId}`)
        .then(() => {})
        .catch(() => {
            logger.error('Error resending micro-deposits')
        })
        .finally(() => {
            loading.value = false
            dialog.value = false
        })
}
</script>
<style scoped>
.v-btn.v-btn--density-default {
    border-color: #cbd5e1;
}
</style>
