import { Toast } from '@/models/toaster/toast'
import { Contract } from '@/models/Contract/Contract'
import pinpointClient from '@/clients/pinpointClient'
import { inject } from 'vue'

export const useContract = () => {
    const popToastLocal = inject('popToastLocal') as (toast: Toast) => {}

    function calculateTotalFees(contract: Contract) {
        const acquisitionFee: number =
            contract.disbursement.acquisitionFeeOverride || contract.disbursement.acquisitionFee

        return (
            contract.disbursement.processingFee +
            contract.disbursement.multipleBk +
            acquisitionFee +
            contract.disbursement.ch13Bk +
            contract.disbursement.limitedTermIdFee +
            contract.disbursement.technological_convenience_fee
        )
    }

    async function getContractByAccountID(accountId: number): Promise<Contract | undefined> {
        const { data } = await pinpointClient.get(`/contracts/account/${accountId}`)
        if (data) {
            return data
        }
        popToastLocal({
            title: 'Contract Not Found',
            message: 'We ran into a problem while trying to get your contract information.',
            timer: 5000,
            location: 'top-center',
        })
        return
    }

    return { calculateTotalFees, getContractByAccountID }
}
