<template>
    <div>
        <v-text-field
            v-model="search"
            cy-id="multi-select-filter-search"
            variant="filled"
            hide-details
            bg-color="white"
            class="text-capitalize"
            :label="`search by ${name}`"
            :placeholder="`Input ${name}`" />
    </div>
    <v-virtual-scroll :items="cFilterOptions" class="overflow-auto" height="300" width="300">
        <template #default="{ item }">
            <v-checkbox
                v-model="selected"
                cy-id="multi-select-filter-checkbox"
                hide-details
                multiple
                density="compact"
                :value="item.name"
                @update:modelValue="emitSelectedValues">
                <template #label>
                    <div class="text-capitalize flex items-center">
                        <p class="text-[11px] w-8">{{ item.count }}</p>
                        <p class="text-black w-fit">{{ item.name }}</p>
                    </div>
                </template>
            </v-checkbox>
        </template>
    </v-virtual-scroll>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, Ref } from 'vue'
import { TMultiSelectValues } from '@/models/Dealer/InventoryFilters'
interface Props {
    filterData: TMultiSelectValues[]
    name: string
}
const props = defineProps<Props>()

const emit = defineEmits<{
    'selected-filters': [value: { [index: string]: string }]
    enable: [value: boolean]
}>()

const selected: Ref<string[]> = ref([])
const search: Ref<string> = ref('')
function emitSelectedValues(): void {
    const selectedNames: string = selected.value.map((item) => `"${item}"`).join(',')
    emit('enable', false)
    emit('selected-filters', { multiSelectedValues: selectedNames })
}
const cFilterOptions = computed(() => {
    if (!search.value) {
        return props.filterData
    }
    return props.filterData.filter((option) => {
        return option.name.toLowerCase().includes(search.value.toLowerCase())
    })
})
onMounted(() => {
    emit('enable', true)
    selected.value = props.filterData.filter((item) => item.selected).map((item) => item.name)
})
</script>
