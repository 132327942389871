<template>
    <div class="bg-white shadow rounded-lg divide-y divide-[#EDEDED] h-fit">
        <h1
            class="text-slate-900 font-inter text-base font-semibold px-6 py-4 leading-[1.75rem] border-b-2 border-black">
            Announcements
        </h1>
        <div class="divide-y divide-[#EDEDED]" v-if="announcements.length > 0">
            <announcement v-for="announcement in announcements" v-bind="announcement" />
        </div>
        <div class="p-4" v-else>
            <empty-state
                icon-subtitle="Data Status"
                border-size="240"
                :vuetify-icon-props="{ icon: '$chartBoxOutline', color: 'grey-darken-1', size: '120' }"
                footer-text="No announcements found" />
        </div>
    </div>
</template>
<script setup lang="ts">
import pinpointClient from '@/clients/pinpointClient'
import { dealerTeams } from '@/constants/dealerTeams'
import { useDealerStore } from '@/stores/dealer/dealer'
import dayjs from 'dayjs'
import { Ref, computed, onMounted, ref, watch } from 'vue'
import Announcement from './Announcement.vue'
import EmptyState from './EmptyState.vue'
type AnnouncementType = 'operational' | 'policy' | 'product'
// run pipeline
type AnnouncementResponse = {
    id: number
    type: AnnouncementType
    headline: string
    team: string
    body: string
    created_by: string
    created_at: string
    deleted_at: string
    expiration_date: string
}

type Announcement = {
    type: AnnouncementType
    headline: string
    message: string
    date: string
}

const dealerStore = useDealerStore()

const getTeamKey = computed(() => {
    return dealerTeams[dealerStore.dealer.team_id as keyof typeof dealerTeams] || ''
})

onMounted(async () => {
    announcements.value = await getAnnouncements()
})

const announcements: Ref<Announcement[]> = ref([])

async function getAnnouncements(): Promise<Announcement[]> {
    if (!getTeamKey.value) {
        return []
    }
    const date = dayjs().toISOString()
    const response = await pinpointClient.get(`dealer/announcements/all?team=${getTeamKey.value}&date=${date}`)
    if (response) {
        return response.data
            .map((announcement: AnnouncementResponse) => {
                return {
                    type: announcement.type,
                    headline: announcement.headline,
                    message: announcement.body,
                    date: announcement.created_at,
                }
            })
            .sort((a: Announcement, b: Announcement) => dayjs(b.date).unix() - dayjs(a.date).unix()) // sort by date descending
    }
    return []
}

watch(getTeamKey, async () => {
    announcements.value = await getAnnouncements()
})
</script>
