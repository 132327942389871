<template>
    <div>
        <div class="bg-[#475569] py-[9.4px] flex flex-row justify-center items-center border-b-2 border-[#EF6C00]">
            <img src="@/assets/pinpoint_header.svg" alt="Pinpoint" class="h-[41.9px]" />
        </div>
        <v-container>
            <v-stepper
                class="custom-stepper"
                alt-labels
                bg-color="transparent"
                prev-text="Back"
                :next-text="nextButtonText"
                v-model="selectedStep"
                :flat="true">
                <v-stepper-header>
                    <div class="whitespace-nowrap px-6 font-inter text-xl font-medium text-normal">
                        Setup your dealership
                    </div>
                    <div class="flex flex-row w-full">
                        <v-stepper-item
                            :step="STEP_DEALER_INFORMATION"
                            :value="STEP_DEALER_INFORMATION"
                            color="text-secondary"
                            :disabled="selectedStep != STEP_DEALER_INFORMATION">
                            <template #icon>
                                <span v-if="selectedStep <= STEP_DEALER_INFORMATION">{{
                                    STEP_DEALER_INFORMATION
                                }}</span>
                                <v-icon v-else>mdi-check</v-icon>
                            </template>
                            <span class="lg:whitespace-nowrap">Dealer information </span>
                        </v-stepper-item>
                        <v-divider></v-divider>
                        <v-stepper-item
                            :step="STEP_BANK_INFORMATION"
                            :value="STEP_BANK_INFORMATION"
                            color="text-secondary">
                            <template #icon>
                                <span v-if="selectedStep <= STEP_BANK_INFORMATION">{{ STEP_BANK_INFORMATION }}</span>
                                <v-icon v-else>mdi-check</v-icon>
                            </template>
                            <span class="lg:whitespace-nowrap">Bank information</span>
                        </v-stepper-item>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                        <v-stepper-item :step="STEP_CONTACTS" :value="STEP_CONTACTS" color="text-secondary">
                            <template #icon>
                                <span v-if="selectedStep <= STEP_CONTACTS"> {{ STEP_CONTACTS }}</span>
                                <v-icon v-else>mdi-check</v-icon>
                            </template>
                            Contacts
                        </v-stepper-item>
                    </div>
                </v-stepper-header>
                <v-stepper-window>
                    <v-stepper-window-item :step="STEP_DEALER_INFORMATION" :value="STEP_DEALER_INFORMATION">
                        <dealer-information-form
                            ref="dealerInformation"
                            :is-setup-page="true"
                            v-if="dealerStore.getDealer.isReady">
                            <template #actions> {{}}</template>
                        </dealer-information-form>
                    </v-stepper-window-item>
                    <v-stepper-window-item :step="STEP_BANK_INFORMATION" :value="STEP_BANK_INFORMATION">
                        <v-form v-model="isFormValid">
                            <v-container class="px-0">
                                <v-row align="start" justify="center">
                                    <v-col>
                                        <v-card class="my-4" min-height="665" flat color="surface-100">
                                            <div class="flex flex-col px-6">
                                                <v-toolbar class="!bg-slate-50">
                                                    <v-toolbar-title class="!font-medium !text-normal border-b py-4"
                                                        >Banking Information</v-toolbar-title
                                                    >
                                                </v-toolbar>
                                                <v-card-text class="flex flex-col gap-4">
                                                    <v-card
                                                        class="mb-4"
                                                        v-if="myBankAccount.status === BANK_ACCOUNT_STATUS.VERIFIED">
                                                        <v-card-text>
                                                            <v-icon color="green mr-2" size="24">
                                                                mdi-check-circle-outline
                                                            </v-icon>
                                                            <span class="text-green-darken-2 text-body-1 !font-semibold"
                                                                >Currently verified and active account</span
                                                            >
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-text-field
                                                        v-model="myBankAccount.name"
                                                        :bg-color="cIsBankingAccountPending ? '' : 'white'"
                                                        color="!text-normal"
                                                        :rules="[validators.required]"
                                                        label="Bank name"></v-text-field>
                                                    <v-text-field
                                                        v-model="myBankAccount.routingNumber"
                                                        :rules="[validators.required]"
                                                        :bg-color="cIsBankingAccountPending ? '' : 'white'"
                                                        :maxlength="9"
                                                        @update:model-value="
                                                            myBankAccount.routingNumber = $event.replace(/\D/g, '')
                                                        "
                                                        label="Routing Number"></v-text-field>
                                                    <v-text-field
                                                        v-model="myBankAccount.routingNumberConfirm"
                                                        :bg-color="cIsBankingAccountPending ? '' : 'white'"
                                                        :rules="[
                                                            validators.required,
                                                            validators.match(
                                                                myBankAccount.routingNumber,
                                                                'Routing numbers do not match',
                                                            ),
                                                        ]"
                                                        :maxlength="9"
                                                        @update:model-value="
                                                            myBankAccount.routingNumberConfirm = $event.replace(
                                                                /\D/g,
                                                                '',
                                                            )
                                                        "
                                                        label="Confirm Routing Number"></v-text-field>
                                                    <v-text-field
                                                        v-model="myBankAccount.accountNumber"
                                                        bg-color="white"
                                                        :rules="[validators.required]"
                                                        label="Account Number"
                                                        @update:model-value="
                                                            myBankAccount.accountNumber = $event.replace(/\D/g, '')
                                                        " />
                                                    <v-text-field
                                                        v-model="myBankAccount.accountNumberConfirm"
                                                        bg-color="white"
                                                        :rules="[
                                                            validators.required,
                                                            validators.match(
                                                                myBankAccount.accountNumber,
                                                                'Account numbers do not match',
                                                            ),
                                                        ]"
                                                        label="Confirm Account Number"
                                                        @update:model-value="
                                                            myBankAccount.accountNumberConfirm = $event.replace(
                                                                /\D/g,
                                                                '',
                                                            )
                                                        "></v-text-field>
                                                    <v-select
                                                        v-show="false"
                                                        v-model="myBankAccount.paymentOptionId"
                                                        bg-color="white"
                                                        label="Choose payment frequency option"
                                                        :items="[
                                                            { title: 'ACH', value: 'ach' },
                                                            // { title: 'Wire transfer', value: 'wire', disabled: true },
                                                            // { title: 'FedNow', value: 'fednow', disabled: true },
                                                        ]" />
                                                    <p
                                                        class="text-xs text-normal"
                                                        v-if="myBankAccount.status != BANK_ACCOUNT_STATUS.VERIFIED">
                                                        *In order to verify your bank account we will be sending a
                                                        micro-transaction to your account. The amount will be between
                                                        $0.01 and $0.15 and will say "Arivo Acceptance" in the
                                                        description. Please follow the prompts you will receive after
                                                        finalizing your account setup. Keep in mind that the
                                                        Micro-Transaction can take up to 48 hours to fully complete.
                                                    </p>
                                                </v-card-text>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <!-- PAYMENT OPTION -->
                                        <v-card class="my-4" flat>
                                            <v-toolbar class="!bg-slate-50 mb-10 !border-b">
                                                <v-toolbar-title class="!font-medium py-4 !text-normal"
                                                    >Payment options</v-toolbar-title
                                                >
                                            </v-toolbar>
                                            <v-radio-group
                                                v-model="myBankAccount.paymentOptionId"
                                                :rules="[validators.required]"
                                                direction="horizontal">
                                                <div class="flex flex-col gap-10">
                                                    <v-card color="#e0f2f1b3" elevation="0">
                                                        <v-card-text
                                                            class="flex flex-row gap-8 items-center justify-between px-4 py-10 text-black">
                                                            <div class="flex flex-row items-center gap-8">
                                                                <img src="@/assets/payment_basic.svg" alt="basic" />
                                                                <span class="text-xl font-medium"
                                                                    >ACH Payment twice a day</span
                                                                >
                                                            </div>
                                                            <span class="self"><v-radio value="ach" /></span>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card color="#ede7f6b3" elevation="0">
                                                        <v-card-text
                                                            class="flex flex-row gap-8 items-center px-4 py-10 justify-between text-black">
                                                            <div class="flex flex-row items-center gap-8">
                                                                <img src="@/assets/payment_fast.svg" alt="fast" />
                                                                <span class="text-xl font-medium"
                                                                    >Wire transfer paid out on the hour every hour</span
                                                                >
                                                            </div>
                                                            <span
                                                                ><v-radio value="wire" class="" :disabled="true"
                                                            /></span>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card color="#FAEEED" elevation="0">
                                                        <v-card-text
                                                            class="flex flex-row gap-8 items-center px-4 py-10 justify-between text-black">
                                                            <div class="flex flex-row items-center gap-8">
                                                                <img src="@/assets/payment_fastest.svg" alt="fastest" />
                                                                <span class="text-xl font-medium"
                                                                    >FedNow paid out after each funded application</span
                                                                >
                                                            </div>
                                                            <span
                                                                ><v-radio value="fednow" class="" :disabled="true"
                                                            /></span>
                                                        </v-card-text>
                                                    </v-card>
                                                </div>
                                            </v-radio-group>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-stepper-window-item>
                    <v-stepper-window-item :step="STEP_CONTACTS" :value="STEP_CONTACTS">
                        <dealer-contacts :is-setup-page="true" :titles="contactTitles" :contacts="contacts" />
                    </v-stepper-window-item>
                </v-stepper-window>

                <template #actions="{ prev, next }" class="">
                    <v-row justify="space-between" class="w-full !m-0 px-3 bg-white">
                        <v-col class="text-left text-none">
                            <v-btn
                                v-if="prev"
                                variant="plain"
                                color="primary"
                                @click="handlPrevButtonClick(prev)"
                                class="text-none"
                                :disabled="selectedStep === STEP_DEALER_INFORMATION"
                                >Back</v-btn
                            >
                        </v-col>
                        <v-col class="text-right">
                            <v-btn
                                v-if="next"
                                color="primary"
                                class="text-none"
                                @click="handleNextButtonClick(next)"
                                :disabled="!isAllowedNext"
                                >{{ nextButtonText }}</v-btn
                            >
                        </v-col>
                    </v-row>
                </template>
            </v-stepper>
        </v-container>
    </div>
    <!-- modal -->
    <v-dialog v-model="openDialog" max-width="645">
        <v-card class="!py-4 !px-6">
            <v-card-title data-test-dialog-title class="font-inter !text-base !font-semibold">
                Ready to verify your account?
            </v-card-title>
            <v-card-text class="text-[#00000099] !text-sm !font-inter"
                >You will receive 2 micro-deposits in the account that you entered. You will be asked to enter the exact
                amounts of the deposits on the next screen to verify your account</v-card-text
            >
            <v-card-actions class="justify-end">
                <v-btn color="primary" @click="openDialog = false" class="text-none">Cancel</v-btn>
                <v-btn
                    color="primary"
                    :loading="loading"
                    :disabled="loading"
                    variant="flat"
                    class="text-none"
                    @click="save(myBankAccount)"
                    >Continue</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- modal -->
    <v-dialog v-model="openCompleteModal" max-width="645">
        <v-card class="!py-4 !px-6">
            <v-card-title data-test-dialog-title class="font-inter !text-base !font-semibold">
                Congratulations! You have successfully completed the setup process
            </v-card-title>
            <v-card-text class="text-[#00000099] !text-sm !font-inter">
                To verify your bank account or update any information, please click on “Set up your account” in the top
                right corner of the screen after your next login.
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn
                    color="primary"
                    :loading="loading"
                    :disabled="loading"
                    variant="flat"
                    class="text-none"
                    @click="redirectToHomePage()"
                    >Continue</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { computed, watch, ref, onBeforeMount, Ref, ComputedRef } from 'vue'
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'

import { useDealerStore } from '@/stores/dealer/dealer'
import { storeToRefs } from 'pinia'
import { BANK_ACCOUNT_STATUS, BankAccount } from '@/models/Dealer/BankAccount'
import { validators } from '@/helpers/validators'

import DealerInformationForm from '@/components/DealerInformationForm.vue'
import DealerContacts from '@/components/partials/DealerContacts.vue'

const STEP_DEALER_INFORMATION = 1
const STEP_BANK_INFORMATION = 2
const STEP_CONTACTS = 3

const dealerInformation: Ref<{ onSaveButtonClick: () => void; isFormValid: boolean; validateForm: () => void } | null> =
    ref(null)
const router = useRouter()
const dealerStore = useDealerStore()
const { contactTitles, contacts, bankAccount, dealer } = storeToRefs(dealerStore)
const loading: Ref<boolean> = ref(false)
const isFormValid: Ref<boolean> = ref(false)
const openDialog: Ref<boolean> = ref(false)
const openCompleteModal: Ref<boolean> = ref(false)
const allowUpdateAccount: Ref<boolean> = ref(false)

const steps: Ref<string[]> = ref(['Bank information', 'Contacts', 'Finish'])
const selectedStep: Ref<number> = ref(STEP_DEALER_INFORMATION)
const nextButtonText: ComputedRef<string> = computed(() => `Next: ${steps.value[selectedStep.value - 1] ?? 'Finish'}`)
const cTransactionDate: ComputedRef<string | undefined> = computed(() =>
    myBankAccount.value.microTransactionSent
        ? dayjs(myBankAccount.value.microTransactionSent).format('MM/DD/YYYY')
        : undefined,
)
const isAllowedNext: ComputedRef<boolean> = computed(() => {
    if (selectedStep.value === STEP_DEALER_INFORMATION) {
        return dealerInformation.value?.isFormValid ?? false
    }
    return isFormValid.value
})

onBeforeMount(async () => {
    if (!dealerStore.getDealer.isReady) {
        await dealerStore.getDealer.execute()
        dealerInformation.value?.validateForm()
    }
    await getBankInfo()
})

const myBankAccount: Ref<BankAccount> = ref({
    name: '',
    routingNumber: '',
    accountNumber: '',
    routingNumberConfirm: '',
    accountNumberConfirm: '',
    paymentOptionId: 'ach',
    status: '',
})

const bankAccountToEdit: Ref<BankAccount> = ref({
    name: '',
    routingNumber: '',
    accountNumber: '',
    routingNumberConfirm: '',
    accountNumberConfirm: '',
    paymentOptionId: 'ach',
    status: '',
})

const cIsBankingAccountPending: ComputedRef<boolean> = computed(
    () =>
        myBankAccount.value.status === BANK_ACCOUNT_STATUS.PENDING ||
        myBankAccount.value.status === BANK_ACCOUNT_STATUS.SENT ||
        myBankAccount.value.status === BANK_ACCOUNT_STATUS.VERIFIED,
)

async function getBankInfo(): Promise<void> {
    await dealerStore.getBankInformation(undefined)

    myBankAccount.value.accountNumber =
        bankAccount.value.accountNumber.length > 0 ? bankAccount.value?.accountNumber.padStart(12, '*') : ''
    myBankAccount.value.routingNumber = bankAccount.value.routingNumber
    myBankAccount.value.name = bankAccount.value.name
    myBankAccount.value.status = bankAccount.value.status
    myBankAccount.value.id = bankAccount.value.id
    myBankAccount.value.microTransactionSent = bankAccount.value?.microTransactionSent
    myBankAccount.value.paymentOptionId = bankAccount.value.paymentOptionId || 'ach'

    if (bankAccount.value.status == BANK_ACCOUNT_STATUS.VERIFIED) {
        allowUpdateAccount.value = true
    }
}

function onAccountVerified(): void {
    bankAccountToEdit.value.name = ''
    bankAccountToEdit.value.routingNumber = ''
    bankAccountToEdit.value.accountNumber = ''
    bankAccountToEdit.value.routingNumberConfirm = ''
    bankAccountToEdit.value.accountNumberConfirm = ''
    bankAccountToEdit.value.paymentOptionId = 'ach'
    bankAccountToEdit.value.status = BANK_ACCOUNT_STATUS.VERIFIED

    getBankInfo()
}

function onCorrectBankInformation(): void {
    selectedStep.value = STEP_BANK_INFORMATION
}

function onSkipVerification(): void {
    selectedStep.value = STEP_CONTACTS
}

async function save(account: BankAccount): Promise<void> {
    loading.value = true
    account.status = BANK_ACCOUNT_STATUS.PENDING

    dealerStore
        .saveBankingInfo(account)
        .then(() => {
            openDialog.value = false
            loading.value = false
            getBankInfo()
            selectedStep.value = STEP_CONTACTS
        })
        .catch(() => {
            loading.value = false
        })
}

function handleNextButtonClick(next: () => void): void {
    console.log(selectedStep.value)
    if (selectedStep.value === STEP_DEALER_INFORMATION) {
        dealerInformation.value?.onSaveButtonClick()
        next()
    } else if (selectedStep.value === STEP_BANK_INFORMATION) {
        openDialog.value = true
    } else if (selectedStep.value === STEP_CONTACTS) {
        showCompletedModal()
    } else {
        next()
    }
}

function handlPrevButtonClick(prev: () => void): void {
    if (selectedStep.value === STEP_BANK_INFORMATION) {
        selectedStep.value = STEP_DEALER_INFORMATION
    }
    if (selectedStep.value === STEP_CONTACTS) {
        selectedStep.value = STEP_BANK_INFORMATION
    }
}

function showCompletedModal(): void {
    openCompleteModal.value = true
}

function redirectToHomePage(): void {
    router.push({ name: 'EmailAuthentication', params: { dealer_id: dealer.value?.id ?? 0 } })
}

watch(selectedStep, async (value) => {
    if (value === STEP_BANK_INFORMATION) {
        getBankInfo()
        myBankAccount.value.routingNumberConfirm = ''
        myBankAccount.value.accountNumberConfirm = ''
    }
    if (value === STEP_CONTACTS) {
        if (!dealerStore.getContactTitles.isReady) {
            dealerStore.getContactTitles.execute()
        }
        if (!dealerStore.getDealerContacts.isReady) {
            dealerStore.getDealerContacts.execute()
        }
    }
})
</script>
<style lang="scss">
.custom-stepper {
    .v-stepper-header {
        box-shadow: none;
        background-color: white;
        border-radius: 0.5rem;
    }

    .v-stepper-window {
        margin: 0px;
        margin-top: 0.5rem;
        background-color: white;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }

    .v-stepper-item {
        &[disabled] {
            color: #475569;

            .v-avatar {
                background-color: #475569;
            }
        }
    }

    .v-stepper-item--selected {
        color: #1976d2 !important;

        .v-avatar {
            background-color: #1976d2 !important;
        }
    }

    .v-divider {
        border-width: 1px;
        border-color: #94a3b8;
    }
}
</style>
