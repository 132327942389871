<template>
    <v-menu
        v-model="aprMenuOpen"
        :disabled="disabled"
        :attach="testing"
        max-height="350"
        :close-on-content-click="false"
        location="bottom">
        <template #activator="{ props }">
            <v-text-field
                data-testid="apr_menu_display_input"
                v-bind="props"
                :readonly="disabled"
                class="text-center"
                :variant="variant"
                bg-color="white"
                :value="convertToPercentage(currentRate)"
                label="APR"
                suffix="%"
                hide-details="auto"
                persistent-placeholder
                append-inner-icon="$menuDown">
            </v-text-field>
        </template>
        <apr-menu-card v-if="aprMenuOpen" v-bind="props" @close="aprMenuOpen = false" @confirmed="handleConfirm" />
    </v-menu>
</template>

<script setup lang="ts">
import { Ref, ref } from 'vue'
import { Vehicle } from '@/models/Vehicle/Vehicle.ts'
import { ApplicationDeal } from '@/models/Application/ApplicationDeal.ts'
import AprMenuCard from '@/components/partials/aprMenu/AprMenuCard.vue'
import { VTextField } from 'vuetify/components'

// define props
interface Props {
    currentRate?: number
    selectedVehicle?: Vehicle | undefined
    min?: number
    max?: number
    deal?: ApplicationDeal
    hasSpreadFee?: boolean | true
    variant?: VTextField['$props']['variant']
    disabled?: boolean
    testing?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    min: 0,
    max: 0,
    currentRate: 0,
    variant: 'outlined',
    testing: false,
    disabled: false,
})

const emit = defineEmits<{
    rateApplied: [v: number]
}>()

const aprMenuOpen: Ref<boolean> = ref(false)

function handleConfirm(value: number) {
    emit('rateApplied', value)
    aprMenuOpen.value = false
}
function convertToPercentage(decimalValue: number) {
    let percentage = decimalValue * 100
    return percentage.toFixed(2)
}
</script>

<style scoped></style>
