<template>
    <div class="flex justify-between items-center px-2 pt-2 pb-2 rounded-lg bg-white">
        <div class="flex flex-row gap-4">
            <div class="flex mr-6">
                <div class="flex flex-col pr-4 py-1">
                    <AccountInfoBox :title="adjustName(borrower.firstName, borrower.lastName)" subTitle="Borrower" />
                </div>
                <div v-if="coborrower?.firstName" class="flex flex-col border-l pl-4 py-1">
                    <AccountInfoBox
                        :title="adjustName(coborrower?.firstName, coborrower?.lastName)"
                        subTitle="Co-Borrower" />
                </div>
            </div>
            <div class="bg-gray-100 flex flex-col py-1 px-3 rounded">
                <AccountInfoBox title="Application #" :subTitle="accountId" />
            </div>
            <div v-if="countDownDate" data-test-time-to-expire class="bg-gray-100 flex flex-col py-1 px-3 rounded">
                <AccountInfoBox title="Approval Expires At" :subTitle="expiresIn" :warning="true" />
            </div>
        </div>
        <div class="flex gap-x-3 items-center">
            <v-chip
                data-test-upload-documents
                @click="openDocumentUploadModal()"
                variant="outlined"
                color="primary"
                size="large"
                class="!text-sm"
                text="Upload Documents"
                prepend-icon="$cloudUploadOutline">
            </v-chip>
            <v-btn v-if="showShareButton === true" icon="$shareVariant" color="bg-success"></v-btn>
        </div>
        <Teleport to="body">
            <base-modal
                class="absolute"
                id="document-upload-modal"
                :open="isOpenDocumentUploadModal"
                :dialog-props="{
                    attach: true,
                    persistent: true,
                    width: '100%',
                    maxWidth: '1008px',
                    noClickAnimation: true,
                }"
                title="Document Upload"
                :loading="false"
                :custom-loader="documentLoading"
                :disable-action-btn="false"
                @close-modal="isOpenDocumentUploadModal = false"
                @handle-save="">
                <document-upload-modal
                    testing_id="data-test-modal-upload"
                    :close-modal="
                        () => {
                            isOpenDocumentUploadModal = false
                        }
                    "
                    :account-id="Number(accountId)"
                    :application-id="Number(applicationId)"
                    @uploading="documentLoading = $event" />
            </base-modal>
        </Teleport>
    </div>
</template>

<script setup lang="ts">
import { h, ref, onMounted, inject, markRaw, Ref, computed } from 'vue'
import DocumentUploadModal from '@/components/popables/DocumentUploadModal.vue'
import { adjustName } from '@/helpers/formatters'
import BaseModal from '@/components/modals/BaseModal.vue'
import { isEmpty } from 'lodash'

defineOptions({
    name: 'AccountInfo',
})

export interface Props {
    borrower: {
        firstName?: string
        lastName?: string
    }
    coborrower?: {
        firstName?: string
        lastName?: string
    }
    accountId?: string
    applicationId?: string
    countDownDate?: {
        date: string | number | Date | undefined
        countDown: number
    }
    showShareButton?: boolean
}

const props: Readonly<Props> = defineProps<Props>()
const isOpenDocumentUploadModal: Ref<boolean> = ref(false)
const documentLoading: Ref<boolean> = ref(false)
// document upload modal
function openDocumentUploadModal(): void {
    isOpenDocumentUploadModal.value = true
}

// internal component function
type InfoBox = {
    title: string
    subTitle?: string
    warning?: boolean
}

function AccountInfoBox(props: InfoBox) {
    const textColor: string = props.warning ? 'text-[#d3214b]' : 'text-slate-500'
    return [
        h('span', { class: 'text-base font-semibold text-vuetify-text-normal', innerHTML: props.title ?? '' }),
        h('span', { class: 'text-sm ' + textColor, innerHTML: props.subTitle ?? '' }),
    ]
}

// expiration countdown logic
let expiresIn = ref<string>('')

function startTimer(): void {
    if (props.countDownDate?.date) {
        const millisecondsInOneSecond: number = 1000
        const millisecondsInOneMinute: number = millisecondsInOneSecond * 60
        const millisecondsInOneHour: number = millisecondsInOneMinute * 60
        const millisecondsInOneDay: number = millisecondsInOneHour * 24
        const expirationTime: number =
            new Date(props.countDownDate.date).getTime() + millisecondsInOneDay * props.countDownDate.countDown

        const timeNow: number = new Date().getTime()
        const timeDifference: number = expirationTime - timeNow

        if (timeDifference > 0) {
            const remainingDays = Math.floor(timeDifference / millisecondsInOneDay)
            const remainingHours = Math.floor((timeDifference % millisecondsInOneDay) / millisecondsInOneHour)
            const remainingMinutes = Math.floor((timeDifference % millisecondsInOneHour) / millisecondsInOneMinute)
            const remainingSeconds = Math.floor((timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond)
            expiresIn.value =
                remainingDays + 'd ' + remainingHours + 'h ' + remainingMinutes + 'm ' + remainingSeconds + 's'
        } else expiresIn.value = 'Expired'
    }
}

// component life circle
onMounted(() => {
    if (props.countDownDate) {
        startTimer()
        setInterval(() => {
            startTimer()
        }, 1000)
    }
})
</script>
<style scoped>
.avc-chip-parent[selectedVehicle] {
    border-color: #334155;
}
</style>
