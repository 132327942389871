<template>
    <v-container>
        <v-form v-model="isFormValid" ref="form">
            <v-row class="flex flex-row gap-6 py-6 text-normal">
                <v-col cols="4" class="bg-slate-50 px-6 py-4 rounded-md">
                    <h1 class="text-xl font-medium border-b p-4 mb-6">Dealership information</h1>
                    <v-text-field
                        id="dealer-information-dealer-name"
                        data-testid="dealer-information-dealer-name"
                        label="Dealer Name"
                        color="text-normal"
                        :bg-color="isSetupPage ? 'white' : ''"
                        :disabled="!isSetupPage"
                        v-model="name"
                        outlined></v-text-field>
                    <v-text-field
                        id="dealer-information-legal-name"
                        data-testid="dealer-information-legal-name"
                        label="Legal Name"
                        :disabled="!isSetupPage"
                        :bg-color="isSetupPage ? 'white' : ''"
                        v-model="legalName"
                        outlined></v-text-field>
                    <v-text-field
                        id="dealer-information-business-phone"
                        data-testid="dealer-information-business-phone"
                        bg-color="white"
                        label="Business Phone"
                        v-maska:[maskOptions]
                        :rules="[validators.required]"
                        v-model="businessPhone"
                        outlined></v-text-field>
                    <v-text-field
                        id="dealer-information-business-email"
                        data-testid="dealer-information-business-email"
                        bg-color="white"
                        :rules="[validators.validEmail, validators.required]"
                        label="Business email"
                        v-model="businessEmail"
                        outlined></v-text-field>
                    <v-text-field
                        v-if="isSetupPage"
                        id="dealer-information-domain"
                        data-testid="dealer-information-domain"
                        :bg-color="isSetupPage ? 'white' : ''"
                        :disabled="!isSetupPage"
                        placeholder="example.com"
                        label="Email domain"
                        v-model="emailDomain"
                        outlined></v-text-field>
                    <v-text-field
                        id="dealer-information-route-one-id"
                        data-testid="dealer-information-route-one-id"
                        :bg-color="isSetupPage ? 'white' : ''"
                        :disabled="!isSetupPage"
                        label="Route1 ID#"
                        v-model="routeOneId"
                        outlined></v-text-field>
                    <v-text-field
                        id="dealer-information-dealer-track-id"
                        data-testid="dealer-information-dealer-track-id"
                        :bg-color="isSetupPage ? 'white' : ''"
                        :disabled="!isSetupPage"
                        label="DealerTrack ID#"
                        v-model="dealerTrackId"
                        outlined></v-text-field>
                </v-col>
                <v-col class="flex flex-col !py-0 gap-6">
                    <v-row v-if="isPending" class="mt-1">
                        <v-card width="100%" class="h-fit">
                            <v-card-text class="flex flex-row gap-2">
                                <v-icon color="red-darken-2" size="24" icon="$alertOutline"></v-icon>
                                <p class="text-red-700 text-base font-semibold">
                                    There are currently changes to this information that are pending
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-row>
                    <v-row class="gap-6 mt-0 py-0">
                        <v-col class="bg-slate-50 px-6 py-4 rounded-md">
                            <h1 class="text-xl font-medium border-b p-4 mb-6">Physical address</h1>
                            <v-text-field
                                id="dealer-information-physical-address"
                                data-testid="dealer-information-physical-address"
                                :disabled="!isSetupPage"
                                :bg-color="isSetupPage ? 'white' : ''"
                                label="Physical address"
                                v-model="physicalAddress.address_line_1"
                                :rules="[validators.required]"
                                outlined></v-text-field>
                            <v-text-field
                                id="dealer-information-city"
                                data-testid="dealer-information-city"
                                :disabled="!isSetupPage"
                                :rules="[validators.required]"
                                :bg-color="isSetupPage ? 'white' : ''"
                                label="City"
                                v-model="physicalAddress.city"
                                outlined></v-text-field>
                            <v-text-field
                                id="dealer-information-state"
                                data-testid="dealer-information-state"
                                :maxLength="2"
                                :rules="[
                                    validators.maxLength(2),
                                    validators.minLength(2),
                                    validators.required,
                                    validators.onlyLetters,
                                ]"
                                :disabled="!isSetupPage"
                                :bg-color="isSetupPage ? 'white' : ''"
                                label="State"
                                v-model="physicalAddress.state"
                                outlined></v-text-field>
                            <v-text-field
                                id="dealer-information-suite-unit"
                                data-testid="dealer-information-suite-unit"
                                :disabled="!isSetupPage"
                                :bg-color="isSetupPage ? 'white' : ''"
                                label="Suite/Unit"
                                v-model="physicalAddress.address_line_2"
                                outlined></v-text-field>
                            <v-text-field
                                id="dealer-information-zip-code"
                                data-testid="dealer-information-zip-code"
                                :disabled="!isSetupPage"
                                :bg-color="isSetupPage ? 'white' : ''"
                                label="Zip"
                                :maxLength="5"
                                :rules="[
                                    validators.required,
                                    validators.onlyNumbers,
                                    validators.maxLength(5),
                                    validators.minLength(5),
                                ]"
                                v-model="physicalAddress.postal_code"
                                outlined></v-text-field>
                        </v-col>
                        <v-col class="bg-slate-50 px-6 py-4 rounded-md">
                            <h1 class="text-xl font-medium border-b p-4 mb-6">Mailing address</h1>
                            <div v-if="!isMailingAddressSameAsPhysicalAddress">
                                <v-text-field
                                    id="dealer-information-mailing-physical-address"
                                    data-testid="dealer-information-mailing-physical-address"
                                    bg-color="white"
                                    label="Physical address"
                                    :rules="[validators.required]"
                                    v-model="mailingPhysicalAddress.address_line_1"
                                    outlined></v-text-field>
                                <v-text-field
                                    id="dealer-information-mailing-city"
                                    data-testid="dealer-information-mailing-city"
                                    bg-color="white"
                                    label="City"
                                    :rules="[validators.required]"
                                    v-model="mailingPhysicalAddress.city"
                                    outlined></v-text-field>
                                <v-text-field
                                    id="dealer-information-mailing-state"
                                    data-testid="dealer-information-mailing-state"
                                    bg-color="white"
                                    label="State"
                                    :maxLength="2"
                                    :rules="[
                                        validators.maxLength(2),
                                        validators.minLength(2),
                                        validators.required,
                                        validators.onlyLetters,
                                    ]"
                                    v-model="mailingPhysicalAddress.state"
                                    outlined></v-text-field>
                                <v-text-field
                                    id="dealer-information-mailing-suite-unit"
                                    data-testid="dealer-information-mailing-suite-unit"
                                    bg-color="white"
                                    label="Suite/Unit"
                                    v-model="mailingPhysicalAddress.address_line_2"
                                    outlined></v-text-field>
                                <v-text-field
                                    id="dealer-information-mailing-zip-code"
                                    data-testid="dealer-information-mailing-zip-code"
                                    bg-color="white"
                                    label="Zip"
                                    :maxLength="5"
                                    :rules="[
                                        validators.required,
                                        validators.onlyNumbers,
                                        validators.maxLength(5),
                                        validators.minLength(5),
                                    ]"
                                    v-model="mailingPhysicalAddress.postal_code"
                                    outlined></v-text-field>
                            </div>

                            <v-radio-group
                                class="w-full"
                                :inline="true"
                                label="Mailing address same as physical address?"
                                v-model="isMailingAddressSameAsPhysicalAddress">
                                <v-radio
                                    id="dealer-information-is-mailing-address-same-as-physical-yes"
                                    cy-id="dealer-information-is-mailing-address-same-as-physical-yes"
                                    label="Yes"
                                    :value="true"></v-radio>
                                <v-radio
                                    id="dealer-information-is-mailing-address-same-as-physical-no"
                                    cy-id="dealer-information-is-mailing-address-same-as-physical-no"
                                    label="No"
                                    :value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <slot name="actions" :onSave="onSaveButtonClick" :isDisabled="!isFormValid" :isLoading="loading">
                        <v-row justify="end" align="end">
                            <v-btn color="blue" class="text-none" variant="text">Cancel</v-btn>
                            <v-btn
                                id="dealer-information-save-button"
                                color="blue"
                                class="text-none"
                                :disabled="!isFormValid"
                                :loading="loading"
                                @click="onSaveButtonClick"
                                >Finalize setup</v-btn
                            >
                        </v-row>
                    </slot>
                </v-col>
            </v-row>
        </v-form>
        <v-snackbar color="white" v-model="showSnackbar">{{ messageSnackbar }}</v-snackbar>
    </v-container>
</template>
<script setup lang="ts">
import pinpointClient from '@/clients/pinpointClient'
import { validators } from '@/helpers/validators'
import { DEALER_ADDRESS_TYPE, useDealerStore } from '@/stores/dealer/dealer'
import { TDealerAddress } from '@/stores/dealer/dealerTypes'
import { storeToRefs } from 'pinia'
import { ref, watchEffect } from 'vue'
import { vMaska } from 'maska/vue'
import { VForm } from 'vuetify/components'

type DealerPayload = {
    id: number
    email: string
    phone_number: string
    addresses: TDealerAddress[]
    name?: string
    legal_name?: string
    routeone_id?: string
    dealer_track_id?: string
    domain?: string
}

const props = defineProps<{
    isSetupPage: boolean
}>()

const form = ref<InstanceType<typeof VForm> | null>(null)

const dealerStore = useDealerStore()
const { dealer } = storeToRefs(dealerStore)

const isFormValid = ref<boolean>(false)
const loading = ref<boolean>(false)
const showSnackbar = ref<boolean>(false)
const messageSnackbar = ref<string>('')

const name = ref<string>(dealer.value.name || '')
const legalName = ref<string>(dealer.value.legal_name || '')
const businessPhone = ref<string>(dealer.value.phone_number || '')
const businessEmail = ref<string>(dealer.value.email || '')
const routeOneId = ref<string>(dealer.value.routeone_id || '')
const dealerTrackId = ref<string>(dealer.value.dealer_track_id || '')
const emailDomain = ref<string>('')

const maskOptions = {
    mask: '(###) ###-####',
}

const physicalAddress = ref<TDealerAddress>({
    address_line_1: '',
    address_line_2: '',
    city: '',
    created_at: '',
    dealer_id: 0,
    id: 0,
    postal_code: '',
    state: '',
    type_id: 0,
    updated_at: '',
    updated_by: 0,
})

const mailingPhysicalAddress = ref<TDealerAddress>({
    address_line_1: '',
    address_line_2: '',
    city: '',
    created_at: '',
    dealer_id: 0,
    id: 0,
    postal_code: '',
    state: '',
    type_id: 0,
    updated_at: '',
    updated_by: 0,
})

const isMailingAddressSameAsPhysicalAddress = ref<boolean>(false)
const isPending = ref<boolean>(false)

const _mailingAddress = dealer.value.addresses.find((address) => address.type_id === DEALER_ADDRESS_TYPE.MAILING)
if (_mailingAddress) {
    mailingPhysicalAddress.value.address_line_1 = _mailingAddress.address_line_1
    mailingPhysicalAddress.value.city = _mailingAddress.city
    mailingPhysicalAddress.value.state = _mailingAddress.state
    mailingPhysicalAddress.value.address_line_2 = _mailingAddress.address_line_2
    mailingPhysicalAddress.value.postal_code = _mailingAddress.postal_code
    mailingPhysicalAddress.value.id = _mailingAddress.id
    mailingPhysicalAddress.value.dealer_id = _mailingAddress.dealer_id
    mailingPhysicalAddress.value.type_id = _mailingAddress.type_id
    mailingPhysicalAddress.value.created_at = _mailingAddress.created_at
    mailingPhysicalAddress.value.updated_at = _mailingAddress.updated_at
    mailingPhysicalAddress.value.updated_by = _mailingAddress.updated_by
}

const _physicalAddress = dealer.value.addresses.find((address) => address.type_id === DEALER_ADDRESS_TYPE.PHYSICAL)
if (_physicalAddress) {
    physicalAddress.value.address_line_1 = _physicalAddress.address_line_1
    physicalAddress.value.city = _physicalAddress.city
    physicalAddress.value.state = _physicalAddress.state
    physicalAddress.value.address_line_2 = _physicalAddress.address_line_2
    physicalAddress.value.postal_code = _physicalAddress.postal_code
    physicalAddress.value.id = _physicalAddress.id
    physicalAddress.value.dealer_id = _physicalAddress.dealer_id
    physicalAddress.value.type_id = _physicalAddress.type_id
    physicalAddress.value.created_at = _physicalAddress.created_at
    physicalAddress.value.updated_at = _physicalAddress.updated_at
    physicalAddress.value.updated_by = _physicalAddress.updated_by
}

function onSaveButtonClick(): void {
    const dealerPayload = makeDealerPayload()

    loading.value = true
    pinpointClient
        .patch(`dealer/${dealer.value.id}`, dealerPayload)
        .then(() => {
            showSnackbar.value = true
            messageSnackbar.value = 'Dealer information updated successfully'
            setInterval(() => {
                showSnackbar.value = false
            }, 5000)
        })
        .catch(() => {
            showSnackbar.value = true
            messageSnackbar.value = 'Failed to update dealer information'
            setInterval(() => {
                showSnackbar.value = false
            }, 5000)
        })
        .finally(() => {
            loading.value = false
        })
}

function makeDealerPayload(): DealerPayload {
    const dealerPayload: DealerPayload = {
        id: dealer.value.id || 0,
        phone_number: businessPhone.value.replace(/\D/g, ''),
        email: businessEmail.value,
        addresses: [
            {
                ...physicalAddress.value,
            },
        ],
    }

    if (props.isSetupPage) {
        dealerPayload.name = name.value
        dealerPayload.legal_name = legalName.value
        dealerPayload.routeone_id = routeOneId.value
        dealerPayload.dealer_track_id = dealerTrackId.value
        dealerPayload.domain = emailDomain.value
    }

    if (!isMailingAddressSameAsPhysicalAddress.value) {
        dealerPayload.addresses.push({
            ...mailingPhysicalAddress.value,
            type_id: DEALER_ADDRESS_TYPE.MAILING,
            dealer_id: dealer.value.id ?? 0,
        })
    } else {
        dealerPayload.addresses.push({
            ...physicalAddress.value,
            type_id: DEALER_ADDRESS_TYPE.MAILING,
            id: mailingPhysicalAddress.value.id,
        })
    }

    return dealerPayload
}

function compareAddress(): void {
    if (
        physicalAddress.value.address_line_1 === mailingPhysicalAddress.value.address_line_1 &&
        physicalAddress.value.city === mailingPhysicalAddress.value.city &&
        physicalAddress.value.state === mailingPhysicalAddress.value.state &&
        physicalAddress.value.address_line_2 === mailingPhysicalAddress.value.address_line_2 &&
        physicalAddress.value.postal_code === mailingPhysicalAddress.value.postal_code
    ) {
        isMailingAddressSameAsPhysicalAddress.value = true
        return
    }

    isMailingAddressSameAsPhysicalAddress.value = false
}

function validateForm(): void {
    if (form.value) {
        form.value.validate()
    }
}

watchEffect(() => {
    if (props.isSetupPage) {
        return
    }
    compareAddress()
})

defineExpose({
    onSaveButtonClick,
    isFormValid,
    validateForm,
})
</script>
