<template>
    <v-card :class="computedFormClass">
        <v-card-title :class="['font-semibold text-vuetify-text-normal', titleClasses]">Disbursement</v-card-title>
        <v-card-text :class="variant === 'flat' ? '!p-0' : '!p-2'">
            <v-container :class="subtotalClasses">
                <v-row :class="variant === 'flat' ? '' : 'gap-y-2'">
                    <v-col cols="12" sm="12" :class="{ 'pb-0': variant === 'flat' }">
                        <currency-input
                            :class="computedCurrencyCss"
                            :vuetify-props="{
                                label: 'Amount Financed',
                                prefix: '$',
                                variant: 'filled',
                                readonly: true,
                                bgColor: variant === 'flat' ? 'white' : 'indigo-lighten-5',
                                hideDetails: true,
                                id: 'disbursement-amount-financed',
                            }"
                            :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                            v-model="amountFinanced" />
                    </v-col>

                    <v-col cols="12" sm="12" :class="{ 'py-0': variant === 'flat' }">
                        <currency-input
                            :class="computedCurrencyCss"
                            :vuetify-props="{
                                label: 'Acquisition Fees',
                                prefix: '$',
                                variant: 'filled',
                                readonly: true,
                                hideDetails: true,
                                bgColor: variant === 'flat' ? 'white' : 'transparent',
                                id: 'disbursement-acquisition-fees',
                            }"
                            :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                            v-model="cAcquisitionFees" />
                    </v-col>
                    <v-col v-if="showSpreadFee" cols="12" sm="12" :class="{ 'py-0': variant === 'flat' }">
                        <currency-input
                            :class="computedCurrencyCss"
                            :vuetify-props="{
                                label: dealerFlatOrSpreadString,
                                prefix: '$',
                                variant: 'filled',
                                readonly: true,
                                hideDetails: true,
                                bgColor: 'white',
                                id: 'disbursement-dealer-flat',
                            }"
                            :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                            v-model="dealerFlatOrSpreadValue"
                            @update:modelValue="$emit(dealerFlatOrSpreadEvent, dealerFlatOrSpreadValue)" />
                    </v-col>
                    <v-col class="py-0" cols="12" sm="12" v-if="props.variant === 'flat'">
                        <currency-input
                            :class="computedCurrencyCss"
                            :vuetify-props="{
                                label: 'Total Short Fund Amount',
                                prefix: '$',
                                variant: 'filled',
                                readonly: true,
                                hideDetails: true,
                                bgColor: variant === 'flat' ? 'white' : 'transparent',
                                id: 'disbursement-short-fund-amount',
                            }"
                            :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                            v-model="shortFundAmount"
                            @change="$emit('update:shortFundAmount', shortFundAmount)" />
                    </v-col>
                    <v-col cols="12" sm="12">
                        <currency-input
                            class="selected_vehicle_disbursement pointer-events-none"
                            :class="{ 'accounts-page': variant == 'flat' }"
                            :vuetify-props="{
                                label: variant === 'flat' ? 'Net Disbursement' : 'Total Disbursement',
                                prefix: '$',
                                variant: 'filled',
                                readonly: true,
                                bgColor: 'green-lighten-5',
                                hideDetails: true,
                                id: 'disbursement-total',
                            }"
                            :options="{ currency: 'USD', currencyDisplay: CurrencyDisplay.hidden, precision: 2 }"
                            v-model="totalDisbursement"
                            @change="$emit('update:totalDisbursement', totalDisbursement)" />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script setup lang="ts">
import { computed, ComputedRef, defineComponent, Ref, ref, watch } from 'vue'
import { CurrencyDisplay } from 'vue-currency-input'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { useDealerStore } from '@/stores/dealer/dealer'
import { storeToRefs } from 'pinia'

defineComponent({
    name: 'Disbursement',
})

interface Props {
    amountFinanced: number
    acquisitionFees: number
    dealerFlat: number
    totalDisbursement: number
    shortFundAmount?: number | undefined
    variant: string
    spreadFee: number
    hasSpreadFee: boolean | true
    isAccountPage?: boolean
    apr?: number
    maxApr?: number
}

const props = defineProps<Props>()
const dealerStore = useDealerStore()
const { cIsUsuryState } = storeToRefs(dealerStore)

// initializations
const amountFinanced: Ref<number> = ref(props.amountFinanced)
const acquisitionFees: Ref<number> = ref(props.acquisitionFees)
const dealerFlat: Ref<number> = ref(props.dealerFlat)
const totalDisbursement: Ref<number> = ref(props.totalDisbursement)
const shortFundAmount: Ref<number> = ref(props.shortFundAmount || 0)
const variant: Ref<string> = ref(props.variant || 'solid')
const spreadFee: Ref<number> = ref(props.spreadFee)
const isAccountPage: Ref<boolean> = ref(props.isAccountPage)

const dealerFlatOrSpreadString: ComputedRef<string> = computed(() =>
    props.hasSpreadFee ? 'Rate Buy Down Fee' : 'Dealer Flat',
)
const dealerFlatOrSpreadEvent: ComputedRef<string> = computed(() =>
    props.hasSpreadFee ? 'update:spreadFee' : 'udpate:dealerFlat',
)
const dealerFlatOrSpreadValue: ComputedRef<number> = computed(() =>
    props.hasSpreadFee ? props.spreadFee : props.dealerFlat,
)

const showSpreadFee: ComputedRef<boolean> = computed(() => {
    if (!isAccountPage.value) {
        return true
    }

    if (!props.apr || !props.maxApr) {
        return true
    }

    if (isAccountPage.value && cIsUsuryState.value && props.apr === props.maxApr) {
        return false
    }

    return true
})

const cAcquisitionFees = computed(() => {
    if (!showSpreadFee.value) {
        return acquisitionFees.value + spreadFee.value
    } else {
        return acquisitionFees.value
    }
})

// watch for changes
watch(
    () => props.amountFinanced,
    (val) => {
        amountFinanced.value = val
    },
)
watch(
    () => props.acquisitionFees,
    (val) => {
        acquisitionFees.value = val
    },
)
watch(
    () => props.dealerFlat,
    (val) => {
        dealerFlat.value = val
    },
)
watch(
    () => props.totalDisbursement,
    (val) => {
        totalDisbursement.value = val
    },
)
watch(
    () => props.shortFundAmount,
    (val) => {
        if (shortFundAmount.value !== undefined && val !== undefined) {
            shortFundAmount.value = val
        }
    },
)
watch(
    () => props.spreadFee,
    (val) => {
        spreadFee.value = val
    },
)

// computed styling classes
const titleClasses: ComputedRef<string> = computed(() => {
    return props.variant === 'flat'
        ? 'text-base pl-2'
        : 'border-b border-black text-normal !tracking-normal !text-base !p-4 px-6'
})

const subtotalClasses: ComputedRef<string> = computed(() => {
    return props.variant === 'flat' ? 'pl-0 !py-0' : 'mt-4'
})

const computedFormClass: ComputedRef<string> = computed(() => {
    const constantCss = 'rounded-lg'
    const computed = props.variant === 'flat' ? 'bg-white h-fit' : '!bg-vuetify-background !rounded-lg h-full'
    return `${constantCss} ${computed}`
})

const computedCurrencyCss: ComputedRef<string> = computed(() => {
    const css = 'pointer-events-none'
    const computed = props.variant === 'flat' ? 'accounts-page' : 'read-only'
    return `${css} ${computed}`
})
</script>

<style scoped>
:deep(.v-field__input) {
    font-weight: 600 !important;
}
.selected_vehicle_disbursement :deep(.v-field__input) {
    font-size: 22px;
    font-weight: 500 !important;
}
</style>
