import { isVINValid } from '@/helpers/vehicles.ts'

const required = (v: string) => !!v || 'Field is required'
const minLength =
    (minLength: number, customMessage: string | null = null) =>
    (v: string) =>
        (v && v.length >= minLength) || (customMessage ?? `Min ${minLength} character${minLength > 1 ? 's' : ''}`)
const maxLength = (maxLength: number) => (v: string) =>
    (v && v.length <= maxLength) || `Max ${maxLength} character${maxLength > 1 ? 's' : ''}`
const match = (field: any, message: string) => (v: string) => v === field || message
const validEmail = (email: string): boolean | string => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
        return 'E-mail must be valid'
    }
    return true
}
const onlyNumbers = (v: string) => /^\d+$/.test(v) || 'Only numbers are allowed'
const onlyLetters = (v: string) => /^[a-zA-Z]+$/.test(v) || 'Only letters are allowed'

const validPhoneNumber =
    (customMessage: string) =>
    (v: string): string | boolean => {
        const sanitizedPhoneNumber = v.replace(/[^0-9]/g, '')
        const phoneNumberRegex = /^\d{10}$/
        if (!phoneNumberRegex.test(sanitizedPhoneNumber)) {
            return customMessage || 'invalid phone number'
        }
        return true
    }
const validVin = (value: string) => (!!value && isVINValid(value)) || 'Please enter a valid VIN.'
const noSpecialChars = (value: string) => /^[\w]+$/.test(value) || 'No Special Characters'

export const validators = {
    maxLength,
    match,
    minLength,
    onlyLetters,
    onlyNumbers,
    required,
    validEmail,
    validPhoneNumber,
    validVin,
    noSpecialChars,
}
