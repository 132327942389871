<template>
    <v-card
        class="text-center d-flex flex-column !rounded-lg text-white bg-grey-darken-1"
        width="100%"
        height="100%"
        :max-width="cIsHorizontal ? '100%' : '330'">
        <template #image>
            <v-img :src="getRandomImage" cover />
        </template>
        <v-card-title v-if="!cIsHorizontal">
            <v-card-item class="mt-10 mb-4">
                <v-img :src="AdLogo" height="79"></v-img>
            </v-card-item>
        </v-card-title>
        <v-card-text>
            <v-carousel
                v-if="randomizedCampaign?.length"
                interval="20000"
                :height="cIsHorizontal ? '100%' : '220'"
                cycle
                hide-delimiters
                hide-delimiter-background
                show-arrows="hover">
                <v-carousel-item height="100%" v-for="(slide, i) in randomizedCampaign" :key="i">
                    <div class="d-flex flex-column h-full" :class="!cIsHorizontal ? 'justify-start' : 'justify-center'">
                        <div>
                            <p class="text-xl font-bold" id="campaign-header">{{ slide.header }}</p>
                            <p class="text-base pt-2" id="campaign-body">{{ slide.description }}</p>
                        </div>
                    </div>
                </v-carousel-item>
            </v-carousel>
        </v-card-text>
    </v-card>
</template>
<script setup lang="ts">
import AdLogo from '@/assets/AdLogo.png'
import { useCampaignStore } from '@/stores/campaign'
import { storeToRefs } from 'pinia'
import { computed, ComputedRef, ref, Ref } from 'vue'

const props = withDefaults(defineProps<{ variant?: 'vertical' | 'horizontal'; horizontalView?: boolean }>(), {
    variant: 'vertical',
    horizontalView: false,
})
const { marketingCampaigns } = storeToRefs(useCampaignStore())

/* this is temporary until we create an endpoint to pull all images from the
marketing ad s3 bucket currently they are all named by number from 1-57 */
const adImages: number = 57
const randomIndex: Ref<number> = ref(Math.floor(Math.random() * adImages) || 1)
const getRandomImage: ComputedRef<string> = computed(
    () => `https://pinpoint.testing.arivo.com/static/MarketingPatterns/${randomIndex.value}.png`,
)
const cIsHorizontal: ComputedRef<boolean> = computed(() => props.variant === 'horizontal' || props.horizontalView)
const randomizedCampaign = computed(() => marketingCampaigns.value)
randomizedCampaign.value.sort(() => Math.random() - 0.5)
</script>
