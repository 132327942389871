<template>
    <div class="w-full flex flex-col">
        <header>
            <h2 data-title class="pl-2 mb-2 font-medium text-base">ACH Transaction Details</h2>
        </header>
        <section v-if="dataWithTimeZone !== 'No available date'">
            <div class="bg-gray-50 p-2">
                <h3 data-sub-title class="text-sm font-normal">Transfer Date</h3>
                <p data-description class="text-base font-medium">{{ dataWithTimeZone }}</p>
            </div>
            <div class="bg-gray-50 p-2">
                <h3 data-sub-title class="text-sm font-normal">Account Name</h3>
                <p data-description data-account-name class="text-base font-medium">{{ accountName }}</p>
            </div>
            <!--TODO commenting out for now until endpoint is built to return this data-->
            <!--        <div class="bg-gray-50 p-2">-->
            <!--            <h3 data-sub-title class=" text-sm font-normal">Transfer number</h3>-->
            <!--            <p data-description data-transfer-number class=" text-base font-medium">{{ transferNumber }}</p>-->
            <!--        </div>-->
            <div class="bg-gray-50 p-2">
                <h3 data-sub-title class="text-sm font-normal">Account number</h3>
                <p data-description data-account-number class="text-base font-medium">
                    {{ accountNumber }}
                </p>
                <p data-transfer-date class="text-[11px] font-medium leading-4">
                    {{ dataWithoutTimeZone }}
                </p>
            </div>
        </section>
        <empty-state
            v-else
            icon-subtitle="No ACH information found"
            footer-text="There is currently no history of activity on this account"
            :vuetify-icon-props="{
                icon: '$currencyUsd',
                color: 'grey-darken-1',
                size: '100',
            }"
            border-size="240"></empty-state>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import EmptyState from '@/components/partials/EmptyState.vue'

defineOptions({ name: 'AchTransactionDetails' })

export interface IACHTransactionDetailsProps {
    transferDate?: string
    accountName?: string
    transferNumber?: string
    accountNumber?: string
}

const props = withDefaults(defineProps<IACHTransactionDetailsProps>(), {
    transferDate: 'No available date',
    accountName: 'Not available',
    transferNumber: 'Not available',
    accountNumber: 'Not available',
})

const dataWithTimeZone = computed(() => {
    if (!isValidDateTime()) return props.transferDate
    const transferDate = new Date(props.transferDate)
    //@ts-ignore
    const options: DateTimeFormatOptions = {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
    }

    const formattedDate = transferDate.toLocaleString('en-US', options)
    return formattedDate
})

const dataWithoutTimeZone = computed(() => {
    if (!isValidDateTime()) return props.transferDate
    const transferDate = new Date(props.transferDate)
    //@ts-ignore
    const options: DateTimeFormatOptions = {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }
    const formattedDate = transferDate.toLocaleString('en-US', options)
    return formattedDate
})

function isValidDateTime(): boolean {
    return !isNaN(Date.parse(props.transferDate))
}
</script>
