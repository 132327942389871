export const SPREAD_FEES_SLUG = 'spread_fee'

export const DealerStatuses: { [key: string]: string } = {
    ACTIVE: '1',
    INACTIVE: '2',
    SUSPENDED: '3',
}

export type TDealerAddress = {
    address_line_1: string
    address_line_2: string
    city: string
    created_at: string
    dealer_id: number
    id: number
    postal_code: string
    state: string
    type_id: number
    updated_at: string
    updated_by: number
}

export type TDealer = {
    id: number | null
    name: string | null
    phone_number: string | null
    dealer_type_id: number | null
    type: string | null
    team_id: number | null
    active: string | null
    legal_name: string | null
    email: string | null
    dealer_track_id: string | null
    routeone_id: string | null
    addresses: TDealerAddress[]
}

export type TStatistics = {
    avg_funding_time_minutes_all_time?: number
    avg_funding_time_minutes_30?: number
    avg_funding_time_minutes_60?: number
    avg_funding_time_minutes_90?: number
    current_funding?: number
    count_funding_status_all_time?: number
    count_funding_status_12_months?: number
    apps_all_time?: number
    apps_30?: number
    apps_60?: number
    apps_90?: number
    booked_all_time?: number
    booked_30?: number
    booked_60?: number
    booked_90?: number
    approvals_all_time?: number
    approvals_30?: number
    approvals_60?: number
    approvals_90?: number
    avg_time_to_approved_minutes_all_time?: number
    avg_time_to_approved_minutes_30?: number
    avg_time_to_approved_minutes_60?: number
    avg_time_to_approved_minutes_90?: number
    avg_time_approved_to_board_minutes_all_time?: number
    avg_time_approved_to_board_minutes_30?: number
    avg_time_approved_to_board_minutes_60?: number
    avg_time_approved_to_board_minutes_90?: number
}

export type TDealerConfigs = {
    hasSpreadFee: boolean
}

export type TContactTitles = {
    value: string
    label: string
}

export type TContact = {
    id?: number
    first_name?: string
    last_name?: string
    dealer_contact_title_id?: string
    email?: string
    phone?: string
    phone_extension?: string
    mobile?: string
}

export type TAllContacts = {
    pagination: {
        CurrentPage: number
        ResultsPerPage: number
        TotalResults: number
        TotalPages: number
    }
    data: TContact[]
}
