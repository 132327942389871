import pinpointClient from '@/clients/pinpointClient'
import { computed, ComputedRef, ref } from 'vue'
import { AccountStatuses } from '@/constants/accounts'

type TimeInStatusResponse = {
    status_id: number
    time_in_status: number
}
export const useGetTimeInStatus = () => {
    const timeInStatus = ref(0)
    const getTimeInStatus = async (accountId: number): Promise<void> => {
        const { data } = await pinpointClient.get<TimeInStatusResponse[]>(`accounts/${accountId}/statuses`, {
            params: { status_ids: AccountStatuses.Funding },
        })
        if (data && data.length) {
            timeInStatus.value = data[0].time_in_status
        }
        return
    }

    const computedTimeInStatus: ComputedRef<number> = computed(() => timeInStatus.value)
    return { getTimeInStatus, computedTimeInStatus }
}
