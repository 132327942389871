import { defineStore } from 'pinia'
import { useAPIState } from '@/composables/useApiState'
import { computed, onMounted, Ref, ref } from 'vue'

interface CampaignStore {
    marketingCampaigns: Ref<Campaign[]>
}

export type Campaign = {
    header: string
    description: string
}
export const useCampaignStore = defineStore('campaign', () => {
    const marketingCampaigns: Ref<Campaign[]> = ref([])
    const getCampaigns = useAPIState(
        computed(() => ({
            url: '/ads/campaigns/get/all',
        })),
        marketingCampaigns,
        { immediate: false },
    )
    onMounted(() => {
        getCampaigns.execute()
    })

    return {
        marketingCampaigns,
    } as CampaignStore
})
