<template>
    <div class="grid grid-cols-3 grid-rows-2 gap-6 mb-6">
        <div class="col-span-2 min-w-[46.25rem] h-[555px]">
            <div class="content-stretch rounded-lg shadow border bg-white mb-6">
                <header class="px-6 py-4 border-b-2 border-black flex justify-between items-center">
                    <h1 class="font-semibold text-base font-inter text-vuetify-text-normal">Statistics</h1>
                    <menu-sort
                        data-testid="dashboard-period"
                        class="test-class"
                        :sort-menu-items="cOptions"
                        :selected="period"
                        @emit-sort="onPeriodChange" />
                </header>
                <div v-if="isLoading" class="p-6 flex gap-x-4">
                    <v-skeleton-loader
                        v-for="i in 3"
                        :key="i"
                        class="mx-auto"
                        elevation="12"
                        type="heading, table-heading, sentences, divider, table-heading, sentences, divider, table-heading, sentences "></v-skeleton-loader>
                </div>
                <div v-else-if="error" class="p-6 flex flex-row justify-center items-center gap-x-4 h-[400px]">
                    <empty-state
                        icon-subtitle="Data Status"
                        border-size="240"
                        :vuetify-icon-props="{ icon: '$chartBoxOutline', color: 'grey-darken-1', size: '120' }"
                        footer-text="There is no data available" />
                </div>
                <main v-else class="p-6 flex gap-x-4">
                    <div class="w-full">
                        <h2 class="font-inter text-base font-normal ml-6 whitespace-nowrap text-vuetify-text-normal">
                            Dealership Performance
                        </h2>
                        <div class="grid row-span-3 gap-y-11 px-6 pt-4 rounded-lg bg-gray-50 pb-11 z-">
                            <stats-info
                                title="Approvals"
                                :mainValue="cStatistics.approvals.toString()"
                                mainValueCss="text-teal-700"
                                aside-value-description="Total Approvals"
                                :tool-tip-text="`Total number of approvals in the last ${period} days`" />

                            <stats-info
                                title="Funding"
                                mainValueCss="text-teal-700"
                                aside-value-description="Deals in Funding"
                                :mainValue="cStatistics.currentFunding.toString()"
                                tool-tip-text="Total number of application in funding status" />
                            <stats-info
                                title="Booked"
                                mainValueCss="text-teal-700"
                                aside-value-description="Total Deals Booked"
                                :mainValue="cStatistics.booked.toString()"
                                :tool-tip-text="`Total number of applications booked in the last ${period} days`" />
                        </div>
                    </div>
                    <div class="w-full h-full">
                        <h2 class="font-inter text-base font-normal ml-6 whitespace-nowrap text-vuetify-text-normal">
                            Speed of Service
                        </h2>
                        <div class="grid row-span-3 gap-y-11 px-6 pt-4 rounded-lg bg-gray-50 pb-11">
                            <stats-info
                                title="Approvals"
                                variant="2"
                                mainValueCss="text-teal-700"
                                :aside-value="calculateTime(cStatistics.avgTimeToApprovedMinutes).unit"
                                aside-value-css="text-xl font-inter font-light leading-[1.375rem]    text-teal-700"
                                :mainValue="calculateTime(cStatistics.avgTimeToApprovedMinutes).value.toFixed(0)"
                                aside-value-description="Avg time to approval"
                                :icon-left="{ icon: '$timerOutline', size: '45' }"
                                tool-tip-text="Time from submitted application to being approved" />
                            <stats-info
                                title="Funding"
                                mainValueCss="text-teal-700"
                                :mainValue="calculateTime(cStatistics.avgFundingTimeMinutes).value.toFixed(0)"
                                aside-value-css="text-xl font-inter font-light leading-[1.375rem]    text-teal-700"
                                :aside-value="calculateTime(cStatistics.avgFundingTimeMinutes).unit"
                                aside-value-description="Avg time in funding"
                                :icon-left="{ icon: '$timerOutline', size: '45' }"
                                tool-tip-text="Time that an application took to be reviewed by our funding analysts" />
                            <stats-info
                                title="Avg App Life Cycle"
                                :aside-value="calculateTime(cStatistics.avgTimeApprovedToBoardMinutes).unit"
                                aside-value-css="text-xl font-inter font-light leading-[1.375rem]    text-teal-700"
                                mainValueCss="text-teal-700"
                                :mainValue="calculateTime(cStatistics.avgTimeApprovedToBoardMinutes).value.toFixed(0)"
                                aside-value-description="Avg from approval to $"
                                :icon-left="{ icon: '$timerOutline', size: '45' }"
                                tool-tip-text="Total amount of time from when application was submitted to when it was funded" />
                        </div>
                    </div>
                    <div class="w-full h-full">
                        <h2 class="text-base font-normal font-inter ml-6 whitespace-nowrap text-vuetify-text-normal">
                            Application Profiles
                        </h2>
                        <div class="grid row-span-3 gap-y-11 px-6 pt-4 pb-11">
                            <stats-info
                                title="Thin Files"
                                :mainValue="cStatistics.thinFile.toString()"
                                no-border
                                :footer-message="`${cStatistics.thinFileAppPercent.toFixed(0)}% of all applications`"
                                :aside-value="`${cStatistics.thinFilePrevPercent?.toFixed(0)}%`"
                                :aside-value-css="
                                    cStatistics.thinFilePrevPercent >= 0
                                        ? `font-medium text-[#45BAB4]`
                                        : ` font-medium text-[#D3214B]`
                                "
                                :aside-value-description="`to prev ${period} days`"
                                :icon-right="{
                                    icon: getTrendingIcon(cStatistics.thinFilePrevPercent),
                                    size: '25',
                                    color: cStatistics.thinFilePrevPercent >= 0 ? 'teal' : 'error',
                                }"
                                tool-tip-text="Total number ‘Thin file’ applications submitted to Arivo" />
                            <stats-info
                                title="BK Ch 7"
                                :mainValue="cStatistics.bk7.toString()"
                                no-border
                                :footer-message="`${cStatistics.bk7AppPercent.toFixed(0)}% of all applications`"
                                :aside-value-css="cStatistics.bk7PrevPercent >= 0 ? `text-[#45BAB4]` : `text-[#D3214B]`"
                                :aside-value="`${cStatistics.bk7PrevPercent.toFixed(0)}%`"
                                :aside-value-description="`to prev ${period} days`"
                                :icon-right="{
                                    icon: getTrendingIcon(Number(cStatistics.bk7PrevPercent)),
                                    size: '25',
                                    color: cStatistics.bk7PrevPercent >= 0 ? 'teal' : 'error',
                                }"
                                tool-tip-text="Total number of applications submitted with BK Ch 7’s Arivo compared to total number of applications" />
                            <stats-info
                                title="BK Ch 13"
                                :mainValue="cStatistics.bk13.toString()"
                                :aside-value-css="
                                    cStatistics.bk13PrevPercent >= 0 ? `text-[#45BAB4]` : `text-[#D3214B]`
                                "
                                no-border
                                :footer-message="`${cStatistics.bk13AppPercent.toFixed(0)}% of all applications`"
                                :aside-value="`${cStatistics.bk13PrevPercent.toFixed(0)}%`"
                                :aside-value-description="`to prev ${period} days`"
                                :icon-right="{
                                    icon: getTrendingIcon(cStatistics.bk13PrevPercent),
                                    size: '25',
                                    color: cStatistics.bk13PrevPercent >= 0 ? 'teal' : 'error',
                                }"
                                tool-tip-text="Total number of applications submitted with BK Ch 13’s Arivo compared to total number of applications" />
                        </div>
                    </div>
                </main>
            </div>

            <announcements />
        </div>
        <aside class="col-span-1 row-span-2">
            <div class="pb-6">
                <ad-campaign variant="horizontal" />
            </div>
            <urgent-unresolved-issues />
        </aside>
    </div>
</template>
<script setup lang="ts">
import StatsInfo from '@/components/cards/StatsInfo.vue'
import AdCampaign from '@/components/partials/AdCampaign.vue'
import { useAPIState } from '@/composables/useApiState'
import { calculateTime } from '@/helpers/formatters'
import {
    convertToStatisticsData,
    getDataFromSpecificTimeFrame,
    SimplifiedStatisticsData,
    StatisticsData,
    StatisticsDataResponse,
} from '@/models/Statistics/statistics'
import { computed, ComputedRef, defineComponent, reactive, Ref, ref } from 'vue'
import { useRoute } from 'vue-router'
import UrgentUnresolvedIssues from '../cards/UrgentUnresolvedIssues.vue'
import Announcements from './Announcements.vue'
import EmptyState from './EmptyState.vue'
import MenuSort, { ISortMenu } from '@/components/MenuSort.vue'

defineComponent({
    name: 'Statistics',
})

const route = useRoute()
const cDealerId: ComputedRef<string> = computed(() => route.params.dealer_id as string)
const cRequest: ComputedRef<{ url: string }> = computed(() => ({
    url: `/stats/dealer-summary/${cDealerId.value}`,
}))
const response: Ref<StatisticsDataResponse> = ref({} as StatisticsDataResponse)
const period: Ref<string> = ref('30')
const { isLoading, error, isReady } = useAPIState(cRequest, response)
const cStatisticsAllTimeFrames: ComputedRef<StatisticsData> = computed(() =>
    isReady.value ? convertToStatisticsData(response.value) : ({} as StatisticsData),
)
const cStatistics: ComputedRef<SimplifiedStatisticsData> = computed(() =>
    getDataFromSpecificTimeFrame(cStatisticsAllTimeFrames.value, +period.value),
)

const cOptions = reactive<ISortMenu[]>([
    {
        value: '30',
        title: 'Past 30 days',
    },
    {
        value: '60',
        title: 'Past 60 days',
    },
    {
        value: '90',
        title: 'Past 90 days',
    },
])

function onPeriodChange(value: string): void {
    period.value = value
}

function getTrendingIcon(value: number): string {
    return value >= 0 ? '$trendingUp' : '$trendingDown'
}
</script>

<style scoped lang="scss">
:deep(.v-btn.v-btn--density-default.menu-sort-v-btn) {
    background-color: rgb(var(--v-theme-background));
}
</style>
