<template>
    <div class="flex flex-row gap-x-4 overflow-x-auto overscroll-x-none w-full">
        <div v-for="(car, key) in cars" :key="`${key}-${car.vin}-${car.stockNo}`">
            <inventory-vehicle-card :vehicle-info="car" :class="hoverCss" @click="$emit('vehicleChanged', car)" />
        </div>
    </div>
</template>
<script setup lang="ts">
import { Vehicle } from '@/models/Vehicle/Vehicle'
import InventoryVehicleCard from '@/components/cards/InventoryVehicleCard.vue'
import { computed, ComputedRef } from 'vue'

defineEmits<{
    vehicleChanged: [v: Vehicle]
}>()
// define props
interface Props {
    cars: Vehicle[]
}

defineProps<Props>()

const hoverCss: ComputedRef<string> = computed(
    () => 'hover:cursor-pointer hover:drop-shadow-[0_2px_4px_rgba(0,0,0,0.6)]',
)
</script>
