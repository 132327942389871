<template>
    <div>
        <h3 id="stats-header" class="flex items-center font-medium text-[11px] w-fit">
            <span class="text-xs font-inter font-normal text-vuetify-text-normal">{{ title }}</span>
            <v-tooltip
                :text="props.toolTipText"
                location="right"
                max-width="240"
                :class="`${props.toolTipClasses} !text-xs`">
                <template #activator="{ props }">
                    <v-icon
                        v-bind="props"
                        color="text-secondary"
                        class="ml-1.5"
                        icon="$informationSlabCircleOutline"
                        size="12" />
                </template>
            </v-tooltip>
        </h3>
        <div :class="`flex h-[70px] items-center border-dotted border-b-2 ${hideBorder}`">
            <v-icon v-if="iconLeft" v-bind="iconLeft" data-testid="icon-left" />
            <p id="stats-main-value" :class="`text-[56px] font-black mr-2 leading-[48px] ${mainValueCss}`">
                {{ mainValue }}
            </p>
            <div
                class="w-[40px] h-[40px] bg-vuetify-bg-surface-200 rounded-full flex items-center justify-center mr-1"
                v-if="iconRight">
                <v-icon v-bind="iconRight"> </v-icon>
            </div>
            <div class="text-xs font-normal font-inter break-words">
                <p id="stats-aside-value" v-if="asideValue" :class="asideValueCss">{{ asideValue }}</p>
                <p id="stats-aside-description">{{ asideValueDescription }}</p>
            </div>
        </div>
        <div v-if="noBorder" class="absolute text-sm">{{ footerMessage }}</div>
    </div>
</template>
<script setup lang="ts">
import { ComputedRef, computed } from 'vue'
import { VIcon } from 'vuetify/components'

interface Props {
    title?: string
    iconLeft?: VIcon['$props']
    iconRight?: VIcon['$props']
    mainValue?: string
    mainValueCss?: string
    asideValueDescription: string
    asideValue?: string
    noBorder?: boolean
    footerMessage?: string
    asideValueCss?: string
    toolTipText?: string
    toolTipClasses?: string
}

const props: Readonly<Props> = withDefaults(defineProps<Props>(), {
    noBorder: false,
})

const hideBorder: ComputedRef<string> = computed((): string => {
    return props.noBorder ? 'border-transparent' : 'border-black'
})
</script>
