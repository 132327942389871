export interface Account {
    created_at: string
    dealer_id: number
    id: number
    preferred_contacts: [
        {
            account_id: number
            borrower_id: number
        },
    ]
    source: {
        id: number
        source: string
    }
    status: {
        id: number
        status: string
    }
    submitted_by: string
    type: string
    updated_at: string
}

export interface AccountByEvents {
    account_id: number
    co_borrower_name: string
    created_at: string
    event_id: number
    name: string
    primary_borrower_name: string
    short_name: string
}

interface Pagination {
    CurrentPage: number
    ResultsPerPage: number
    TotalPages: number
    TotalResults: number
}

export interface AccountsByEventsData {
    Accounts: AccountByEvents[]
    Pagination: Pagination
}

export const AccountStatus = {
    Active: 12,
}
