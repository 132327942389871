<template>
    <div class="pl-6 pt-2 text-sm font-medium">
        <span cy-id="number-favorites">{{ favorites.length }}</span>
        {{ favoritesLabel }}
    </div>
    <div v-if="favorites.length" class="max-h-[652px] overflow-auto p-4">
        <inventory-cards :vehicleList="favorites" />
    </div>
    <p v-else class="max-w-[1040px] py-8 px-6 text-22px font-normal">
        It looks like you haven’t selected any favorite vehicles yet. To add vehicles to your favorites, click the heart
        in the top-right corner of any vehicle card.
    </p>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue'
import { useInventoryStore } from '@/stores/inventory'
import InventoryCards from '@/components/partials/InventoryCards.vue'
import { useApplicationStore } from '@/stores/application'
import { storeToRefs } from 'pinia'

defineOptions({
    name: 'FavoritesModal',
})

// initializations
const inventoryStore = useInventoryStore()
const application = useApplicationStore()
const { terms } = storeToRefs(application)
const { filters, favorites } = storeToRefs(inventoryStore)

const cFavorites = computed(() => {
    return favorites.value.slice(0, 20)
})

const favoritesLabel = computed(() => {
    return cFavorites.value.length === 1 ? 'Favorite' : 'Favorites'
})
// lifecycle hooks
onMounted(async () => {
    await inventoryStore.getFavorites(application.account?.id, filters.value.apr)
})

defineExpose({ favorites })

watch(terms, async () => {
    if (filters.value.apr != terms.value?.rate_contract) {
        filters.value.apr = terms.value?.rate_contract
        await inventoryStore.getFavorites(application.account?.id, filters.value.apr)
    }
})
</script>
