import { ref, Ref, App } from 'vue'
import { ToastVariant } from './ToastVariant'
import { ToastButton } from '@/models/toaster/toast'
export type locationOptions = 'top-left' | 'top-right' | 'top-center' | 'bottom-left' | 'bottom-right' | 'bottom-center'

export type Toast = {
    key?: string
    title?: string
    autoClose?: boolean
    class?: string
    message: string
    timer?: number
    location?: locationOptions
    type: ToastVariant
    buttons?: ToastButton[]
    closable?: boolean
    persistent?: boolean
    onCloseCallback?: () => void
}

export function eatToast(key: string, toasts: Ref<Toast[]>): void {
    toasts.value = toasts.value.filter((toast: Toast) => toast.key != key)
}

export function installToaster(app: App): void {
    const toasts: Ref<Toast[]> = ref([])

    function addToast(value: Toast): void {
        if (isThereDuplicate(value)) return
        toasts.value.push(value)
    }

    function popToast(value: Toast): void {
        const key: string = Math.random().toString(36).substring(7)
        const toast: Toast = {
            key,
            autoClose: value.autoClose ?? true,
            class: value.class ?? '',
            message: value.message ?? '',
            timer: value.timer ?? 3000,
            location: value.location ?? 'top-right',
            type: value.type ?? 'default',
            title: value.title ?? '',
            buttons: value.buttons ?? [],
            closable: value.closable ?? true,
            persistent: value.persistent ?? false,
            onCloseCallback: value.onCloseCallback,
        }
        addToast(toast)
        if (toast.autoClose) {
            setTimeout(() => eatToast(key, toasts), toast.timer)
        }
    }

    function isThereDuplicate(value: Toast): boolean {
        const currentToast: Toast = Object.assign({}, value)
        delete currentToast.key
        const found: boolean = toasts.value.some((toast: Toast) => {
            const toastToCompare = Object.assign({}, toast)
            delete toastToCompare.key
            return JSON.stringify(toastToCompare) == JSON.stringify(currentToast)
        })
        return found
    }

    app.provide('toastsLocal', toasts)
    app.provide('popToastLocal', popToast)
}
