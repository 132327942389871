<template>
    <v-menu
        v-model="menuOpen"
        max-width="220"
        :close-on-content-click="false"
        :attach="testing"
        location="bottom right">
        <template #activator="{ props }">
            <v-btn
                v-bind="props"
                cy-id="menu-sort-btn"
                :class="{ active: menuOpen }"
                class="!tracking-normal text-capitalize menu-sort-v-btn !text-vuetify-text-normal"
                variant="text"
                :text="`${cSortLabel?.prependTitle ?? ''} ${cSortLabel?.title}`">
                <template #append>
                    <v-icon icon="$menuDown"></v-icon>
                </template>
            </v-btn>
        </template>
        <v-card>
            <v-list
                :items="cSortList"
                item-props
                density="compact"
                @click:select="handleSelected($event.id as string)"
                class="">
            </v-list>
        </v-card>
    </v-menu>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, Ref, ref } from 'vue'
import { VList } from 'vuetify/components'

export interface ISortMenu {
    title?: string
    type?: string
    prependTitle?: string
    innerSubtitle?: string
    subtitle?: string
    value?: any
    props?: any
}

interface Props {
    sortMenuItems: ISortMenu[]
    selected: string
    testing?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    testing: false,
})
const emit = defineEmits<{
    'emit-sort': [value: string]
}>()
const menuOpen: Ref<boolean> = ref(false)

const cSortList: ComputedRef<ISortMenu[]> = computed(() => {
    return props.sortMenuItems.map((item) => {
        let propsToAdd: any = {}
        if (item.value === props.selected) {
            propsToAdd = {
                'data-testid': 'sort_list_check_icon',
                appendIcon: '$check',
                class: 'bg-blue-grey-lighten-5',
                disabled: true,
            }
        }
        return {
            ...propsToAdd,
            ...item,
        }
    })
})
const cSortLabel: ComputedRef<ISortMenu | undefined> = computed(() =>
    cSortList.value.find((sort) => sort.value == props.selected),
)
function handleSelected(selectedSort: string) {
    emit('emit-sort', selectedSort)
    menuOpen.value = false
}
</script>

<style lang="scss" scoped>
.v-btn.active .v-icon {
    transform: rotate(180deg);
    transition: transform 0.2s ease;
}

.v-btn:not(.active) .v-icon {
    transform: rotate(0deg);
    transition: transform 0.2s ease;
}
:deep(hr) {
    border-color: black !important;
}
</style>
