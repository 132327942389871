<template>
    <v-dialog v-model="modal.show" v-bind="modal.state.dialogProps" @after-leave="closeModal">
        <v-card
            v-if="modal.show && modal.state.component?.name"
            class="draggable-modal relative p-4 h-fit !rounded-lg w-full">
            <v-card-title
                data-testid="global-modal-title"
                class="d-flex items-center hover:cursor-move border-b border-black"
                @mousedown="startDrag"
                @mousemove="doDrag"
                @mouseup="stopDrag">
                <p class="font-medium text-md p-2">{{ modal.state.title }}</p>
                <v-spacer />
                <v-btn
                    data-testid="global-modal-close-btn"
                    icon="$windowClose"
                    density="compact"
                    @click="closeModal"></v-btn>
            </v-card-title>
            <v-card-text class="!px-0 !pb-0 !pt-0 w-full">
                <component :is="modal.state.component?.name" v-bind="modal.state.component?.props" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { onBeforeUnmount, reactive } from 'vue'
import { useGlobalModal } from '@/composables/useGlobalModal.ts'

const modal = useGlobalModal()

const state = reactive({
    isVisible: false,
    isDragging: false,
    initialX: 0,
    initialY: 0,
    offsetX: 0,
    offsetY: 0,
})

function startDrag(event: MouseEvent): void {
    const target = event.target as HTMLElement
    if (target?.tagName === 'INPUT' || target?.tagName === 'TEXTAREA') {
        return
    }
    state.isDragging = true
    state.initialX = event.clientX - state.offsetX
    state.initialY = event.clientY - state.offsetY
    window.addEventListener('mousemove', doDrag)
    window.addEventListener('mouseup', stopDrag)
}

function doDrag(event: MouseEvent): void {
    if (!state.isDragging) return
    state.offsetX = event.clientX - state.initialX
    state.offsetY = event.clientY - state.initialY
    const modalWrapper: HTMLElement | null = document.querySelector('.draggable-modal')
    if (modalWrapper) {
        modalWrapper.style.transform = `translate(${state.offsetX}px, ${state.offsetY}px)`
    }
}

function stopDrag(): void {
    state.isDragging = false
    window.removeEventListener('mousemove', doDrag)
    window.removeEventListener('mouseup', stopDrag)
}

function onLeave(): void {
    state.offsetX = 0
    state.offsetY = 0
    const draggableComponent: HTMLElement | null = document.querySelector('.draggable-component')
    if (draggableComponent) {
        draggableComponent.style.transform = 'translate(0px, 0px)'
    }
}

function closeModal(): void {
    modal.show = false
    onLeave()
}

onBeforeUnmount(() => {
    window.removeEventListener('mousemove', doDrag)
    window.removeEventListener('mouseup', stopDrag)
})
</script>
