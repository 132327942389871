<template>
    <div class="flex flex-col items-center border-dashed border-b-2 border-black pb-2">
        <div class="pb-2" data-test-title>
            {{ title }}
            <v-tooltip :text="toolTipMessage" data-testid="tooltip" location="top">
                <template #activator="{ props }">
                    <v-icon v-bind="props" color="text-secondary" icon="$informationSlabCircleOutline" size="12" />
                </template>
            </v-tooltip>
        </div>
        <div data-test-value class="font-black text-[#087670]" :class="setTextSizeByDateLength(value)">{{ value }}</div>
        <div data-test-unit class="text-xs">{{ unit }}</div>
    </div>
</template>
<script setup lang="ts">
// define props
interface IProps {
    title: string
    toolTipMessage: string
    value: string
    unit: string
}

defineProps<IProps>()

function setTextSizeByDateLength(value: string): string {
    return value.length > 3 ? 'text-4xl text-red-800' : 'text-5xl'
}
</script>
