<template>
    <div class="w-full flex flex-row justify-between py-1 px-2 border-b border-slate-300">
        <div>
            <h3 :cy-id="`${queueName}-name`" class="font-inter font-normal text-sm leading-5 text-slate-900">
                {{ name }}
            </h3>
            <span :cy-id="`${queueName}-account`" class="font-normal text-xs leading-5 text-slate-500">{{
                account
            }}</span>
        </div>
        <div>
            <span
                :cy-id="`${queueName}-days`"
                :class="`${computedBadgeStyling} font-inter font-normal text-sm py-[2px] px-2 rounded-3xl`"
                >{{ getDaysLabel }}</span
            >
        </div>
    </div>
</template>

<script lang="ts" setup>
import { styleIssueLineBadgeBackground } from '@/composables/IssueLine'
import { useDealerStore } from '@/stores/dealer/dealer'
import { storeToRefs } from 'pinia'
import { computed, ComputedRef } from 'vue'

interface Props {
    name: string
    account: string
    days: number
    queueName: string
}

const props = defineProps<Props>()

const { dealer } = storeToRefs(useDealerStore())

const isIndependent: ComputedRef<boolean> = computed(() => dealer.value.type === 'Independent')
const computedBadgeStyling: ComputedRef<string> = computed(() =>
    styleIssueLineBadgeBackground(props.queueName, props.days, isIndependent.value),
)

const getDaysLabel: ComputedRef<string> = computed(() => {
    if (props.days) {
        return props.days > 1 ? `${props.days} days` : `${props.days} day`
    }
    return ''
})
</script>
