import { markRaw, Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { VDialog } from 'vuetify/components'
import { merge } from 'lodash'
type TModalProps<T = {}> = {
    dialogProps?: VDialog['$props']
    title?: string
    component?: {
        name: ReturnType<typeof markRaw>
        props?: T
    }
}

type TUseModalReturnType = {
    state: Ref<TModalProps>
    show: Ref<boolean>
    openModal: (props: TModalProps) => void
    closeModal: () => void
}

export const useGlobalModal = defineStore('modal', () => {
    const show: Ref<boolean> = ref(false)
    const state: Ref<TModalProps> = ref({})

    function openModal(props: TModalProps): void {
        state.value.title = props.title
        state.value.dialogProps = merge(props.dialogProps)
        state.value.component = props.component
        show.value = true
    }
    function closeModal(): void {
        show.value = false
    }

    return {
        state,
        show,
        openModal,
        closeModal,
    } as TUseModalReturnType
})
